export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAIL = 'LOGIN_FAIL';

export const REG_SUCCESS = 'REG_SUCCESS';
export const REG_FAIL = 'REG_FAIL';

export const ACCOUNT_INFO_SUCCESS = 'ACCOUNT_INFO_SUCCESS';
export const ACCOUNT_INFO_FAIL = 'ACCOUNT_INFO_FAIL';

export const POSITION_INFO_SUCCESS = 'POSITION_INFO_SUCCESS';
export const POSITION_INFO_FAIL = 'POSITION_INFO_FAIL';

export const ORDER_INFO_SUCCESS = 'ORDER_INFO_SUCCESS';
export const ORDER_INFO_FAIL = 'ORDER_INFO_FAIL';

export const BLOCK_INFO_SUCCESS = 'BLOCK_INFO_SUCCESS';
export const BLOCK_INFO_FAIL = 'BLOCK_INFO_FAIL';

export const UPDATE_CRED_SUCCESS = 'UPDATE_CRED_SUCCESS';

export const BLOCK_CHART_INFO_SUCCESS = 'BLOCK_CHART_INFO_SUCCESS';
export const GET_ACTIVE_ASSETS_INFO = 'GET_ACTIVE_ASSETS_INFO';

export const COMMON_CHART_INFO = 'COMMON_CHART_INFO';
export const SIGNAL_CHART_INFO_SUCCESS = 'SIGNAL_CHART_INFO_SUCCESS';

export const GET_POSITION_LOGS_INFO = 'GET_POSITION_LOGS_INFO';

export const ACCOUNT_HISTORY = 'ACCOUNT_HISTORY';
export const FORCE_SELL_SUCCESS = 'FORCE_SELL_SUCCESS';
export const SUPPRESS_ACTIONS = 'SUPPRESS_ACTIONS';
import { combineReducers } from 'redux';
import loginReducer from './loginReducer';
import accountReducer from './accountReducer';
import positionReducer from './positionReducer';
import orderReducer from './orderReducer';
import blocksListing from './blocksListingReducer';
import blockChart from './blockChartReducer';
import activeAssets from './activeAssetsReducer';
import commonChartInfo from './commonChartReducer';
import signalsInfo from './signalsReducer';
import accountHistoryReducer from './accountHistoryReducer';
import logsReducer from './logsReducer';

export default combineReducers({
    userData: loginReducer,
    accountInfo: accountReducer,
    positionsInfo: positionReducer,
    orderInfo: orderReducer,
    blockInfo: blocksListing,
    blockChartInfo: blockChart,
    activeAssetsInfo: activeAssets,
    commonChartInfoData: commonChartInfo,
    signalsInfoData: signalsInfo,
    accountHistoryInfo: accountHistoryReducer,
    logsInfo: logsReducer,

});
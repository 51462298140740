
// import qs from "qs";
import { ACCOUNT_INFO_SUCCESS, UPDATE_CRED_SUCCESS } from './types'
import { accountInfoUrl, updateCerdUrl } from '../utility/Constants';
import { httpGetRequest, httpPostReq } from "../utility/httpRequests";
import { getUserInfo } from '../utility/Utility';

export const getAplcaAccountInfo = (callback) => async (
    dispatch
) => {


    let userInfoD = getUserInfo();
    let url = accountInfoUrl;
    try {
        url = url.replace("userID", userInfoD.id);
    } catch (err) {
    }

    // console.log(url)
    // console.log('url ___')
    httpGetRequest(url, function (response) {
        // console.log(response)
        // console.log('response ___')
        if (response.data && response.data.status == 'success') {
            dispatch({
                type: ACCOUNT_INFO_SUCCESS,
                payload: response.data,
            });
        }
        callback(response.data);
    })
};
export const updateCedentials = (apiid, apiSecret, callback) => async (
    dispatch
) => {

    let userInfoD = getUserInfo();
    let url = updateCerdUrl;

    try {
        url = url.replace("userID", userInfoD.id);
    } catch (err) {
    }


    const data = {
        "api_id": apiid,
        "api_secret_key": apiSecret,
        "is_paper_acc": true
    };
    // const data = {
    //     "api_id": "PKJI9F3U874E8L70GN2A",
    //     "api_secret_key": "ssMMjvtfWlGB82zLP8B5mp2GYk8oaIYKnqjJ4ntY",
    //     "is_paper_acc": true
    // }

    // console.log(data)
    // console.log('data ___')
    httpPostReq(
        url,
        // qs.stringify(data),
        data,
        function (response) {
            // console.log(response)
            // console.log("response ___")
            if (response && response.status == 'success') {
                dispatch({
                    type: UPDATE_CRED_SUCCESS,
                    payload: response.data,
                });
            }
            callback(response.data);
        },
        false
    );
};
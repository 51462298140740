
import React from "react";

import '../css/style.css';
import { Chart } from 'chart.js';
import annotationPlugin from 'chartjs-plugin-annotation';


class SimpleLineChart extends React.Component {
    constructor(props) {
        super(props);
        this.canvasRef = React.createRef();
    }

    componentDidUpdate() {

        const { chartData } = this.props;
        if (chartData && Object.keys(chartData).length > 0) {

            this.myChart.data = {
                labels: chartData.labels,
                datasets: chartData.datasets,
            };
            this.myChart.options = chartData.options;

            this.myChart.update();
        }
    }

    componentDidMount() {

        const { chartData } = this.props;

        if (Object.keys(chartData).length > 0) {

            Chart.plugins.register({
                afterDatasetsDraw: function (chart) {
                    if (chart.tooltip._active && chart.tooltip._active.length) {
                        let activePoint = chart.tooltip._active[0],
                            ctx = chart.ctx,
                            y_axis = chart.scales['y-axis-0'],
                            x = activePoint.tooltipPosition().x,
                            topY = y_axis.top,
                            bottomY = y_axis.bottom;
                        // draw line
                        ctx.save();
                        ctx.beginPath();
                        ctx.moveTo(x, topY);
                        ctx.lineTo(x, bottomY);
                        ctx.lineWidth = 0.45;
                        // ctx.strokeStyle = '#07C';
                        ctx.strokeStyle = '#f57607';
                        ctx.stroke();
                        ctx.restore();
                    }
                }
            });
            this.myChart = new Chart(this.canvasRef.current, {
                type: 'line',
                data: {
                    labels: chartData.labels,
                    datasets: chartData.datasets,
                },
                options: chartData.options
            })



        }

    }

    componentWillUnmount() { }

    render() {

        const { chartData } = this.props;
        if (Object.keys(chartData).length > 0) {
            return <div style={{ justifyContent: 'center', alignItems: 'center', width: "100%" }}>
                {/* <canvas id="portfolioCharoverlay" style={{ pointerEvents: "none", position: "absolute", display: "block" }} /> */}
                <canvas id="portfolioCanvas" ref={this.canvasRef} style={{ height: 200 }} />
            </div>;
        }
        return <div />
    }
}
export default SimpleLineChart;

import {
    ORDER_INFO_SUCCESS,
    // ORDER_INFO_FAIL,
    BLOCK_INFO_SUCCESS,
    // BLOCK_INFO_FAIL,
    // BLOCK_CHART_INFO_SUCCESS,
    GET_ACTIVE_ASSETS_INFO,
    COMMON_CHART_INFO,
    SIGNAL_CHART_INFO_SUCCESS,

    GET_POSITION_LOGS_INFO

} from './types'
import {
    ordersUrl,
    transactionUrl,
    blocksChartUrl,
    commonChartUrl,
    signalChartUrl,
    activeAssetsUrl,
    positionLogsUrl
} from '../utility/Constants';
import { httpGetRequest } from "../utility/httpRequests";
import { getUserInfo } from '../utility/Utility';

export const getOrderInfo = (status, symbolVal, callback) => async (
    dispatch
) => {

    try {
        let userInfoD = getUserInfo();

        let url = ordersUrl;
        url = url.replace("userID", userInfoD.id);
        url = url + '?status=' + ((status && status.length > 0) ? status : 'open');
        if (symbolVal && symbolVal.length > 0) {
            url = url + '&symbol=' + symbolVal;
        }

        httpGetRequest(url, function (response) {

            // console.log(response)
            // console.log('response ___')
            if (response.data && response.data.status === 'success') {
                dispatch({
                    type: ORDER_INFO_SUCCESS,
                    payload: response.data,
                });
            }
            callback(response.data);
        })
    } catch (err) {
        console.log(err)
    }

};
export const getBlockListingInfo = (status, symbolVal, callback) => async (
    dispatch
) => {

    try {
        let userInfoD = getUserInfo();

        let url = transactionUrl;
        url = url.replace("userID", userInfoD.id);

        url = url + '?status=' + ((status && status.length > 0) ? status : 'all');
        if (symbolVal && symbolVal.length > 0) {
            url = url + '&symbol=' + symbolVal;
        }

        // console.log(url)
        // console.log('url ____ block listing...')
        httpGetRequest(url, function (response) {
            if (response.data && response.data.status === 'success') {
                dispatch({
                    type: BLOCK_INFO_SUCCESS,
                    payload: response.data,
                });
            }
            callback(response.data);
        })
    } catch (err) {
        console.log(err)
    }
};
export const getBlockChartInfo = (symbol, startDate, endDate, callback) => async (
    dispatch
) => {

    // let userInfoD = getUserInfo();

    let url = blocksChartUrl;
    url = url + '' + symbol + `?start=${startDate}&end=${endDate}`

    // console.log(url)
    // console.log('url ____ blocksChartUrl')

    httpGetRequest(url, function (response) {

        // if (response.data) {
        //     dispatch({
        //         type: BLOCK_CHART_INFO_SUCCESS,
        //         payload: response.data,
        //     });
        // }
        if (response.data && response.data.result) {
            callback(response.data.result);
        } else {
            return {}
        }
    })
};
export const getCommonChartInfo = (symbol, startDate, endDate, callback) => async (
    dispatch
) => {

    // let userInfoD = getUserInfo();

    let url = commonChartUrl;
    url = url + '' + symbol + `?start=${startDate}&end=${endDate}`

    // console.log(url)
    // console.log('url ____ common chart')

    httpGetRequest(url, function (response) {

        // console.log(response)
        // console.log('response ____')
        // if (response.data) {
        //     dispatch({
        //         type: COMMON_CHART_INFO,
        //         payload: response.data,
        //     });
        // }
        if (response.data && response.data.result) {
            if (response.data) {
                dispatch({
                    type: COMMON_CHART_INFO,
                    payload: response.data.result,
                });
            }
            callback(response.data.result);
        } else {
            return {}
        }
    })
};
export const getSignalChartInfo = (symbol, startDate, endDate, callback, isCommon) => async (
    dispatch
) => {

    let url = signalChartUrl;
    url = url + '' + symbol + `?start=${startDate}&end=${endDate}`

    // console.log(url)
    // console.log('url ____ getSignalChartInfo')
    httpGetRequest(url, function (response) {

        if (response.data) {
            if (isCommon) {
                dispatch({
                    type: SIGNAL_CHART_INFO_SUCCESS,
                    payload: response.data.result,
                });
            }
            callback(response.data.result);
        } else {
            return {}
        }
    })
};
export const getActiveAssetsInfo = (symbol, callback) => async (
    dispatch
) => {

    let url = activeAssetsUrl;
    url = url + '' + symbol

    // console.log(url)
    // console.log('url ____ getActiveAssetsInfo')
    httpGetRequest(url, function (response) {

        if (response.data) {
            if (response.data) {
                dispatch({
                    type: GET_ACTIVE_ASSETS_INFO,
                    payload: response.data.result,
                });
            }
            callback(response.data.result);
        } else {
            return {}
        }
    })
};
export const getPositionLogs = (status, symbolVal, callback) => async (
    dispatch
) => {

    try {
        let userInfoD = getUserInfo();

        let url = positionLogsUrl;
        url = url.replace("userID", userInfoD.id);

        // url = url + '?status=' + ((status && status.length > 0) ? status : 'all');
        if (symbolVal && symbolVal.length > 0) {
            // url = url + '&symbol=' + symbolVal;
            url = url + '?symbol=' + symbolVal;
        }

        httpGetRequest(url, function (response) {

            if (response.data && response.data.status === 'success') {
                dispatch({
                    type: GET_POSITION_LOGS_INFO,
                    payload: response.data,
                });
            }
            callback(response.data);
        })
    } catch (err) {
        console.log(err)
    }
};
import cookie from 'react-cookies';
// var md5 = require('md5');

export const createCookie = (name, value, isSessonEnd, hours, days) => {

    const expires = new Date()
    expires.setDate(Date.now() + 1000 * 60 * 60 * 24 * 14)
    let expHr = hours ? hours : 1;

    if (isSessonEnd) {
        cookie.save(name, value, { path: '/' })
    } else if (days) {
        var now = new Date();
        cookie.save(
            name,
            value,
            {
                path: '/',
                expires: expires,
                maxAge: 60 * 60 * 24
            }
        )
    } else {
        cookie.save(
            name,
            value,
            {
                path: '/',
                expires: expires,
                maxAge: 60 * 60 * 24 * expHr * 365
            }
        )
    }

    return true
}
export const isJSON = (str) => {
    try {
        JSON.parse(str);
        return true;
    } catch (err) {
        return false;
    }
}


export const getNumLabels = (datasize) => {

    if (datasize <= 5) {
        return 5;
    }
    for (let i = 9; i < 16; i++) {
        if (((datasize - 1) % i) == 0) {
            return i;
        }
    }
}


export const getUserInfo = () => {
    let userInfo = {}
    try {
        userInfo = cookie.load("userInfo")
    } catch (err) {

    }
    return userInfo;
}
export const datetimeFromTimestampMdy = (timestamp, spr) => {

    //EST
    // let offset = -5.0

    let clientDate = new Date(timestamp);
    let usaTime = clientDate.toLocaleString("en-US", { timeZone: "America/New_York" });
    // let utc = clientDate.getTime() + (clientDate.getTimezoneOffset() * 60000);
    // let serverDate = new Date(utc + (3600000 * offset));
    // return serverDate.toLocaleString()
    return usaTime


}
export const getNewyorkTime = (timestamp, spr) => {


    let clientDate = new Date(timestamp);
    let usaTime = clientDate.toLocaleString("en-US", { timeZone: "America/New_York" }); //timeZone: "Asia/Kolkata"
    // console.log(new Date(usaTime))
    let nt = new Date(usaTime);

    let month = nt.getMonth() + 1;

    let day = nt.getDate();

    let hours = nt.getHours();
    let minutes = nt.getMinutes();

    if (month.toString().length < 2)
        month = '0' + month;
    if (day.toString().length < 2)
        day = '0' + day;
    if (hours.toString().length < 2)
        hours = '0' + hours;

    if (minutes.toString().length < 2)
        minutes = '0' + minutes;

    // console.log([month, day].join('/') + ' ' + hours + ':' + minutes)
    // console.log('day ___')
    return [month, day].join('/') + ' ' + hours + ':' + minutes;
    // return usaTime


}


export const getUTCDateOnly = (timestamp, lessCount) => {

    // console.log('SG  timestamp ___ ', timestamp)
    let clientDate = new Date(timestamp.substring(0, 10));
    // console.log('SG  clientDate == ', clientDate)


    let month = clientDate.getUTCMonth() + 1;
    let day = clientDate.getUTCDate();

    if (month.toString().length < 2)
        month = '0' + month;
    if (day.toString().length < 2)
        day = '0' + day;


    let val = [month, day].join('/')
    // console.log('SG  val === ', val)
    return val;
}
export const getDateOnly = (timestamp, lessCount) => {
    // console.log('SG  timestamp ___', timestamp)
    let clientDate = new Date(timestamp);
    if (lessCount) {
        clientDate.setDate(clientDate.getDate() - lessCount);
    }

    let month = clientDate.getMonth() + 1;
    let day = clientDate.getDate();

    if (month.toString().length < 2)
        month = '0' + month;
    if (day.toString().length < 2)
        day = '0' + day;

    return [month, day].join('/');
}
export const getNewyorkDate = (timestamp, spr) => {


    let clientDate = new Date(timestamp);
    let usaTime = clientDate.toLocaleString("en-US", { timeZone: "America/New_York" });
    // console.log(new Date(usaTime))
    let nt = new Date(usaTime);

    let month = nt.getMonth() + 1;
    let day = nt.getDate();
    let hours = nt.getHours();
    let minutes = nt.getMinutes();

    if (month.toString().length < 2)
        month = '0' + month;
    if (day.toString().length < 2)
        day = '0' + day;
    // if (hours.toString().length < 2)
    //     hours = '0' + hours;
    // if (minutes.toString().length < 2)
    //     minutes = '0' + minutes;

    return [month, day].join('/');
    // return usaTime


}
export const datetimeFromTimestampMdyOld = (timestamp, spr) => {
    var dateTime = new Date(timestamp);
    var sp = spr ? spr : "-";
    var year = dateTime.getFullYear();
    var month = dateTime.getMonth() + 1;
    month = month < 10 ? "0" + month : month;
    var date = dateTime.getDate() < 10 ? "0" + dateTime.getDate() : dateTime.getDate();
    var hours = dateTime.getHours();
    var minutes = dateTime.getMinutes();
    var ampm = hours >= 12 ? 'PM' : 'AM';
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    minutes = minutes < 10 ? '0' + minutes : minutes;
    var dt = month + sp + date + sp + year + " " + hours + ':' + minutes + ' ' + ampm;
    return dt;
}

export const round = (num, pos) => {
    try {
        return num ? num.toFixed(pos) : 0;
    } catch (err) {
        return num;
    }
}

export const getParameterByName = (name, url = window.location.href) => {
    name = name.replace(/[\[\]]/g, '\\$&');
    var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
        results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, ' '));
}

export const formatDateYYYYmmdd = (date) => {
    var d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

    if (month.length < 2)
        month = '0' + month;
    if (day.length < 2)
        day = '0' + day;

    return [year, month, day].join('-');
}
export const formatDateMMdd = (date) => {
    var dt = new Date(date).toISOString();
    var d = new Date(dt),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate();
    // year = d.getFullYear();

    if (month.length < 2) {
        month = '0' + month;
    }
    if (day.length < 2) {
        day = '0' + day;
    }

    return [month, day].join('-');
}
export const formatDateMMDDhhmm = (date) => {
    var d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        // year = d.getFullYear();
        hours = '' + d.getHours(),
        minutes = '' + d.getMinutes();

    if (month.length < 2)
        month = '0' + month;
    if (day.length < 2)
        day = '0' + day;
    if (hours.length < 2)
        hours = '0' + hours;
    if (minutes.length < 2)
        minutes = '0' + minutes;

    return [month, day].join('/') + ' ' + hours + ':' + minutes;
}
export const formatDateYYYYMMDDhhmm = (date) => {
    var d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear(),
        hours = '' + d.getHours(),
        minutes = '' + d.getMinutes();

    if (month.length < 2)
        month = '0' + month;
    if (day.length < 2)
        day = '0' + day;
    if (hours.length < 2)
        hours = '0' + hours;
    if (minutes.length < 2)
        minutes = '0' + minutes;

    return [year, month, day].join('-') + ' ' + hours + ':' + minutes;
}
export const getDayDifference = (startDate, endDate) => {

    const _MS_PER_DAY = 1000 * 60 * 60 * 24;
    const utc1 = Date.UTC(startDate.getFullYear(), startDate.getMonth(), startDate.getDate());
    const utc2 = Date.UTC(endDate.getFullYear(), endDate.getMonth(), endDate.getDate());

    return Math.abs(Math.floor((utc2 - utc1) / _MS_PER_DAY));
}
export const getGradientColor = (container, type) => {

    var ctx = document.getElementById(container).getContext("2d");
    var gradientColor = ctx.createLinearGradient(0, 0, 0, 400);
    if (type == 'success') {
        gradientColor.addColorStop(0, 'rgba(234,67,53,1)');
        gradientColor.addColorStop(1, 'rgba(234,67,53,0)');
    } else {
        gradientColor.addColorStop(0, 'rgba(82, 173, 49,1)');
        gradientColor.addColorStop(1, 'rgba(82, 173, 49,0)');
    }
    return gradientColor;
}
export const getDayFromDate = (timestamp) => {

    //Convert to UTC
    let clientDate = new Date(timestamp.substring(0, 10));
    
    var weekday = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
    
    let day = weekday[clientDate.getUTCDay()];
    return day;
}
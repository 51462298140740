import { GET_ACTIVE_ASSETS_INFO } from '../actions/types';

const initialState = {
    activeAssetsData: {},
    loading: false,
};

export default function (state = initialState, action) {
    switch (action.type) {
        case GET_ACTIVE_ASSETS_INFO:
            return {
                ...state,
                activeAssetsData: action.payload
            };


        default:
            return state;
    }
}
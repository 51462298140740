

import React from 'react';
import Container from '@material-ui/core/Container';
import { chartAreaBackColorDanger, chartAreaBackColorSuccess } from '../utility/Constants';
import {
    // getNewyorkTime,
    getUTCDateOnly,
    round,
    getDayFromDate
} from '../utility/Utility';

import SimpleBarChart from '../commonComponents/SimpleBarChart';

function PortfolioBarChart(props) {

    const { accountHistory } = props;
    // console.log(accountHistory)
    // console.log('accountHistory ____')

    let labels = [];
    let profitLossArr = [];
    // let lineColor = chartAreaBackColorSuccess;
    let barColorArr = [];
    let extraDataArr = [];
    let dayArr = [];
    if (accountHistory && Object.keys(accountHistory).length > 0) {
        for (var i = 0; i < Object.keys(accountHistory).length; i++) {
            if (i > 0) {
                let xValWithDays = getUTCDateOnly(accountHistory[i].valued_at);//getUTCDateOnly(accountHistory[i].valued_at) + " " + (getDayFromDate(accountHistory[i].valued_at));
                labels.push(xValWithDays);
                dayArr.push(getDayFromDate(accountHistory[i].valued_at));
                // labels.push(new Date(accountHistory[i].valued_at));
                let profLoss = (accountHistory[i].portfolio_value - accountHistory[i - 1].portfolio_value) ;
                let profLossVal = (accountHistory[i].portfolio_value - accountHistory[i - 1].portfolio_value) * 100 / accountHistory[i].portfolio_value;

                extraDataArr.push(round(profLoss, 2))
                profitLossArr.push(round(profLossVal, 4))
                if (profLossVal >= 0) {
                    barColorArr.push(chartAreaBackColorSuccess)
                } else {
                    barColorArr.push(chartAreaBackColorDanger)
                }

                // extraDataArr.push(roundToFour(accountHistory[i].portfolio_value - accountHistory[i - 1].portfolio_value))

            }
        }
    }



    const chartData = {
        labels: labels,
        datasets: [
            {
                label: 'Daily PL',
                data: profitLossArr,
                customData: extraDataArr,
                customLabel: dayArr,
                backgroundColor: barColorArr,
                borderColor: barColorArr,
                pointBackgroundColor: barColorArr,
                borderWidth: 0,
                fill: false,
                order: 1
            },
            // {
            //     type: 'bubble',
            //     label: 'Diff Value',
            //     backgroundColor: 'transparent',
            //     data: extraDataArr,
            //     borderColor: 'transparent',
            //     borderWidth: 0,
            //     fill: false,
            //     order: 1
            // },
        ],
        options: {
            legend: {
                display: false,
                position: "top",
            },
            scales: {
                xAxes: [{
                    // type: 'time',
                    // time: {
                    //     format: 'DD/MM/YYYY',
                    //     tooltipFormat: 'll'
                    // },
                    ticks: {
                        autoSkip: true,
                        maxTicksLimit: 8,
                        maxRotation: 0,
                        minRotation: 0,
                    },
                    gridLines: {
                        drawBorder: false,
                        zeroLineColor: "rgba(204, 204, 204, 0.1)",
                        color: "rgba(255,255,255, 0)",
                        display: true,
                    },
                }],
                yAxes: [{

                    gridLines: {
                        drawBorder: false,
                        color: "rgba(204, 204, 204, 0.1)",
                        zeroLineColor: "rgba(204, 204, 204, 0.05)",
                        display: true,
                    },
                    ticks: {
                        // suggestedMin: minClose,
                        // suggestedMax: maxClose,
                        beginAtZero: false
                    }
                }],

            },
            responsive: true,
            maintainAspectRatio: false,
            hover: {
                mode: 'index'
            },
            tooltips: {
                // enabled: false,

                xPadding: 10,
                yPadding: 10,
                position: 'nearest',
                mode: 'index',
                intersect: false,
                callbacks: {
                    title: function (tooltipItems, data) {
                        // console.log(tooltipItems)
                        // console.log(data)
                        var x = data.datasets[tooltipItems[0].datasetIndex];
                        var label = tooltipItems[0].label + " " +x.customLabel[tooltipItems[0].index] ;
                        
                        return label;
                    },
                    label: function (tooltipItems, data) {
                        var x = data.datasets[tooltipItems.datasetIndex];
                        var label = x.label || '';
                        label += ": " + tooltipItems.yLabel + " %";
                        label += " , Val = " + x.customData[tooltipItems.index] + "";
                        return label;
                    }
                }
            },

        }
    };

    if (chartData && Object.keys(chartData).length > 0) {
        return (
            <Container maxWidth="lg" >
                <div style={{ textAlign: 'center' }} className="chart-heading">
                    <span style={{ fontWeight: 'bold' }}>{'Daily PL %'}</span>
                </div>
                <SimpleBarChart chartData={chartData} />
            </Container>
        );
    }
    return <div />
}


export default PortfolioBarChart
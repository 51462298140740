

import React from 'react';
// import { withStyles, makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import { chartAreaBackColorDanger, chartAreaBackColorSuccess } from '../utility/Constants';
import {
    // getNewyorkTime,
    getUTCDateOnly,
    getDayFromDate
} from '../utility/Utility';
import SimpleLineChart from '../commonComponents/SimpleLineChart';
function PortfolioLineChart(props) {

    const { accountHistory } = props;
    // console.log(accountHistory)
    // console.log('accountHistory ____')

    let labels = [];
    let dayArr = [];
    let portfolioValArr = [];
    let investedvalArr = [];
    let portfolioLineColor = chartAreaBackColorSuccess;
    let investedLineColor = chartAreaBackColorDanger;
    if (accountHistory && Object.keys(accountHistory).length > 0) {
        accountHistory.map((data, i) => {
            // console.log(data)
            // console.log('data ___')
            labels.push(getUTCDateOnly(data.valued_at));
            dayArr.push(getDayFromDate(accountHistory[i].valued_at));
                
            portfolioValArr.push(data.portfolio_value)
            investedvalArr.push(data.invested_value)
        })
    }
    // console.log(labels)
    // console.log('labels __')

    const chartData = {
        labels: labels,
        datasets: [
            {
                label: 'Portfolio Value',
                data: portfolioValArr,
                customLabel: dayArr,
                backgroundColor: portfolioLineColor,
                borderColor: portfolioLineColor,
                pointBackgroundColor: portfolioLineColor,
                borderWidth: 0,
                pointRadius: 0,
                fill: false,
                order: 8
            },
            {
                label: 'Invested Value',
                data: investedvalArr,
                customLabel: dayArr,
                backgroundColor: investedLineColor,
                borderColor: investedLineColor,
                pointBackgroundColor: investedLineColor,
                borderWidth: 0,
                pointRadius: 0,
                fill: false,
                order: 8
            },
        ],
        options: {
            legend: {
                display: false,
                position: "top",
            },
            scales: {
                xAxes: [{
                    // type: 'time',
                    ticks: {
                        autoSkip: true,
                        maxTicksLimit: 10,
                        maxRotation: 0,
                        minRotation: 0,
                    },
                    gridLines: {
                        drawBorder: false,
                        zeroLineColor: "rgba(204, 204, 204, 0.1)",
                        color: "rgba(255,255,255, 0)",
                        display: true,
                    },
                }],
                yAxes: [{

                    gridLines: {
                        drawBorder: false,
                        color: "rgba(204, 204, 204, 0.1)",
                        zeroLineColor: "rgba(204, 204, 204, 0.05)",
                        display: true,
                    },
                    ticks: {
                        // suggestedMin: minClose,
                        // suggestedMax: maxClose,
                        beginAtZero: false
                    }
                }],

            },
            responsive: true,
            maintainAspectRatio: false,
            hover: {
                mode: 'index'
            },
            tooltips: {
                // enabled: false,

                xPadding: 10,
                yPadding: 10,
                position: 'nearest',
                mode: 'index',
                intersect: false,
                callbacks: {
                    title: function (tooltipItems, data) {
                        var x = data.datasets[tooltipItems[0].datasetIndex];
                        var label = tooltipItems[0].label + " " +x.customLabel[tooltipItems[0].index] ;
                        
                        return label;
                    }
                }
            },
            elements: {
                line: {
                    tension: 0.000001
                },

            },
        }
    };

    if (chartData && Object.keys(chartData).length > 0) {
        return (
            <Container maxWidth="lg" >
                <div style={{ textAlign: 'center' }} className="chart-heading">
                    <span style={{ fontWeight: 'bold' }}>{'Portfolio Value'}</span>
                </div>
                <SimpleLineChart chartData={chartData} />
            </Container>
        );
    }
    return <div />
}


export default PortfolioLineChart
import React, { Component } from 'react';
import {
    // Link, 
    Switch,
    Route
} from 'react-router-dom';
// import HomeContainer from '../container/HomeContainer';
import Login from '../container/Login';
import Signup from '../container/Signup';
// import Listing from '../container/Listing';

import OverView from '../components/OverView';
import AccountInfo from '../components/AccountInfo';
import Portfolio from '../components/Portfolio';
// import Orders from '../components/Orders';
import OrdersContainer from '../container/OrdersContainer';

class Routing extends Component {

    render() {
        return (
            <Switch>
                <Route exact path="/login/" render={(props) => <Login {...props} />}></Route>
                <Route exact path="/signup/" render={(props) => <Signup {...props} />}></Route>
                <Route exact path="/overview/" render={(props) => <OverView {...props} />}></Route>
                <Route exact path="/account/" render={(props) => <AccountInfo {...props} />}></Route>
                {/* <Route exact path="/portfolio/" render={(props) => <Portfolio {...props} />}></Route> */}
                <Route exact path="/position/" render={(props) => <Portfolio {...props} setLoader={(flg) => this.props.setLoader(flg)} />}></Route>
                <Route exact path="/order/" render={(props) => <OrdersContainer {...props} setLoader={(flg) => this.props.setLoader(flg)} />}></Route>
                <Route exact path="/order/:id" render={(props) => <OrdersContainer {...props} />}></Route>
                <Route path="/" render={(props) => <Portfolio {...props} />}></Route>

            </Switch >
        )
    }
}
export default Routing;

import React, { useEffect } from "react";

import '../css/style.css';
import { Chart } from 'chart.js';
import annotationPlugin from 'chartjs-plugin-annotation';


class SimpleBarChart extends React.Component {
    constructor(props) {
        super(props);
        this.canvasRef = React.createRef();
    }

    componentDidUpdate() {
        const { chartData } = this.props;
        if (chartData && Object.keys(chartData).length > 0) {

            this.myChart.data = {
                labels: chartData.labels,
                datasets: chartData.datasets,
            };
            this.myChart.options = chartData.options;
            this.myChart.update();
        }
    }

    componentDidMount() {

        const { chartData } = this.props;
        if (Object.keys(chartData).length > 0) {
            this.myChart = new Chart(this.canvasRef.current, {
                type: 'bar',
                data: {
                    labels: chartData.labels,
                    datasets: chartData.datasets,
                },
                options: chartData.options
            })
        }

    }

    componentWillUnmount() { }

    render() {

        const { chartData } = this.props;
        if (Object.keys(chartData).length > 0) {
            return <div style={{ justifyContent: 'center', alignItems: 'center', width: "100%" }}>
                {/* <canvas id="barCharoverlay" style={{ pointerEvents: "none", position: "absolute", display: "block" }} /> */}
                <canvas id="barCanvas" ref={this.canvasRef} style={{ height: 200 }} />
            </div>;
        }
        return <div />
    }
}
export default SimpleBarChart;
import React, { Component } from "react";
import { withRouter } from 'react-router-dom';
import { connect } from "react-redux";
import cookie from 'react-cookies';

import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Paper from '@material-ui/core/Paper';

import '../css/style.css';
import { createCookie } from '../utility/Utility';
import { registerUser } from '../actions/loginActions';

class Signup extends Component {

    constructor(props) {
        super(props);
        this.state = {
            fname: "",
            lname: "",
            email: "",
            password: "",
            statusMsg: "",
        };
    }


    handleChange = e => {
        // this.setState({ [e.currentTarget.id]: e.currentTarget.value });
        switch (e.currentTarget.id) {
            case "standard-fname-input":
                this.setState({ fname: e.currentTarget.value });
                break;
            case "standard-lname-input":
                this.setState({ lname: e.currentTarget.value });
                break;
            case "standard-email-input":
                this.setState({ email: e.currentTarget.value });
                break;
            case "standard-password-input":
                this.setState({ password: e.currentTarget.value });
                break;
            default:
                break;
        }
    };

    customNavigate = (page) => {
        this.props.history.push(page);
    }

    onSubmit = () => {

        const { fname, lname, email, password } = this.state;

        if (lname.length > 0 && fname.length > 0 && email.length > 0 && password.length > 0) {
            const { registerUser } = this.props;
            let _this = this;
            registerUser(fname, lname, email, password, function (res) {
                // console.log(res)
                // console.log('res ___')
                if (res && res.data.status == "success") {
                    // _this.setState({ statusMsg: res.data });
                    createCookie('login', true);
                    createCookie('userInfo', res.data.result);
                    _this.props.history.push('/');
                } else {
                    _this.setState({ statusMsg: res ? res.data.message : "Something went wrong, please try again." });
                }
            });
        } else {
            createCookie('login', false);
            this.setState({ statusMsg: "All fields are required" });
        }
    }
    componentDidMount() {
        if (cookie.load('login') == "true") {
            this.props.history.push("/");
        }
    }
    render() {
        if (cookie.load('login') == "true") {
            return <div />
        }
        return (
            <Paper elevation={4} style={{ marginTop: 50, padding: 20, boxShadow: "0 1px 5px 0 rgb(218 224 235 / 60%)" }}>
                {/* <Card style={{ display: 'flex', flexWrap: 'wrap', flexDirection: 'row', justifyContent: 'center', marginTop: 50 }}> */}
                <CardContent>
                    <div className="login-container" style={{ width: 500 }}>
                        <h4>Sign Up</h4>
                        {this.state.statusMsg.length > 0 && <p style={{ color: 'red' }}><label>{this.state.statusMsg}</label></p>}
                        <form className="form">
                            <div>
                                <TextField
                                    id="standard-fname-input"
                                    label="First Name"
                                    type="text"
                                    onChange={(e) => this.handleChange(e)}
                                    style={{ width: 200 }}
                                />
                            </div>
                            <div>
                                <TextField
                                    id="standard-lname-input"
                                    label="Last Name"
                                    type="text"
                                    onChange={(e) => this.handleChange(e)}
                                    style={{ width: 200 }}
                                />
                            </div>
                            <div>
                                <TextField
                                    id="standard-email-input"
                                    label="Email"
                                    type="text"
                                    onChange={(e) => this.handleChange(e)}
                                    style={{ width: 200 }}
                                />
                            </div>
                            <div style={{ paddingBottom: 20 }}>
                                <TextField
                                    id="standard-password-input"
                                    label="Password"
                                    type="password"
                                    autoComplete="current-password"
                                    onChange={(e) => this.handleChange(e)}
                                    style={{ width: 200 }}
                                />
                            </div>
                            <Button variant="contained" color="primary" onClick={() => this.onSubmit()}>Sign up </Button>
                            <p className="forgot-password text-right">
                                Already registered? <a activehref="javascript:void(0);" onClick={() => this.customNavigate('login')} style={{ textDecoration: 'underline', cursor: 'pointer', color: 'green' }}>Login now.</a>
                            </p>
                        </form>
                    </div>
                </CardContent>
                {/* </Card> */}
            </Paper>
        );
    }
}
const mapStateToProps = (state) => {
    return {}
};
export default connect(mapStateToProps, { registerUser })(withRouter(Signup));

import React from 'react';
// import PropTypes from 'prop-types';
import { withStyles, makeStyles } from '@material-ui/core/styles';
// import Box from '@material-ui/core/Box';
import Collapse from '@material-ui/core/Collapse';
import IconButton from '@material-ui/core/IconButton';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
// import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
// import { connect } from "react-redux";
// import { withRouter } from 'react-router-dom';
// import { getBlockListingInfo, getBlockChartInfo } from '../actions/orderActions';

import { round, getNewyorkTime } from '../utility/Utility';
import { defaultColor, greyOutColor, successColor, dangerColor, dangerColorFaded, successColorFaded, warningColor } from '../utility/Constants';
// import Uparrow from '../assets/img/Up_green_arrow.png';
// import Downarrow from '../assets/img/down.png';
// import AreaChart from '../commonComponents/AreaChart';
import CustomAreaChart from '../commonComponents/CustomAreaChart';

const useRowStyles = makeStyles({
    root: {
        '& > *': {
            borderBottom: 'unset',
        },
    },
});

const StyledTableCell = withStyles((theme) => ({
    head: {
        backgroundColor: "#666363",//theme.palette.common.black,
        color: theme.palette.common.white,
        // fontWeight: '600',
        fontSize: '0.8rem',
    },
    body: {
        fontSize: '0.8rem',
        fontWeight: '600'
    },
}))(TableCell);

function Row(props) {
    const { data } = props;
    const [open, setOpen] = React.useState(false);
    const classes = useRowStyles();
    let currMrktUplColor = defaultColor;
    if (!data.result) {
        return <div />
    }
    if (data.status < 0) {
        currMrktUplColor = greyOutColor;
    }

    let statusMsgColor = dangerColor
    if (data.status >= 100) {
        statusMsgColor = successColor
    } else if (data.status < 0) {
        statusMsgColor = dangerColor
    } else {
        statusMsgColor = warningColor
    }
    const { qty, limit_price, filled_qty, filled_avg_price, side, stop_price, created_at } = data.result;

    let sideBuySell = (data.side == null || data.side.length == 0) ? (side == null || side.length == 0 ? '' : side.toUpperCase()) : data.side.toUpperCase()

    return (
        <React.Fragment>
            <TableRow className={classes.root}>


                <StyledTableCell component="th" scope="row" style={{ color: currMrktUplColor }}>
                    <a className="th-a-link" onClick={() => props.linkClicked(data.symbol, 1)}>{data.symbol}</a>
                </StyledTableCell>
                <StyledTableCell align="left" style={{ color: currMrktUplColor }}>
                    <span style={{ backgroundColor: sideBuySell == "BUY" ? successColorFaded : dangerColorFaded, paddingLeft: 7, paddingRight: 7, borderRadius: 4 }}>
                        {sideBuySell}
                    </span>
                </StyledTableCell>

                <StyledTableCell align="right" style={{ color: currMrktUplColor }} >
                    {qty == null ? '-' : qty}
                </StyledTableCell>
                <StyledTableCell align="right" style={{ color: currMrktUplColor }} >
                    {filled_qty == null ? '-' : filled_qty}
                </StyledTableCell>
                {/* <StyledTableCell align="right" style={{ color: currMrktUplColor }} >
                    <span >{roundToTwo(data.current_price)}</span>
                    <span style={{ paddingLeft: 5 }}>
                        {data.pre_close < data.current_price ?
                            <img src={Uparrow} style={{ width: 10, height: 8, opacity: data.status < 0 ? 0.3 : 1 }} /> :
                            <img src={Downarrow} style={{ width: 10, height: 8, opacity: data.status < 0 ? 0.3 : 1 }} />}
                    </span>
                </StyledTableCell> */}

                <StyledTableCell align="right" style={{ color: currMrktUplColor }}>
                    {round(limit_price, 2)}
                </StyledTableCell>
                <StyledTableCell align="right" style={{ color: currMrktUplColor }}>
                    {round(stop_price, 2)}
                </StyledTableCell>

                <StyledTableCell align="right" style={{ color: currMrktUplColor }}>
                    {round(filled_avg_price, 2)}
                </StyledTableCell>
                <StyledTableCell align="left" style={{ color: statusMsgColor }}>
                    {data.status_msg}
                </StyledTableCell>
                <StyledTableCell align="left" style={{ color: currMrktUplColor }}>
                    {getNewyorkTime(data.created_on)}
                </StyledTableCell>
                <StyledTableCell>
                    <IconButton aria-label="expand row" size="small" onClick={() => {
                        setOpen(!open);
                    }}>
                        {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                    </IconButton>
                </StyledTableCell>
            </TableRow>
            <TableRow>
                <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={12}>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <div style={{ flex: 1, height: 290, width: '100%', alignItems: 'center' }}>
                            {Object.keys(data).length > 0 && <CustomAreaChart
                                getBlockChartInfo={props.getBlockChartInfo}
                                getSignalChartInfo={props.getSignalChartInfo}
                                listInfo={data}
                                buyDate={created_at ? created_at : 0}
                                sellDate={created_at ? created_at : 0}
                                isList={true}
                                buyPrice={(sideBuySell == 'BUY') ? round(filled_avg_price, 2) : undefined}
                                sellPrice={(sideBuySell == 'SELL') ? round(filled_avg_price, 2) : undefined}

                                containerId={'div_' + data.symbol + '_' + data.id + '_chart'} />}

                        </div>
                    </Collapse>
                </TableCell>
            </TableRow>
        </React.Fragment >
    );
}




function CollapsibleTable(props) {

    const { orderInfo, blockChartData } = props;
    // console.log(orderInfo)
    // console.log('orderInfo ___')
    return (
        <TableContainer component={Paper}>
            <Table aria-label="collapsible table">
                <TableHead>
                    <TableRow>
                        <StyledTableCell>Symbol</StyledTableCell>
                        <StyledTableCell align="left">Side</StyledTableCell>
                        <StyledTableCell align="right">QTY #</StyledTableCell>
                        <StyledTableCell align="left">Filled Qty #</StyledTableCell>
                        {/* <StyledTableCell align="left">Current Price $</StyledTableCell> */}
                        <StyledTableCell align="right">Limit Price $</StyledTableCell>
                        <StyledTableCell align="right">Stop Price $</StyledTableCell>
                        <StyledTableCell align="right">Filled Avg Price $</StyledTableCell>
                        <StyledTableCell align="left">Status</StyledTableCell>
                        <StyledTableCell align="left">Created On</StyledTableCell>
                        <StyledTableCell align="left"></StyledTableCell>

                    </TableRow>
                </TableHead>
                <TableBody>
                    {orderInfo.map((data, i) => (
                        <Row key={data.result ? data.result.id : 'cust_id' + i} data={data} {...props} />
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
}


export default CollapsibleTable
import React from 'react';
import { connect } from "react-redux";
import Container from '@material-ui/core/Container';
// import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import { getBlockListingInfo, getCommonChartInfo, getSignalChartInfo, getActiveAssetsInfo } from '../actions/orderActions';
import {
    formatDateYYYYmmdd,
    getNumLabels,
    getUTCDateOnly,
    round
} from '../utility/Utility';
import {
    commonChartdateOffset,
    chartLineColorDanger,
    chartLineColorSuccess,
    chartAreaBackColorDanger,
    chartAreaBackColorSuccess,
    buyPointColor,
    sellPointColor,
    dangerColorFaded,
    grstopColor,
    crstopColor,
    successColor,
    dangerColor,
} from '../utility/Constants';
// import AreaChart from '../commonComponents/AreaChart';
import Loader from '../commonComponents/Loader';
import ChartjsGraph from '../commonComponents/ChartjsGraphs';
import Uparrow from '../assets/img/Up_green_arrow.png';
import Downarrow from '../assets/img/down.png';

class CommonChartView extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            chartPrepared: false,
            chartData: {},
            curStartIndex: 0,
            curEndIndex: 0,
            chartDays: "1M"
        }
        this.allChartData = {}
    }

    createChartData(resData, blockInfo, sigData) {

        const pointRadiusBuy = 4;
        const pointRadiusSell = 6;//6;

        let preparedChartData = {}
        if (resData == null) {
            return preparedChartData;
        }
        let labels = [];
        let closeData = [];
        let openData = [];
        let highData = [];
        let lowData = [];

        let pointBorderRadiusArr = [];
        let dates = [];
        let pointColorArr = [];
        let finalCalBuyPriceData = [];
        let finalCalSellPriceData = [];

        resData.map(function (cdata, i) {

            // console.log(getUTCDateOnly(cdata.date))
            labels.push(getUTCDateOnly(cdata.date));
            // labels.push(cdata.date);
            dates.push(cdata.date);
            closeData.push(cdata.close);
            openData.push(cdata.open);
            highData.push(cdata.high);
            lowData.push(cdata.low);

        });

        /******************** BUY SELL DATE *****************/
        const buyDatesArr = [];
        const sellDatesArr = [];
        const buyPriceArr = []; //avg_buy_price
        const sellPriceArr = [];

        if (blockInfo && Object.keys(blockInfo).length > 0) {
            blockInfo.map((binfo) => {

                // console.log(binfo)
                // console.log('binfo ___')

                let buyResult = binfo.buy_result ? binfo.buy_result : {}
                let sellResult = binfo.sell_result ? binfo.sell_result : {}
                if (Object.keys(buyResult).length > 0) {
                    buyDatesArr.push((buyResult.filled_at))
                    buyPriceArr.push(binfo.avg_buy_price)
                }
                if (Object.keys(sellResult).length > 0) {
                    sellDatesArr.push((sellResult.filled_at))
                    sellPriceArr.push(binfo.avg_sell_price)
                }
            })
        }


        /*************** GRSTOP Data line **************/

        let signalDateData = [];
        if (sigData && Object.keys(sigData).length > 0) {
            sigData.map(function (sdata, i) {
                signalDateData.push(getUTCDateOnly(sdata.date_obj))
                // signalDateData.push(getUTCDateOnly(sdata.date_obj))
            });
        }

        let commonChartDate = [];
        dates.map(function (cdata, j) {
            commonChartDate.push(getUTCDateOnly(cdata));
            // commonChartDate.push(getUTCDateOnly(cdata));
        })

        let grstopArr = [];
        let crstopArr = [];
        let triggerArr = [];

        let indexArr = [];
        let prevVal = NaN;
        let prevValCR = NaN;
        for (var j = 0; j < signalDateData.length; j++) {
            let hasEqualVal = false;

            for (var i = 0; i < commonChartDate.length; i++) {
                if (commonChartDate[i] == signalDateData[j]) {
                    hasEqualVal = true;
                    // console.log(commonChartDate[i] + ' === ' + signalDateData[j] + ' == ' + sigData[j].grstop + ' == ' + i)
                    // console.log('bddata found ___')
                    grstopArr[i] = sigData[j].grstop;
                    prevVal = sigData[j].grstop;
                    crstopArr[i] = sigData[j].cutoverstop;
                    prevValCR = sigData[j].cutoverstop;


                    indexArr.push(i);
                    triggerArr[i] = sigData[j].trigger;

                    break;
                } else {
                    // console.log('i elese ___' + i)
                    hasEqualVal = false;
                    if (indexArr.indexOf(i) == -1) {
                        grstopArr[i] = prevVal; //NaN;
                        crstopArr[i] = prevValCR; //NaN;
                        triggerArr[i] = NaN
                        // triggerVertLineDateArr.splice(i, 1)
                    }
                }
            }

            if (hasEqualVal) {
                continue;
            }
            // console.log('\n\n')

        }

        let triggerData = [];
        // let triggerVertLineDateArr = [];
        let triggerVertLineObjArr = [];
        if (triggerArr && Object.keys(triggerArr).length > 0) {
            triggerArr.map(function (sdata, i) {
                // triggerData.push(sdata.trigger);
                //Logic : hiegest value of close value pushed into trigger array in order to show the None, Suy, Sell(Trigger values)strings
                // console.log("sdata - ", sdata, " -- " , Math.max(...closeData))
                // console.log(sdata, ' -- sdata ___', getUTCDateOnly(resData[i].date))

                switch (sdata) {
                    case 'None':
                        triggerData.push(Math.max(...closeData));
                        break;
                    case 'Buy':
                        triggerData.push(Math.max(...closeData) - 1);
                        triggerVertLineObjArr[i] = {
                            date: resData[i] ? getUTCDateOnly(resData[i].date) : '',//labels[i], //Showing triggle one day before
                            xval: i,
                            value: 'Buy'
                        }
                        break;
                    case 'Sell':
                        triggerData.push(Math.max(...closeData) - 2);
                        triggerVertLineObjArr[i] = {
                            date: resData[i] ? getUTCDateOnly(resData[i].date) : '',//labels[i], //Showing triggle one day before
                            xval: i,
                            value: 'Sell'
                        }
                        break;
                    default:
                        break;
                }

            });
        }

        /*************** GRSTOP Data line **************/

        let calBuyDatesArr = [];
        buyDatesArr.map((bdate, i) => {
            calBuyDatesArr[i] = getUTCDateOnly(bdate)
        })
        let calSellDatesArr = [];
        sellDatesArr.map((sdate, i) => {
            calSellDatesArr[i] = getUTCDateOnly(sdate)
        })

        /************************************/
        // Calculate the num of lables to be displayed to get the max chance of showing the last date.
        let numLabel = getNumLabels(labels.length);
        if (numLabel < 4) {
            numLabel = 9
        }

        /**********************************/

        labels.map((ldata, i) => {

            // console.log(ldata)

            pointBorderRadiusArr.push(0);
            pointColorArr.push(chartLineColorDanger);

            finalCalBuyPriceData[i] = {
                x: ldata,
                y: null,
                r: 0
            }
            finalCalSellPriceData[i] = {
                x: ldata,
                y: null,
                r: 0
            }

            let key = null;
            for (let j = 0; j < calBuyDatesArr.length; j++) {
                if (calBuyDatesArr[j] == ldata) {
                    // console.log(calBuyDatesArr[j] + '==' + ldata)
                    // console.log('buy found ...')

                    key = j;
                    finalCalBuyPriceData[i] = {
                        x: ldata,
                        y: buyPriceArr[key],
                        r: pointRadiusBuy
                    }
                    continue;
                }
            }
            pointBorderRadiusArr[key] = pointRadiusBuy;
            pointColorArr[key] = buyPointColor;


            let skey = null;
            for (let k = 0; k < calSellDatesArr.length; k++) {
                if (calSellDatesArr[k] == ldata) {
                    // console.log(calSellDatesArr[k] + '==' + ldata)
                    // console.log('sell found ...')

                    finalCalSellPriceData[i] = {
                        x: ldata,
                        y: sellPriceArr[k],
                        r: pointRadiusSell
                    }
                    skey = k;
                    continue;
                }
            }
            pointBorderRadiusArr[skey] = pointRadiusSell;
            pointColorArr[skey] = sellPointColor;

            // console.log("\n\n")

        })

        let areaBackgroundColor = chartAreaBackColorDanger;
        // let areaBackgroundColor = getGradientColor('chart-js-container', 'danger');
        let borderColor = chartLineColorDanger;
        if (closeData[0] < closeData[closeData.length - 1]) {
            // areaBackgroundColor = getGradientColor('chart-js-container', 'success'); //chartAreaBackColorSuccess;
            areaBackgroundColor = chartAreaBackColorSuccess;
            borderColor = chartLineColorSuccess;
        }

        let annotations = triggerVertLineObjArr.map(function (data, index) {
            // console.log("vline -- ", data.xval, " -- ", data.date, " -- ", data.value)
            return {
                type: 'line',
                id: 'vline' + index,
                mode: 'vertical',
                scaleID: 'x-axis-0',
                value: data.xval,
                borderColor: data.value == 'Buy' ? buyPointColor : sellPointColor,//'green', 'rgba(255, 99, 132, 0.8)'
                borderWidth: 15,
                label: {
                    enabled: true,
                    position: "center",
                    // content: data.value,//amount[index],
                    // backgroundColor: data.value == 'Buy' ? chartAreaBackColorDanger : chartLineColorSuccess
                }
            }
        });

        const chartData = {

            labels: labels,
            datasets: [

                // {
                //     label: 'CO STOP',
                //     data: crstopArr,
                //     backgroundColor: crstopColor,
                //     borderColor: crstopColor,
                //     pointBackgroundColor: crstopColor,
                //     borderWidth: 0,
                //     pointRadius: 0,
                //     fill: false,
                //     order: 8
                // },
                // {
                //     label: 'GR STOP',
                //     data: grstopArr,
                //     backgroundColor: grstopColor,
                //     borderColor: grstopColor,
                //     pointBackgroundColor: grstopColor,
                //     borderWidth: 0,
                //     pointRadius: 0,
                //     fill: false,
                //     order: 7
                // },
                {
                    label: 'Trigger',
                    data: triggerData,
                    backgroundColor: 'transparent',
                    borderColor: 'transparent',
                    borderWidth: 0,
                    pointRadius: 0,
                    fill: false,
                    order: 8
                },
                {
                    type: 'line',
                    label: 'Close',
                    backgroundColor: areaBackgroundColor,
                    borderColor: 'transparent',
                    data: closeData,
                    borderWidth: 0,
                    pointRadius: 0,
                    fill: 'start',
                    // order: 1
                },


                {
                    type: 'line',
                    label: 'Open',
                    data: openData,
                    fill: false,
                    borderWidth: 0,
                    pointRadius: 0,
                    borderColor: "transparent",
                    backgroundColor: "transparent",//"transparent",
                    pointBorderColor: "transparent",
                },
                {
                    label: 'High',
                    data: highData,
                    fill: false,
                    borderWidth: 0,
                    pointRadius: 0,
                    borderColor: "transparent",
                    backgroundColor: "transparent",//"transparent",
                    pointBorderColor: "transparent",

                },
                {
                    label: 'Low',
                    data: lowData,
                    fill: false,
                    borderWidth: 0,
                    pointRadius: 0,
                    borderColor: "transparent",
                    backgroundColor: "transparent",
                    pointBorderColor: "transparent",
                },

                {
                    type: 'bubble',
                    label: 'Buy Price',
                    backgroundColor: chartAreaBackColorSuccess,//"rgba(33, 138, 61,0.5)",
                    data: finalCalBuyPriceData,//buyPriceDataArr,
                    borderColor: 'white',
                    borderWidth: 1
                },
                {
                    type: 'bubble',
                    label: 'Sell Price',
                    backgroundColor: chartAreaBackColorDanger, //"rgba(33, 138, 61,0.5)",
                    data: finalCalSellPriceData,//sellPriceDataArr,
                    borderColor: 'white',
                    borderWidth: 1
                },


            ],
            options: {
                legend: {
                    display: false,
                    position: "top",
                },


                plugins: {
                    filler: {
                        propagate: false
                    },

                },
                scope: {
                    override: {
                        fill: false
                    }
                },
                scales: {
                    xAxes: [{
                        // type: 'time',
                        bounds: 'ticks',
                        ticks: {
                            // autoSkip: true,
                            maxTicksLimit: numLabel,
                            maxRotation: 0,
                            minRotation: 0,
                            major: {
                                enabled: true,
                            }
                        },
                        gridLines: {
                            drawBorder: false,
                            zeroLineColor: "rgba(204, 204, 204, 0.1)",
                            color: "rgba(255,255,255, 0)",
                            display: false,
                        },
                    }],
                    yAxes: [{

                        gridLines: {
                            drawBorder: false,
                            color: "rgba(204, 204, 204, 0.1)",
                            zeroLineColor: "rgba(204, 204, 204, 0.05)",
                            display: false,
                        },
                        ticks: {
                            // suggestedMin: minClose,
                            // suggestedMax: maxClose,
                            beginAtZero: false
                        },
                    }],

                },
                annotation: {
                    drawTime: 'beforeDatasetsDraw',
                    annotations: annotations
                },
                responsive: true,
                title: {
                    display: true,
                    // text: symbol ? symbol : ''
                },
                maintainAspectRatio: false,
                hover: {
                    mode: 'index'
                },
                tooltips: {
                    // enabled: false,
                    axis: 'x',
                    xPadding: 10,
                    yPadding: 10,
                    // position: 'nearest',
                    mode: 'index',
                    intersect: false,
                    callbacks: {
                        label: function (tooltipItems, data) {

                            var label = data.datasets[tooltipItems.datasetIndex].label || '';
                            if (label) {
                                label += ': ';
                            }


                            if (label == 'Trigger: ') {
                                //Logic : Cmpared the the values pushed to trigger array to show on tooltip
                                if (tooltipItems.yLabel == Math.max(...closeData)) {
                                    label += 'None';
                                } else if (tooltipItems.yLabel == Math.max(...closeData) - 1) {
                                    label += 'Buy';
                                } else if (tooltipItems.yLabel == Math.max(...closeData) - 2) {
                                    label += 'Sell';
                                }
                            } else {
                                label += "$" + Math.round(tooltipItems.yLabel * 100) / 100;
                            }

                            return label;
                        }
                    }
                },
                elements: {
                    line: {
                        tension: 0.000001,
                        fill: 'top'
                    },
                    // point: {
                    //     pointStyle: 'circle'
                    // }
                },
            }
        };
        this.allChartData = chartData;
        return chartData;
    }
    componentDidMount() {

        // console.log('componentDidMount ____')
        // console.log(this.props)
        // console.log('this.props ___')

        let _this = this;
        const { getBlockListingInfo, symbol, getActiveAssetsInfo } = this.props;
        getBlockListingInfo('all', symbol, function () {
            _this.prepareDataForChart()
        });
        getActiveAssetsInfo(symbol, function (res) {

        })
    }

    prepareDataForChart() {
        const { symbol, getCommonChartInfo, getSignalChartInfo, blockInfo } = this.props;


        let _this = this;
        let startDate = new Date();
        let endDate = new Date();
        startDate.setDate(startDate.getDate() - commonChartdateOffset);
        startDate = formatDateYYYYmmdd(startDate);
        endDate = formatDateYYYYmmdd(endDate);
        // 
        // getCommonChartInfo(symbol, startDate, endDate, function (resData) {
        getCommonChartInfo(symbol, startDate, endDate, function (resData) {

            if (Object.keys(resData).length > 0) {

                getSignalChartInfo(symbol, startDate, endDate, function (sigData) {

                    // const chartData = _this.createChartData(resData, blockInfo, sigData);
                    // _this.setState({
                    //     chartData: chartData
                    // });
                    //Change chart to 1M, after full year Data fetched
                    _this.changeChart('1M');
                }, true)

            }
        })
    }
    grapheslected(startIndex, endIndex) {
        // console.log('implement filter between ' +startIndex + ' and ' + endIndex);  

        let sX = startIndex;
        let eX = endIndex;
        if (endIndex < startIndex) {
            eX = startIndex;
            sX = endIndex;
        }

        this.changeChart("custom", sX, eX);
    }

    changeChart(dayOffset, startIndex, endIndex) {
        // console.log(this.props)
        // console.log("dayOffset - ", dayOffset, ", startIndex - ", startIndex, ", endIndex - ", endIndex, ", curStartIndex - ", this.state.curStartIndex);

        const { commonChartInfo, blockInfo, signalsInfo } = this.props;
        this.state.chartDays = dayOffset;

        if (commonChartInfo == null || Object.keys(commonChartInfo).length == 0 || Object.keys(signalsInfo).length == 0) {
            return;
        }
        let chartDates = [];
        commonChartInfo.map(function (cdata) {
            chartDates.push(new Date(cdata.date));
        });
        const maxDate = new Date(Math.max(...chartDates));

        let calculatedCommonChartInfo = [];

        let chartDateLen = Object.keys(chartDates).length;

        // console.log(dayOffset)

        let till = new Date()

        let basedOnDate = true;
        if (dayOffset.toLowerCase() === "custom") {
            basedOnDate = false;
        }
        let eX = chartDateLen;
        let sX = this.state.curStartIndex;
        if (basedOnDate) {
            if (dayOffset.endsWith('M')) {
                till.setMonth(till.getMonth() - dayOffset.slice(0, -1))
            } else if (dayOffset.endsWith('Y')) {
                till.setFullYear(till.getFullYear() - dayOffset.slice(0, -1))
            } else {
                till.setDate(till.getDate() - dayOffset.slice(0, -1))
            }
        } else {

            eX = this.state.curStartIndex + endIndex;
            sX = this.state.curStartIndex + startIndex;
            // console.log('PPPP ' + sX + ' and ' + eX);  

            if (eX - sX == 0) {

                if (this.state.endIndex === undefined) {
                    this.state.endIndex = chartDateLen
                }
                // Double cliecked
                let currentDiff = this.state.endIndex - this.state.curStartIndex
                let nSX = sX - parseInt(currentDiff / 3);
                let nEX = eX + parseInt(currentDiff / 3);
                // console.log('000 nSX = ' + nSX + ', nEX = ' + nEX + ', start = ' + this.state.curStartIndex + ', end = ' + this.state.endIndex);  
                if (nSX > this.state.curStartIndex) {
                    sX = nSX;
                } else {
                    sX = this.state.curStartIndex
                }

                if (nEX < this.state.endIndex) {
                    eX = nEX;
                } else {
                    eX = this.state.endIndex
                }

                // console.log('000 sX = ' + sX + ', eX = ' + eX);  
                // return;
            } else if (eX - sX < 3) {
                eX = eX + 2;
                sX = sX - 2;
            } else {
                eX = eX + 1;
                sX = sX - 1;
            }
        }

        // console.log("dayOffset - ", dayOffset, ", sX - ", sX, ", eX - ", eX);  

        // console.log("till = " , till)

        let numLabel = 0
        let i = 0;
        for (let j = chartDateLen - 1; j >= 0; j--) {
            // console.log("j - ", j, ", i - ", i, ", sX - ", sX, ", eX - ", eX, ", ci - ", this.state.curStartIndex);  

            if (j > eX) {
                continue
            }
            if (i == 0) {
                this.state.curEndIndex = j + 1;
            }

            let tDate = new Date(commonChartInfo[j].date)
            // console.log("numLabel = " , numLabel)

            if (
                ((basedOnDate && tDate.getTime() < till.getTime()) || (!basedOnDate && (j < sX)))
                && calculatedCommonChartInfo.length >= 5 && numLabel > 0) {
                // console.log("WHY ???? j - ", j, ", i - ", i, ", sX - ", sX, ", eX - ", eX);  
                // 5 is the min length
                break;
            }
            calculatedCommonChartInfo[i] = commonChartInfo[j]
            this.state.curStartIndex = j;
            numLabel = getNumLabels(calculatedCommonChartInfo.length);
            i++;
        }
        // console.log("FFF numLabel = " , numLabel, ", LEN - ", calculatedCommonChartInfo.length)

        calculatedCommonChartInfo.reverse()
        calculatedCommonChartInfo = calculatedCommonChartInfo.filter(function (el) {
            return el != null;
        });


        // console.log(calculatedCommonChartInfo)
        // console.log('calculatedCommonChartInfo ____')
        let _this = this;
        const chartData = _this.createChartData(calculatedCommonChartInfo, blockInfo, signalsInfo);
        _this.setState({
            chartData: chartData
        })

    }
    render() {

        const { activeAssetsDataInfo } = this.props;

        let tingoLast, tingoPrev, currPrice, changeValue = 0;
        let changeColor = successColor;
        let assetName, actSymbol = '';
        if (activeAssetsDataInfo && Object.keys(activeAssetsDataInfo).length > 0) {

            assetName = activeAssetsDataInfo.name;
            actSymbol = activeAssetsDataInfo.symbol;
            currPrice = activeAssetsDataInfo.current_price
            tingoLast = activeAssetsDataInfo.tingo_info.tngoLast ? activeAssetsDataInfo.tingo_info.tngoLast : 0;
            tingoPrev = activeAssetsDataInfo.tingo_info.prevClose ? activeAssetsDataInfo.tingo_info.prevClose : 0;
            changeValue = tingoLast - tingoPrev;
            changeValue = round(changeValue, 2);

            if (changeValue < 0) {
                changeColor = dangerColor
            }
        }

        // console.log(this.state.chartData)
        // console.log('this.state.chartData ___')
        let dayOptions = ["5D", "15D", "1M", "3M", "6M", "1Y"];
        var spans = [];
        for (var i = 0; i < dayOptions.length; i++) {
            let dayop = dayOptions[i];
            spans.push(
                <span style={{ textDecoration: 'underline' }} key={i}>
                    <Button
                        color="primary"
                        onClick={() => {
                            this.changeChart(dayop);
                        }}
                        className="chart-action-button"
                        style={{ backgroundColor: this.state.chartDays === dayop ? dangerColorFaded : 'transparent' }}
                    >{dayop}</Button>
                </span>
            )
        }
        return (
            <Container maxWidth="lg" style={{ height: 300 }}>
                <div style={{ textAlign: 'left' }} className="chart-heading">
                    <span>{assetName}</span>
                    <span style={{ marginLeft: 4 }}>({actSymbol})</span>
                    <span style={{ marginLeft: 7, color: changeColor }}>{currPrice}</span>
                    <span style={{ marginLeft: 7 }}>
                        {
                            changeValue > 0 ?
                                <img src={Uparrow} style={{ width: 10, height: 8 }} /> :
                                <img src={Downarrow} style={{ width: 10, height: 8 }} />
                        }
                    </span>
                    <span style={{ marginLeft: 7, color: changeColor }} className="change-value">({changeValue})</span>
                </div>
                <div style={{ textAlign: 'left', fontWeight: 'bold' }} className="chart-action-heading">{spans}</div>
                {(this.state.chartData && Object.keys(this.state.chartData).length > 0) ? <ChartjsGraph
                    {...this.props}
                    chartData={this.state.chartData}
                    onSelect={this.grapheslected.bind(this)}
                /> : <Loader />}
            </Container>

        )
    }

}

// export default CommonChartView;
const mapStateToProps = (state) => {
    // console.log(state)
    // console.log('state ____')

    const { blockChartData } = state.blockChartInfo ? state.blockChartInfo : {};
    const { blockListData } = state.blockInfo ? state.blockInfo : {};
    const { activeAssetsData } = state.activeAssetsInfo ? state.activeAssetsInfo : {};
    let blockInfo = blockListData.result ? blockListData.result : {};
    let activeAssetsDataInfo = activeAssetsData ? activeAssetsData[0] : {};

    const { commonChartInfo } = state.commonChartInfoData ? state.commonChartInfoData : {}
    const { signalsInfo } = state.signalsInfoData ? state.signalsInfoData : {}

    // console.log(signalsInfo)
    // console.log('signalsInfo ____ state')
    return {
        blockInfo,
        blockChartData,
        activeAssetsDataInfo,
        commonChartInfo,
        signalsInfo
    }
};
export default connect(mapStateToProps, { getBlockListingInfo, getCommonChartInfo, getSignalChartInfo, getActiveAssetsInfo })(CommonChartView);
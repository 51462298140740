
// import qs from "qs";
import { ACCOUNT_HISTORY } from './types'
import { accountHistoryUrl } from '../utility/Constants';
import { httpGetRequest } from "../utility/httpRequests";
import { getUserInfo } from '../utility/Utility';

export const getAccountHistory = (callback) => async (
    dispatch
) => {


    let userInfoD = getUserInfo();
    let url = accountHistoryUrl;
    try {
        url = url.replace("userID", userInfoD.id);
    } catch (err) {
    }

    // console.log(url)
    // console.log('url ___')
    httpGetRequest(url, function (response) {
        // console.log(response)
        // console.log('response account history___')
        if (response.data && response.data.status == 'success') {
            dispatch({
                type: ACCOUNT_HISTORY,
                payload: response.data,
            });
        }
        callback(response.data);
    })
};

import { ACCOUNT_HISTORY } from '../actions/types';

const initialState = {
    accountHistoryData: {},
    loading: false,
};

export default function (state = initialState, action) {
    switch (action.type) {
        case ACCOUNT_HISTORY:
            return {
                ...state,
                accountHistoryData: action.payload
            };
        default:
            return state;
    }
}

import {
    POSITION_INFO_SUCCESS,
    // POSITION_INFO_FAIL,
    FORCE_SELL_SUCCESS,
    SUPPRESS_ACTIONS
} from './types'
import {
    positionsUrl,
    forceSellUrl,
    suppressUrl,
    unsuppressUrl,

} from '../utility/Constants';
import { httpGetRequest } from "../utility/httpRequests";
import { getUserInfo } from '../utility/Utility';

export const getPositionsInfo = (sortOrder, fieldName, callback) => async (
    dispatch
) => {

    let userInfoD = getUserInfo();
    let url = positionsUrl;
    try {
        url = url.replace("userID", userInfoD.id);
    } catch (err) {

    }
    // if (sortOrder.length > 0) {
    //     url = url + '?sort=' + sortOrder
    // }
    // if (fieldName.length > 0) {
    //     url = url + '&sort_by=' + fieldName
    // }


    // console.log(url)
    // console.log('position url ___')
    httpGetRequest(url, function (response) {
        // console.log(response)
        // console.log('response ___')
        if (response.data && response.data.status === 'success') {
            dispatch({
                type: POSITION_INFO_SUCCESS,
                payload: response.data,
            });
        }
        callback(response.data);
    })
};
export const forceSell = (positionId, callback) => {

    let userInfoD = getUserInfo();
    let url = forceSellUrl;
    try {
        url = url.replace("userID", userInfoD.id);
    } catch (err) { }
    url = url.replace("positionID", positionId);

    // console.log(url)
    // console.log('forceSellUrl url ___')

    httpGetRequest(url, function (response) {
        // console.log(response)
        // console.log('response forcesell___')
        callback(response.data);
    })
};

export const suppressAction = (positionId, sTag, callback) => {

    let userInfoD = getUserInfo();
    let url = sTag ? suppressUrl : unsuppressUrl;

    try {
        url = url.replace("userID", userInfoD.id);
    } catch (err) { }
    url = url.replace("positionID", positionId);


    // console.log(url)
    // console.log('supp url ___')
    httpGetRequest(url, function (response) {
        // console.log(response)
        // console.log('response supp___')
        callback(response.data);
    })
};
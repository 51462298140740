import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Container from '@material-ui/core/Container';
// import TableFooter from '@material-ui/core/TableFooter';
// import TablePagination from '@material-ui/core/TablePagination';


// import InputLabel from '@material-ui/core/InputLabel';
// import FormControl from '@material-ui/core/FormControl';
// import Select from '@material-ui/core/Select';
// import TextField from '@material-ui/core/TextField';
// import Button from '@material-ui/core/Button';
// import ArrowBackIcon from '@material-ui/icons/ArrowBack';

import Paper from '@material-ui/core/Paper';
import { connect } from "react-redux";
import { withRouter } from 'react-router-dom';



import { getOrderInfo, getBlockChartInfo, getSignalChartInfo } from '../actions/orderActions';
import { round, datetimeFromTimestampMdy } from '../utility/Utility';
import { defaultColor, greyOutColor } from '../utility/Constants';
import FadeInContainer from '../commonComponents/FadeInContainer';
import Uparrow from '../assets/img/Up_green_arrow.png';
import Downarrow from '../assets/img/down.png';
import Backarrow from '../assets/img/backarrow.png';
import ListCollapsibleTable from './ListCollapsibleTable';
import Loader from '../commonComponents/Loader';

import '../css/style.css';


const StyledTableCell = withStyles((theme) => ({
    head: {
        backgroundColor: "#666363",//theme.palette.common.black,
        color: theme.palette.common.white,
        // fontWeight: '600',
        fontSize: '0.8rem',
    },
    body: {
        fontSize: '0.8rem',
        fontWeight: '600'
    },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
    root: {
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.action.hover,
        },
    },
}))(TableRow);


class Orders extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            page: 0,
            rowsPerPage: 5,
            status: 'open',
            searchVal: '',
            isLoaded: false
        }
    }
    abortOntroller = new AbortController();
    componentDidMount() {

        let _this = this;
        const { qstr } = this.props;
        // console.log("componentDidMount _______ list" + qstr);

        if (qstr && qstr.length > 0) {
            this.setState({
                searchVal: qstr,
                status: 'all'
            });
            if (document.getElementById("list-symbol-search")) {
                document.getElementById("list-symbol-search").value = qstr;
            }

            this.props.getOrderInfo('all', qstr, function () {
                _this.setState({
                    isLoaded: true
                });
                _this.props.hideLoader();
            })
        } else {
            // this.props.getOrderInfo('open', '', function () { }) //Changed for all listing
            this.setState({
                status: 'all'
            });
            this.props.getOrderInfo('all', '', function () {
                _this.setState({
                    isLoaded: true
                });
                _this.props.hideLoader();
            })
        }
    }
    componentWillUnmount() {
        this.abortOntroller.abort();
    }
    handleChange(event) {
        let _this = this;
        const status = event.target.value;
        this.setState({
            status: status
        })
        this.props.getOrderInfo(status, this.state.searchVal, function () {
            _this.setState({
                isLoaded: true
            });
            _this.props.hideLoader();
        })
    }
    handleOnTxtChange(event) {
        let _this = this;
        const searchVal = event.target.value;

        if (searchVal && searchVal.length == 0) {
            this.setState({
                searchVal: searchVal,
                status: 'open'
            });
        } else {
            this.setState({
                searchVal: searchVal,
            });
        }

        this.props.getOrderInfo(this.state.status, searchVal, function () {
            _this.setState({
                isLoaded: true
            });
            _this.props.hideLoader();
        })
    }
    symbolLink = (symbolVal) => {

        //1 is tab index of blocks
        this.props.linkClicked(symbolVal, 1);
    }
    render() {

        const { orderInfo, qstr, propLoading } = this.props;
        // console.log(this.props)
        // console.log('this.props _______ orders')

        const Headers = () => {
            return (
                <TableHead>
                    <TableRow>
                        <StyledTableCell>Symbol</StyledTableCell>
                        <StyledTableCell align="right">Side</StyledTableCell>
                        <StyledTableCell align="right">QTY #</StyledTableCell>
                        <StyledTableCell align="right">Filled Qty #</StyledTableCell>
                        <StyledTableCell align="right">Current Price $</StyledTableCell>
                        <StyledTableCell align="right">Limit Price $</StyledTableCell>
                        <StyledTableCell align="right">Stop Price $</StyledTableCell>
                        <StyledTableCell align="right">Filled Avg Price $</StyledTableCell>
                        <StyledTableCell align="right">Status</StyledTableCell>
                        <StyledTableCell align="right">Created On</StyledTableCell>

                    </TableRow>
                </TableHead>
            )
        }
        const PopulateTableCell = () => {
            let _this = this;
            const posMap = orderInfo.map(function (data, i) {

                let currMrktUplColor = defaultColor;
                if (!data.result) {
                    return <div />
                }
                if (data.status < 0) {
                    currMrktUplColor = greyOutColor;
                }
                const { qty, limit_price, filled_qty, filled_avg_price, side, stop_price } = data.result;
                return (
                    <StyledTableRow key={i}>
                        <StyledTableCell component="th" scope="row" style={{ color: currMrktUplColor }}>
                            <a className="th-a-link" onClick={() => _this.symbolLink(data.symbol)}>{data.symbol}</a>
                        </StyledTableCell>
                        <StyledTableCell align="left" style={{ color: currMrktUplColor }}>
                            {(data.side == null || data.side.length == 0) ? (side == null || side.length == 0 ? '' : side.toUpperCase()) : data.side.toUpperCase()}
                        </StyledTableCell>

                        <StyledTableCell align="right" style={{ color: currMrktUplColor }} >
                            {qty == null ? '-' : qty}
                        </StyledTableCell>
                        <StyledTableCell align="right" style={{ color: currMrktUplColor }} >
                            {filled_qty == null ? '-' : filled_qty}
                        </StyledTableCell>
                        <StyledTableCell align="right" style={{ color: currMrktUplColor }} >
                            {/* {data.current_price == null ? '-' : data.current_price} */}
                            <span >{round(data.current_price, 2)}</span>
                            <span style={{ paddingLeft: 5 }}>
                                {data.pre_close < data.current_price ?
                                    <img src={Uparrow} style={{ width: 10, height: 8, opacity: data.status < 0 ? 0.3 : 1 }} /> :
                                    <img src={Downarrow} style={{ width: 10, height: 8, opacity: data.status < 0 ? 0.3 : 1 }} />}
                            </span>
                        </StyledTableCell>

                        <StyledTableCell align="right" style={{ color: currMrktUplColor }}>
                            {round(limit_price, 2)}
                        </StyledTableCell>
                        <StyledTableCell align="right" style={{ color: currMrktUplColor }}>
                            {round(stop_price, 2)}
                        </StyledTableCell>

                        <StyledTableCell align="right" style={{ color: currMrktUplColor }}>
                            {round(filled_avg_price, 2)}
                        </StyledTableCell>
                        <StyledTableCell align="left" style={{ color: currMrktUplColor }}>
                            {data.status_msg}
                        </StyledTableCell>
                        <StyledTableCell align="left" style={{ color: currMrktUplColor }}>
                            {datetimeFromTimestampMdy(data.created_on)}
                        </StyledTableCell>

                    </StyledTableRow>
                )
            });

            return <>{posMap}</>;
        }

        if (propLoading) {
            return (
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: 500 }}>
                    <Loader />
                </div>
            )
        }
        return (
            <FadeInContainer delay={150} duration={750}>
                {/* <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }} colSpan={12}>
                    <div style={{ backgroundColor: 'transparent', display: 'flex', width: '50%', alignItems: 'flex-start', justifyContent: 'flex-start' }}>
                        {qstr && <Button onClick={() => this.symbolLink(this.props.qstr)}>
                            <span style={{ paddingTop: 7 }}><ArrowBackIcon /></span>
                            <span style={{ paddingLeft: 10, paddingRight: 10 }}>Back</span>
                        </Button>}
                    </div>
                    <div style={{ backgroundColor: 'transparent', display: 'flex', width: '50%', alignItems: 'flex-start', justifyContent: 'flex-start' }}>
                        <h3>Orders</h3>
                    </div>
                </div> */}
                <Container maxWidth="lg">

                    <Paper elevation={4} style={{ padding: 20, marginBottom: 30, boxShadow: "0 1px 5px 0 rgb(218 224 235 / 60%)" }}>
                        <Paper style={{ width: '100%', marginBottom: 20, boxShadow: "0 1px 5px 0 rgb(218 224 235 / 60%)", flexDirection: "row", display: "flex" }}>
                            {/* <div style={{ padding: 10 }}>
                                <FormControl variant="outlined" className="fantom-select">
                                    <InputLabel htmlFor="outlined-age-native-simple">Status</InputLabel>
                                    <Select
                                        native
                                        value={this.state.status}
                                        onChange={(evt) => this.handleChange(evt)}
                                        label="Status"
                                        inputProps={{
                                            name: 'status',
                                            id: 'outlined-age-native-simple',
                                        }}
                                    >
                                        <option value={'all'}>All</option>
                                        <option value={'open'}>Open</option>
                                        <option value={'closed'}>Closed</option>
                                    </Select>
                                </FormControl>
                            </div> */}
                            {/* <div style={{ padding: 10 }} className="search-input">
                                <TextField
                                    id="list-symbol-search"
                                    className="fantom-input"
                                    label="Search Symbol"
                                    type="search"
                                    variant="outlined"
                                    onChange={(evt) => this.handleOnTxtChange(evt)}
                                />
                            </div> */}
                        </Paper>
                        <TableContainer component={Paper} style={{ maxHeight: window.innerHeight - 250, width: '100%' }}>
                            {(orderInfo && Object.keys(orderInfo).length > 0) ?
                                // <Table stickyHeader aria-label="sticky table" >
                                //     <Headers />
                                //     <TableBody>
                                //         <PopulateTableCell />
                                //     </TableBody>

                                // </Table>
                                <ListCollapsibleTable {...this.props} isList={true} />
                                :
                                <Table stickyHeader aria-label="sticky table" >
                                    <Headers />
                                    <TableBody>
                                        <StyledTableRow align="center">
                                            <StyledTableCell component="th" scope="row"> </StyledTableCell>
                                            <StyledTableCell component="th" scope="row">  </StyledTableCell>
                                            <StyledTableCell component="th" scope="row">  </StyledTableCell>
                                            <StyledTableCell component="th" scope="row">  </StyledTableCell>
                                            <StyledTableCell component="th" scope="row">  </StyledTableCell>
                                            {/* <StyledTableCell component="th" scope="row">  No data found</StyledTableCell> */}
                                            <StyledTableCell component="th" scope="row"> {this.state.isLoaded ? ' No data found' : <Loader />}</StyledTableCell>
                                            <StyledTableCell component="th" scope="row">  </StyledTableCell>
                                            <StyledTableCell component="th" scope="row">  </StyledTableCell>
                                            <StyledTableCell component="th" scope="row">  </StyledTableCell>
                                            <StyledTableCell component="th" scope="row">  </StyledTableCell>
                                        </StyledTableRow>
                                    </TableBody>
                                </Table>
                            }
                        </TableContainer>
                    </Paper>
                </Container>
            </FadeInContainer>
        )

    }
}
const mapStateToProps = (state) => {

    const { blockChartData } = state.blockChartInfo ? state.blockChartInfo : {};
    const { orderData } = state.orderInfo ? state.orderInfo : {};
    let orderInfo = orderData.result ? orderData.result : {};
    return {
        orderInfo,
        blockChartData
    }
};
export default connect(mapStateToProps, { getOrderInfo, getBlockChartInfo, getSignalChartInfo })(withRouter(Orders));
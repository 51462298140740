
import { Container } from '@material-ui/core';
import { BrowserRouter } from 'react-router-dom';

// import TabViewDemo from './components/PrimeTab';
import { Provider } from 'react-redux';
import store from './store';
import HomeContainer from './container/HomeContainer';
// import CustomizedTabs from './components/CustomTab';

function App() {

  if (process.env.NODE_ENV === "production") {
    console.clear();
  }
  return (
    <Provider store={store}>
      <BrowserRouter>
        <Container maxWidth="xl">
          {/* <CustomizedTabs /> */}
          <HomeContainer />
        </Container>
      </BrowserRouter>

    </Provider>

  );

}

export default App;

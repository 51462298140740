import { SIGNAL_CHART_INFO_SUCCESS } from '../actions/types';

const initialState = {
    signalsInfo: {},
    loading: false,
};

export default function (state = initialState, action) {
    switch (action.type) {
        case SIGNAL_CHART_INFO_SUCCESS:
            return {
                ...state,
                signalsInfo: action.payload
            };


        default:
            return state;
    }
}
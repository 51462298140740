import React, {
    // useEffect 
} from "react";
// import { Line, Bubble } from "react-chartjs-2";
import {
    // formatDateMMDDhhmm, 
    formatDateYYYYmmdd,
    getDayDifference,
    getNewyorkTime,
    getNewyorkDate,
    getDateOnly
} from '../utility/Utility';
import {
    dateOffset,
    chartLineColorDanger,
    chartLineColorSuccess,
    chartAreaBackColorDanger,
    chartAreaBackColorSuccess,
    buyPointColor,
    sellPointColor,
    crstopColor,
    grstopColor
} from '../utility/Constants';
import Loader from '../commonComponents/Loader';
import ChartjsGraph from './ChartjsGraphs';


class CustomAreaChart extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            chartPrepared: false,
            chartData: {}
        }
    }
    componentDidMount() {

        const { blockInfo, getBlockChartInfo, getSignalChartInfo, listInfo, buyPrice, sellPrice } = this.props;

        // console.log(blockInfo)
        // console.log('blockInfo ____')

        // console.log(sellPrice)
        // console.log('sellPrice ____')

        let propBuyDate = this.props.buyDate,
            propSellDate = this.props.sellDate;

        let startDate = 0, endDate = 0, symbol = '';
        if (blockInfo && Object.keys(blockInfo).length > 0) {
            const { buy_result, sell_result } = blockInfo;
            var buyDate = new Date(buy_result.filled_at);
            buyDate.setDate(buyDate.getDate() - dateOffset);
            startDate = formatDateYYYYmmdd(buyDate);


            let dayDiff = getDayDifference(new Date(), new Date(buy_result.filled_at));
            let sellDate = new Date();
            endDate = formatDateYYYYmmdd(sellDate);
            if (sell_result && sell_result.filled_at !== null) {
                sellDate = new Date(sell_result.filled_at)
                sellDate.setDate(sellDate.getDate() + dateOffset);
                endDate = formatDateYYYYmmdd(sellDate);
            } else if (dayDiff < 10) {
                endDate = formatDateYYYYmmdd(new Date());
            } else {
                let bDate = new Date(buy_result.filled_at);
                bDate.setDate(bDate.getDate() + dateOffset);
                endDate = formatDateYYYYmmdd(bDate);
            }

            symbol = blockInfo.symbol;

        }
        if (listInfo && Object.keys(listInfo).length > 0) {
            let buyDate = new Date(listInfo.created_on);
            buyDate.setDate(buyDate.getDate() - dateOffset);
            startDate = formatDateYYYYmmdd(buyDate);


            let bDate = new Date(listInfo.created_on);
            bDate.setDate(bDate.getDate() + dateOffset);
            endDate = formatDateYYYYmmdd(bDate);
            symbol = listInfo.symbol;

        }
        let _this = this;
        getBlockChartInfo(symbol, startDate, endDate, function (resData) {

            // console.log(resData)
            // console.log('resData _____')
            if (Object.keys(resData).length > 0) {
                getSignalChartInfo(symbol, startDate, endDate, function (sigData) {
                    const chartData = _this.createChartData(resData, propBuyDate, propSellDate, buyPrice, sellPrice, sigData);
                    _this.setState({
                        chartData: chartData
                    })
                })

            }
        })

    }


    createChartData(blockChartData, buyDate, sellDate, buyPrice, sellPrice, sigData) {

        // console.log(sigData)
        // console.log('sigData ____ custom chart')
        const { isList } = this.props;

        const pointRadiusBuy = 6;
        const pointRadiusSell = 6;//6;
        let buyDateMatches = false;
        let sellDateMatches = false;

        let preparedChartData = {}
        if (blockChartData == null) {
            return preparedChartData;
        }
        let labels = [];
        let closeData = [];
        let openData = [];
        let highData = [];
        let lowData = [];
        let buyPriceData = [];
        let sellPriceData = [];

        let pointBorderWidthArr = [];
        let dates = [];
        let pointColorArr = [];


        blockChartData.map(function (cdata, j) {
            closeData.push(cdata.close);
        })
        let minClose = Math.min.apply(Math, closeData);


        // console.log(sellDate)
        // console.log('sellDate ____')

        blockChartData.map(function (cdata, i) {

            // console.log(getNewyorkTime(cdata.date) + ' === ' + getNewyorkTime(buyDate))
            if (getNewyorkTime(cdata.date) == getNewyorkTime(buyDate) || getNewyorkTime(cdata.date) == getNewyorkTime(sellDate)) {

                if (getNewyorkTime(cdata.date) == getNewyorkTime(buyDate)) {
                    buyDateMatches = true;
                    pointColorArr[i] = buyPointColor
                    let bprice = buyPrice;


                    // if (isList) {
                    //     bprice = minClose //May be changed
                    // }
                    buyPriceData[i] = {
                        x: getNewyorkTime(cdata.date),
                        y: bprice,
                        r: pointRadiusBuy
                    }


                }
                // console.log(getNewyorkTime(cdata.date) + ' === ' + getNewyorkTime(sellDate))
                if (getNewyorkTime(cdata.date) == getNewyorkTime(sellDate)) {

                    sellDateMatches = true;
                    pointColorArr[i] = sellPointColor;
                    sellPriceData[i] = {
                        x: getNewyorkTime(cdata.date),
                        y: sellPrice,
                        r: pointRadiusSell
                    };

                    // console.log(sellPrice)
                    // console.log('sellPrice _____')
                }
                // pointBorderWidthArr.push(pointRadius)
                pointBorderWidthArr[i] = pointRadiusBuy
            } else {
                pointBorderWidthArr[i] = 0;
                pointColorArr[i] = "#b51c0e";
                buyPriceData[i] = {
                    x: getNewyorkTime(cdata.date),
                    y: null,
                    r: 0
                }
                sellPriceData[i] = {
                    x: getNewyorkTime(cdata.date),
                    y: null,
                    r: 0
                };
            }
            labels.push(getNewyorkTime(cdata.date));
            dates.push(cdata.date);
            // closeData.push((Math.random()) * Math.round(Math.random() * 100));
            // openData.push((Math.random()) * Math.round(Math.random() * 100));
            // closeData.push(cdata.close);
            openData.push(cdata.open);
            highData.push(cdata.high);
            lowData.push(cdata.low);
        });

        //Calculating buy date and sell date(get within the time)
        let tobeCal = false;
        let calcBuyDate = buyDate;
        if (!buyDateMatches) {
            tobeCal = true;
            //resetting the array
            pointBorderWidthArr = [];
            pointColorArr = [];
            buyPriceData = [];
            sellPriceData = [];

            let tempBuyDate = dates.map(d => Math.abs(new Date(buyDate) - new Date(d).getTime()));
            let idx = tempBuyDate.indexOf(Math.min(...tempBuyDate));
            calcBuyDate = dates[idx];
        }


        let calcSellDate = sellDate;
        if (sellDate == 0) {
            calcSellDate = 0;
        } else if (!sellDateMatches) {
            tobeCal = true;
            //resetting the array
            pointBorderWidthArr = [];
            pointColorArr = [];
            buyPriceData = [];
            sellPriceData = [];

            let tempSellDate = dates.map(d => Math.abs(new Date(sellDate) - new Date(d).getTime()));
            let idxSell = tempSellDate.indexOf(Math.min(...tempSellDate));
            calcSellDate = dates[idxSell];
        }


        if (tobeCal) {
            dates.map(function (rawdates, i) {

                // console.log(getNewyorkTime(rawdates) + ' === ' + getNewyorkTime(calcBuyDate))
                // console.log(getNewyorkTime(rawdates) + ' === ' + getNewyorkTime(calcSellDate))
                if (getNewyorkTime(rawdates) == getNewyorkTime(calcBuyDate) || getNewyorkTime(rawdates) == getNewyorkTime(calcSellDate)) {

                    // console.log(getNewyorkTime(rawdates) + ' === ' + getNewyorkTime(calcBuyDate))

                    pointBorderWidthArr[i] = pointRadiusBuy;

                    if (getNewyorkTime(rawdates) == getNewyorkTime(calcBuyDate)) {
                        pointColorArr[i] = buyPointColor;

                        let bprice = buyPrice;
                        // if (isList) {
                        //     bprice = minClose //to be changed for 
                        // }
                        buyPriceData[i] = {
                            x: getNewyorkTime(rawdates),
                            y: bprice,
                            r: pointRadiusBuy
                        }

                        // console.log(buyPrice)
                        // console.log('buyPrice _____')
                        // console.log(bprice)
                        // console.log('bprice _____')
                    }
                    if (getNewyorkTime(rawdates) == getNewyorkTime(calcSellDate)) {
                        pointColorArr[i] = sellPointColor;
                        sellPriceData[i] = {
                            x: getNewyorkTime(rawdates),
                            y: sellPrice,
                            r: pointRadiusSell
                        };
                    }
                } else {

                    pointBorderWidthArr[i] = 0;
                    pointColorArr[i] = "#b51c0e";
                    buyPriceData[i] =
                    {
                        x: getNewyorkTime(rawdates),
                        y: null,
                        r: 0
                    }

                    sellPriceData[i] = {
                        x: getNewyorkTime(rawdates),
                        y: null,
                        r: 0
                    };
                }
            })
        }

        /*************** GRSTOP Data line **************/

        let signalDateData = [];
        if (sigData && Object.keys(sigData).length > 0) {
            sigData.map(function (sdata, i) {
                signalDateData.push(getDateOnly(sdata.date_obj))
            });
        }

        /*************** GRSTOP Data line **************/

        let blockChartDate = [];
        dates.map(function (cdata, j) {
            blockChartDate.push(getNewyorkDate(cdata));
        })
        // console.log(sigData)
        // console.log(Object.keys(sigData).length)
        // console.log('sigData ____')
        // console.log(signalDateData)
        // console.log(Object.keys(signalDateData).length)
        // console.log('signalDateData ____')

        let grstopArr = [];
        let crstopArr = [];
        let indexArr = [];
        let prevVal = NaN;
        let prevValCR = NaN;
        let triggerArr = [];

        for (var i = 0; i < blockChartDate.length; i++) { //199
            for (var j = 0; j < signalDateData.length; j++) { //5
                let hasEqualVal = false;

                // console.log(blockChartDate[i] + " === " + signalDateData[j])
                if (blockChartDate[i] == signalDateData[j]) {
                    hasEqualVal = true;

                    grstopArr[i] = sigData[j].grstop;
                    prevVal = sigData[j].grstop;

                    crstopArr[i] = sigData[j].cutoverstop;
                    prevValCR = sigData[j].cutoverstop;

                    indexArr.push(i);

                    // console.log(sigData[j].grstop)
                    // console.log("sigData[j].grstop _____ ")

                    // console.log(prevVal + " === " + i)
                    // console.log('prevVal if____')
                    triggerArr[i] = sigData[j].trigger;
                    break;

                } else {

                    hasEqualVal = false;
                    if (indexArr.indexOf(i) == -1) {

                        // console.log(indexArr)
                        // console.log(prevVal + " == " + i)
                        if (prevVal != sigData[j].grstop) {
                            grstopArr[i] = prevVal; //NaN;
                            crstopArr[i] = prevValCR; //NaN;
                            triggerArr[i] = NaN
                        }
                    }
                }

                if (hasEqualVal) {
                    continue;
                }
                // console.log('\n\n')
            }


        }
        let triggerData = [];
        if (triggerArr && Object.keys(triggerArr).length > 0) {
            triggerArr.map(function (sdata, i) {
                // triggerData.push(sdata.trigger);
                //Logic : hiegest value of close value pushed into trigger array in order to show the None, Suy, Sell(Trigger values)strings
                switch (sdata) {
                    case 'None':
                        triggerData.push(Math.max(...closeData));
                        break;
                    case 'Buy':
                        triggerData.push(Math.max(...closeData) - 1);
                        break;
                    case 'Sell':
                        triggerData.push(Math.max(...closeData) - 2);
                        break;
                    default:
                        triggerData.push(NaN);
                        break;
                }

            });
        }

        let areaBackgroundColor = chartAreaBackColorDanger;//"rgba(234,67,53, 0.2)";
        let borderColor = chartLineColorDanger;//"#b51c0e"; //"#EA4335"
        if (closeData[0] < closeData[closeData.length - 1]) {
            areaBackgroundColor = chartAreaBackColorSuccess;// "rgba(82, 173, 49, 0.2)";
            borderColor = chartLineColorSuccess;//"#397d20";//"#52ad31";
        }

        // console.log(sellPriceData)
        // console.log('sellPriceData ____')
        // console.log(buyPriceData)
        // console.log('buyPriceData ____')
        const chartData = {
            labels: labels,
            datasets: [


                // {
                //     label: 'CO STOP',
                //     data: crstopArr,
                //     backgroundColor: crstopColor,
                //     borderColor: crstopColor,
                //     pointBackgroundColor: crstopColor,
                //     borderWidth: 0,
                //     pointRadius: 0,
                //     fill: false,
                //     order: 8
                // },
                // {
                //     label: 'GR STOP',
                //     data: grstopArr,
                //     backgroundColor: grstopColor,
                //     borderColor: grstopColor,
                //     pointBackgroundColor: grstopColor,
                //     borderWidth: 0,
                //     pointRadius: 0,
                //     fill: false,
                //     order: 7
                // },
                {
                    label: 'Trigger',
                    data: triggerData,
                    backgroundColor: 'transparent',
                    borderColor: 'transparent',
                    pointBackgroundColor: 'transparent',
                    borderWidth: 0,
                    pointRadius: 0,
                    fill: false,
                    order: 8
                },
                {
                    type: 'line',
                    label: 'Close',
                    backgroundColor: areaBackgroundColor,//"rgba(151,187,205,0.5)",
                    data: closeData,
                    fill: true,
                    borderWidth: 0,
                    pointRadius: 0,
                },

                {
                    type: 'line',
                    label: 'Open',
                    data: openData,
                    fill: false,
                    borderWidth: 0,
                    pointRadius: 0,
                    borderColor: "transparent",
                    backgroundColor: "transparent",//"transparent",
                    pointBorderColor: "transparent",
                },
                {
                    label: 'High',
                    data: highData,
                    fill: false,
                    borderWidth: 0,
                    pointRadius: 0,
                    borderColor: "transparent",
                    backgroundColor: "transparent",//"transparent",
                    pointBorderColor: "transparent",

                },
                {
                    label: 'Low',
                    data: lowData,
                    fill: false,
                    borderWidth: 0,
                    pointRadius: 0,
                    borderColor: "transparent",
                    backgroundColor: "transparent",
                    pointBorderColor: "transparent",
                },
                {
                    type: 'bubble',
                    label: 'Buy Price',
                    backgroundColor: chartAreaBackColorSuccess,//"rgba(33, 138, 61,0.5)",
                    data: buyPriceData,
                    borderColor: 'white',
                    borderWidth: 1
                },
                {
                    type: 'bubble',
                    label: 'Sell Price',
                    backgroundColor: chartAreaBackColorDanger, //"rgba(33, 138, 61,0.5)",
                    data: sellPriceData,
                    borderColor: 'white',
                    borderWidth: 1
                },
            ],
            options: {
                legend: {
                    display: false,
                    position: "top",
                },
                scales: {
                    xAxes: [{
                        // type: 'time',
                        ticks: {
                            autoSkip: true,
                            maxTicksLimit: 10,
                            maxRotation: 0,
                            minRotation: 0,
                        },
                        gridLines: {
                            drawBorder: false,
                            zeroLineColor: "rgba(204, 204, 204, 0.1)",
                            color: "rgba(255,255,255, 0)",
                            display: true,
                        },
                    }],
                    yAxes: [{

                        gridLines: {
                            drawBorder: false,
                            color: "rgba(204, 204, 204, 0.1)",
                            zeroLineColor: "rgba(204, 204, 204, 0.05)",
                            display: true,
                        },
                        ticks: {
                            // suggestedMin: minClose,
                            // suggestedMax: maxClose,
                            beginAtZero: false
                        }
                    }],

                },
                responsive: true,
                maintainAspectRatio: false,
                hover: {
                    mode: 'index'
                },
                tooltips: {
                    // enabled: false,

                    xPadding: 10,
                    yPadding: 10,
                    position: 'nearest',
                    mode: 'index',
                    intersect: false,
                    callbacks: {
                        label: function (tooltipItems, data) {

                            var label = data.datasets[tooltipItems.datasetIndex].label || '';

                            let hideLabel = false;
                            // if (isList && label == 'Buy Price') {
                            //     hideLabel = true
                            // }
                            if (label) {
                                label += ': ';
                            }
                            // label += Math.round(tooltipItems.yLabel * 100) / 100;
                            if (label == 'Trigger: ') {
                                //Logic : Compared the the values pushed to trigger array to show on tooltip
                                if (tooltipItems.yLabel == Math.max(...closeData)) {
                                    label += 'None';
                                } else if (tooltipItems.yLabel == Math.max(...closeData) - 1) {
                                    label += 'Buy';
                                } else if (tooltipItems.yLabel == Math.max(...closeData) - 2) {
                                    label += 'Sell';
                                }
                            } else {
                                label += "$" + Math.round(tooltipItems.yLabel * 100) / 100;
                            }
                            if (hideLabel) {
                                label = ''
                            }
                            return label;
                        }
                    }
                },
                elements: {
                    line: {
                        tension: 0.000001
                    },
                    // point: {
                    //     pointStyle: 'circle'
                    // }
                },
            }
        };
        return chartData;
    }
    render() {

        return (
            <div className="content" style={{ justifyContent: 'center', alignItems: 'center', display: 'flex' }}>
                {Object.keys(this.state.chartData).length > 0 ? <ChartjsGraph {...this.props} chartData={this.state.chartData} /> : <Loader />}
            </div>
        )
    }
}
export default CustomAreaChart
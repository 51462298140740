

const baseUrl = "http://fantomvest.com:8080/";//"http://54.221.9.216:8080/";
const loginUrl = baseUrl + "fs/01/user/login";
const signupUrl = baseUrl + "fs/01/user/signup";
const accountInfoUrl = baseUrl + "fs/01/user/u/userID/alpacaaccount";//"fs/01/user/userID/alpacaaccount"
const positionsUrl = baseUrl + "fs/01/user/u/userID/positions";//"fs/01/user/userID/positions"
const updateCerdUrl = baseUrl + "fs/01/user/u/userID/alpacainfo";//"fs/01/user/userID/alpacainfo"
const ordersUrl = baseUrl + "fs/01/user/u/userID/orders";
const transactionUrl = baseUrl + "fs/01/user/u/userID/transactions";
// const blocksChartUrl = baseUrl + "fs/01/daily/ext/"; //OLD
const blocksChartUrl = baseUrl + "fs/01/daily/intraday/";

// const commonChartUrl = baseUrl + "fs/01/daily/get/";//OLD
const commonChartUrl = baseUrl + "fs/01/daily/price/";//NEW
const signalChartUrl = baseUrl + "fs/01/signals/";
const activeAssetsUrl = baseUrl + "fs/01/activeassets/";
const accountHistoryUrl = baseUrl + "fs/01/user/u/userID/alpacaaccountHistory";
const positionLogsUrl = baseUrl + "fs/01/user/u/userID/positionLogs";

const forceSellUrl = baseUrl + "fs/01/user/u/userID/forcesell/positionID";
const suppressUrl = baseUrl + "fs/01/user/u/userID/state/suppress/positionID";
const unsuppressUrl = baseUrl + "fs/01/user/u/userID/state/unsuppress/positionID";


// http://fantomvest.com:8080/fs/01/user/u/<UID>/forcesell/<POSITION_ID>
// http://fantomvest.com:8080/fs/01/user/u/<UID>/state/suppress<POSITION_ID>
// http://fantomvest.com:8080/fs/01/user/u/<UID>/state/unsuppress<POSITION_ID>


// http://fantomvest.com:8080/fs/01/daily/get/DRIV?start=2020-10-08&end=2021-04-06
// http://fantomvest.com:8080/fs/01/daily/price/AAPL?start=2021-03-01&end=2021-04-06


// http://fantomvest.com:8080/fs/01/daily/intraday/AAPL?start=2021-04-01&end=2021-04-06
// http://fantomvest.com:8080/fs/01/daily/ext/DRIV?start=2021-04-02&end=2021-04-10

//http://fantomvest.com:8080/fs/01/user/u/a0ba4e51-277b-465e-a402-8e9c81a62d76/alpacaaccountHistory
const dateOffset = 4;
const commonChartdateOffset = 365;//6 Months

const successColor = '#2bb547';
const successColorFaded = 'rgba(82, 173, 49, 0.1)';
const warningColor = '#d18411';
const dangerColor = '#e0410b';
const dangerColorFaded = 'rgba(234,67,53, 0.1)';
const defaultColor = '#44475b';//'#696666';
const txtColor = '#696666';
const greyOutColor = '#acacad'; //'#9b9b9e';//'#acacad';



const chartLineColorDanger = '#b51c0e';
const chartLineColorSuccess = '#397d20';
const chartAreaBackColorDanger = 'rgba(234,67,53, 1)';
const chartAreaBackColorBlue = 'rgba(5, 73, 181, 1)';
const chartAreaBackColorSuccess = 'rgba(82, 173, 49, 1)';
const buyPointColor = 'rgba(0, 200, 0, 0.07)';//'#397d20';
const sellPointColor = 'rgba(200, 0, 0, 0.07)';
const grstopColor = chartAreaBackColorBlue;//'#d10621';
const crstopColor = 'rgba(158, 11, 67, 1)';//'rgba(11, 165, 179, 1)';

module.exports = {
    loginUrl,
    signupUrl,
    accountInfoUrl,
    positionsUrl,
    updateCerdUrl,
    ordersUrl,
    transactionUrl,
    blocksChartUrl,
    commonChartUrl,
    signalChartUrl,
    activeAssetsUrl,
    accountHistoryUrl,
    positionLogsUrl,
    forceSellUrl,
    suppressUrl,
    unsuppressUrl,

    dateOffset,
    commonChartdateOffset,

    successColor,
    warningColor,
    dangerColor,
    txtColor,
    defaultColor,
    greyOutColor,

    chartLineColorDanger,
    chartLineColorSuccess,
    chartAreaBackColorDanger,
    chartAreaBackColorSuccess,
    buyPointColor,
    sellPointColor,
    chartAreaBackColorBlue,
    grstopColor,
    successColorFaded,
    dangerColorFaded,
    crstopColor,
}
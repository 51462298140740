// import qs from "qs";
import { LOGIN_SUCCESS, REG_SUCCESS } from './types'
import { loginUrl, signupUrl } from '../utility/Constants';
import { httpPostReq } from "../utility/httpRequests";

export const loginUser = (email, password, callback) => async (
    dispatch
) => {
    let url = loginUrl;
    const data = {
        username: email,//encodeURIComponent(email),
        password: password,
    };

    httpPostReq(
        url,
        // qs.stringify(data),
        data,
        function (response) {
            // console.log(response)
            if (response.data && response.data.status == 'success') {
                dispatch({
                    type: LOGIN_SUCCESS,
                    payload: response.data,
                });
            }
            callback(response.data);
        },
        false
    );
};
export const registerUserOld = (fname, lname, email, password, callback) => async (
    dispatch
) => {
    let url = signupUrl;
    const data = {
        fname: fname,
        lname: lname,
        username: email,
        email: email, //encodeURIComponent(email),
        password: password,
    };

    console.log(data)
    console.log('data ___')
    httpPostReq(
        url,
        // qs.stringify(data),
        data,
        function (response) {
            console.log(response)
            if (response.data && response.data.status == 'success') {
                dispatch({
                    type: REG_SUCCESS,
                    payload: response.data,
                });
            }
            callback(response);
        },
        false
    );
};
export const registerUser = (fname, lname, email, password, callback) => async (
    dispatch
) => {
    let url = signupUrl;
    // const data = {
    //     fname: fname,
    //     lname: lname,
    //     username: email,
    //     email: email, //encodeURIComponent(email),
    //     password: password,
    // };

    const data = {
        "fname": "Samrat",
        "lname": "Ganguly",
        "username": "samrat11@gmail.com",
        "email": "samrat11@gmail.com",
        "password": "samrat@123"
    }
    console.log(data)
    console.log('data ___')
    httpPostReq(
        url,
        // qs.stringify(data),
        data,
        function (response) {
            console.log(response)
            if (response.data && response.data.status == 'success') {
                dispatch({
                    type: REG_SUCCESS,
                    payload: response.data,
                });
            }
            callback(response);
        },
        false
    );
};
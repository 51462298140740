import { BLOCK_CHART_INFO_SUCCESS } from '../actions/types';

const initialState = {
    blockChartData: {},
    loading: false,
};

export default function (state = initialState, action) {
    switch (action.type) {
        case BLOCK_CHART_INFO_SUCCESS:
            return {
                ...state,
                blockChartData: action.payload
            };


        default:
            return state;
    }
}
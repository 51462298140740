import React from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';

import Box from "@material-ui/core/Box";
import { Link } from 'react-router-dom';

import IconButton from '@material-ui/core/IconButton';
import MoreVertIcon from '@material-ui/icons/MoreVert';

import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import { createCookie, getUserInfo } from '../utility/Utility';
import cookie from 'react-cookies';

import Logo from '../assets/img/logo.jpeg';

const AntTabs = withStyles({
  root: {
    borderBottom: '1px solid #e8e8e8',
  },
  indicator: {
    backgroundColor: '#1890ff',
  },
})(Tabs);

const AntTab = withStyles((theme) => ({
  root: {
    textTransform: 'none',
    minWidth: 72,
    fontWeight: theme.typography.fontWeightRegular,
    marginRight: theme.spacing(4),
    fontSize: '1rem',
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    '&:hover': {
      color: '#40a9ff',
      opacity: 1,
    },
    '&$selected': {
      color: '#1890ff',
      fontWeight: theme.typography.fontWeightMedium,
    },
    '&:focus': {
      color: '#40a9ff',
    },
  },
  selected: {},
}))((props) => {

  return (<Tab disableRipple {...props} />)
});



const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  padding: {
    padding: theme.spacing(3),
  },
  demo1: {
    backgroundColor: theme.palette.background.paper,
  },
  demo2: {
    backgroundColor: '#2e1534',
  },
  headerMenu: {
    // borderBottom: '1px solid #2e1534'
    boxShadow: "0 1px 4px 0 rgb(218 224 235 / 60%)",//"0 6px 6px -7px grey",

    paddingLeft: 30,
    paddingRight: 30

  },

  paper: {
    marginRight: theme.spacing(2),
  },
}));
let pathIndex = 0;

export default function CustomizedTabs(props) {

  const classes = useStyles();

  let pathName = props.location.pathname;
  let ind = pathName.includes('position') ? 0 : 1;
  if (pathName == '/') {
    ind = 0;
  }
  const [value, setValue] = React.useState(ind); //ind


  switch (pathName) {
    case '/position':
      if (pathIndex == 0) {
        setValue(ind);
        pathIndex = 1
      }
      break;
    case '/order/list':
      if (pathIndex == 1) {
        setValue(ind);
        pathIndex = 0
      }

      break;
    default:
      break;
  }


  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);

  const handleChange = (event, newValue) => {

    const { setLoader } = props;
    // setLoader(true);

    setValue(newValue);

    switch (newValue) {
      case 0:
        // props.history.push('/portfolio');
        props.history.push('/position');
        // setLoader(false);
        break;
      case 1:
        // props.history.push('/order/blocks');
        props.history.push('/order/list');
        // setLoader(false);
        break;
      default:
        // props.history.push('/overview')
        break;
    }
  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };
  const logOut = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    createCookie('login', false)
    setOpen(false);
    props.history.push('/login')
  };

  function handleListKeyDown(event) {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpen(false);
    }
  }

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = React.useRef(open);


  React.useEffect(() => {
    if (prevOpen.current === true && open === false && anchorRef && anchorRef.current) {
      anchorRef.current.focus();
    }

    prevOpen.current = open;
  }, [open]);

  let userData = getUserInfo();
  let fname = '', lname = '', fullname = ""


  if (userData && Object.keys(userData).length > 0) {

    fname = userData.fname ? userData.fname : '';
    lname = userData.lname ? userData.lname : '';
    fullname = fname + " " + lname;
  }

  const homeclicked = () => {
    setValue(0);
  }
  const overViewClicked = () => {
    setValue('');
    props.history.push('/overview');
  }
  const accountClicked = () => {
    setValue('');
    props.history.push('/account');
  }

  return (
    <div className={classes.root}>
      <div className={classes.demo1}>
        <Grid container spacing={1} className={classes.headerMenu}>
          <Grid container item xs={3} spacing={1}>
            <Link to={'/position'} style={{ textDecoration: 'none' }} onClick={() => homeclicked()}>
              <img src={Logo} style={{ width: 200, marginTop: 20 }} />
            </Link>
          </Grid>
          <Grid container item xs={7} spacing={1}>
            {/* {props.isLogin == "true" && <AntTabs value={value} onChange={handleChange} aria-label="ant example"> */}
            {props.isLogin == "true" && <AntTabs value={value} onChange={handleChange} aria-label="ant example">
              {/* <AntTab label="Portfolio" props={props} /> */}
              <AntTab label="Position" props={props} />
              <AntTab label="Orders" props={props} />
            </AntTabs>}

          </Grid>
          <Grid container item xs={2} spacing={1}>
            {props.isLogin == "true" && <div style={{ display: 'flex', flexDirection: 'row' }} >
              <span>
                <h4>{fullname ? fullname : '?'}</h4>
              </span>
              <span style={{ marginTop: 8 }}>
                <IconButton
                  aria-label="more"
                  aria-controls="long-menu"
                  aria-haspopup="true"
                  onClick={handleToggle}
                  ref={anchorRef}
                >
                  <MoreVertIcon />
                </IconButton>
              </span>

              <Popper open={open} anchorEl={anchorRef.current} role={undefined} transition disablePortal>
                {({ TransitionProps, placement }) => (
                  <Grow
                    {...TransitionProps}
                    style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
                  >
                    <Paper>
                      <ClickAwayListener onClickAway={handleClose}>
                        <MenuList autoFocusItem={open} id="menu-list-grow" onKeyDown={handleListKeyDown}>
                          <MenuItem onClick={overViewClicked}>Overview</MenuItem>
                          <MenuItem onClick={accountClicked}>Account</MenuItem>
                          <MenuItem onClick={logOut}>Logout</MenuItem>
                        </MenuList>
                      </ClickAwayListener>
                    </Paper>
                  </Grow>
                )}
              </Popper>
            </div>}
          </Grid>
        </Grid>

      </div>

    </div>
  );
}

import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Container from '@material-ui/core/Container';

import Paper from '@material-ui/core/Paper';
import { connect } from "react-redux";
import { withRouter } from 'react-router-dom';
import Tooltip from '@material-ui/core/Tooltip';



import { getPositionLogs } from '../actions/orderActions';
import { round, datetimeFromTimestampMdy } from '../utility/Utility';
import { defaultColor, greyOutColor } from '../utility/Constants';
import FadeInContainer from '../commonComponents/FadeInContainer';
import Loader from '../commonComponents/Loader';
import '../css/style.css';


const StyledTableCell = withStyles((theme) => ({
    head: {
        backgroundColor: "#666363",//theme.palette.common.black,
        color: theme.palette.common.white,
        // fontWeight: '600',
        fontSize: '0.8rem',
    },
    body: {
        fontSize: '0.8rem',
        fontWeight: '600'
    },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
    root: {
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.action.hover,
        },
    },
}))(TableRow);
const BlueOnGreenTooltip = withStyles({
    tooltip: {
        color: "white",//"lightblue",
        backgroundColor: "rgba(0, 0, 0, 0.6)", //"green",
        marginTop: -4
    }
})(Tooltip);

class Logs extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            page: 0,
            rowsPerPage: 5,
            status: 'open',
            searchVal: '',
            isLoaded: false
        }
    }
    abortOntroller = new AbortController();
    componentDidMount() {

        let _this = this;
        const { qstr } = this.props;

        if (qstr && qstr.length > 0) {
            this.setState({
                searchVal: qstr,
                status: 'all'
            });
            if (document.getElementById("list-symbol-search")) {
                document.getElementById("list-symbol-search").value = qstr;
            }

            this.props.getPositionLogs('all', qstr, function () {
                _this.setState({
                    isLoaded: true
                });
                _this.props.hideLoader();
            })
        } else {
            // this.props.getPositionLogs('open', '', function () { }) //Changed for all listing
            this.setState({
                status: 'all'
            });
            this.props.getPositionLogs('all', '', function () {
                _this.setState({
                    isLoaded: true
                });
                _this.props.hideLoader();
            })
        }
    }
    componentWillUnmount() {
        this.abortOntroller.abort();
    }
    handleChange(event) {
        let _this = this;
        const status = event.target.value;
        this.setState({
            status: status
        })
        this.props.getPositionLogs(status, this.state.searchVal, function () {
            _this.setState({
                isLoaded: true
            });
            _this.props.hideLoader();
        })
    }
    handleOnTxtChange(event) {
        let _this = this;
        const searchVal = event.target.value;

        if (searchVal && searchVal.length == 0) {
            this.setState({
                searchVal: searchVal,
                status: 'open'
            });
        } else {
            this.setState({
                searchVal: searchVal,
            });
        }

        this.props.getPositionLogs(this.state.status, searchVal, function () {
            _this.setState({
                isLoaded: true
            });
            _this.props.hideLoader();
        })
    }
    symbolLink = (symbolVal) => {

        //1 is tab index of blocks
        this.props.linkClicked(symbolVal, 1);
    }
    render() {

        const { logsDataInfo, qstr, propLoading } = this.props;
        const Headers = () => {
            return (
                <TableHead>
                    <TableRow>
                        <StyledTableCell>Symbol</StyledTableCell>
                        <StyledTableCell align="right">QTY #</StyledTableCell>
                        <StyledTableCell align="right">Current Price $</StyledTableCell>
                        <StyledTableCell align="left">Trigger</StyledTableCell>
                        <StyledTableCell align="left">Created On</StyledTableCell>
                        <StyledTableCell align="left">Message</StyledTableCell>

                    </TableRow>
                </TableHead>
            )
        }

        const PopulateTableData = () => {
            return (
                <Table aria-label="collapsible table">
                    <Headers />
                    <TableBody>
                        <PopulateTableCell />
                    </TableBody>
                </Table>
            )
        }
        const PopulateTableCell = () => {
            let _this = this;
            const posMap = logsDataInfo.map(function (data, i) {

                if (data && Object.keys(data).length == 0) {
                    return;
                }
                const { symbol, qty, current_price, created_on, message, signal } = data;
                let triggerVal = ''
                if (signal) {
                    triggerVal = signal.trigger;
                }

                const longText = <div style={{ padding: 10 }}>
                    <p className="tooltip-txt" style={{ fontWeight: 'bold' }}> {symbol}</p>
                    <p className="tooltip-txt">Date: {signal.date ? signal.date : ''}</p>
                    <p className="tooltip-txt">Close: ${signal.close ? signal.close : ''}</p>
                    <p className="tooltip-txt">ATR: ${signal.atr ? signal.atr : ''}</p>
                    <p className="tooltip-txt">CR Stop: ${signal.cutoverstop ? signal.cutoverstop : ''}</p>
                    <p className="tooltip-txt">Trigger: {signal.trigger ? signal.trigger : ''}</p>
                    <p className="tooltip-txt">Message: {message ? message : ''}</p>
                </div>;
                return (
                    <BlueOnGreenTooltip title={longText} disableFocusListener enterDelay={0} className="tbl-tooltip" key={i + '_toltip'} arrow placement="bottom">
                        <StyledTableRow key={i}>
                            <StyledTableCell component="th" scope="row" style={{ color: defaultColor }}>
                                <a className="th-a-link" onClick={() => _this.symbolLink(symbol)}>{symbol}</a>
                            </StyledTableCell>
                            <StyledTableCell align="right" style={{ color: defaultColor }} >
                                {qty == null ? '-' : qty}
                            </StyledTableCell>
                            <StyledTableCell align="right" style={{ color: defaultColor }}>
                                {round(current_price, 2)}
                            </StyledTableCell>
                            <StyledTableCell align="right" style={{ color: defaultColor }}>
                                {triggerVal ? triggerVal : '-'}
                            </StyledTableCell>
                            <StyledTableCell align="left" style={{ color: defaultColor }}>
                                {datetimeFromTimestampMdy(created_on)}
                            </StyledTableCell>
                            <StyledTableCell align="left" style={{ color: defaultColor }}>
                                {message ? message : '-'}
                            </StyledTableCell>

                        </StyledTableRow>
                    </BlueOnGreenTooltip>
                )
            });

            return <>{posMap}</>;
        }

        if (propLoading) {
            return (
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: 500 }}>
                    <Loader />
                </div>
            )
        }
        return (
            <FadeInContainer delay={150} duration={750}>

                <Container maxWidth="lg">

                    <Paper elevation={4} style={{ padding: 20, marginBottom: 30, boxShadow: "0 1px 5px 0 rgb(218 224 235 / 60%)" }}>
                        <Paper style={{ width: '100%', marginBottom: 20, boxShadow: "0 1px 5px 0 rgb(218 224 235 / 60%)", flexDirection: "row", display: "flex" }}>

                        </Paper>
                        <TableContainer component={Paper} style={{ maxHeight: window.innerHeight - 250, width: '100%' }}>
                            {(logsDataInfo && Object.keys(logsDataInfo).length > 0) ?

                                <PopulateTableData />
                                :
                                <Table stickyHeader aria-label="sticky table" >
                                    <Headers />
                                    <TableBody>
                                        <StyledTableRow align="center">
                                            <StyledTableCell component="th" scope="row">  </StyledTableCell>
                                            <StyledTableCell component="th" scope="row">  </StyledTableCell>
                                            <StyledTableCell component="th" scope="row">  </StyledTableCell>
                                            <StyledTableCell component="th" scope="row"> {this.state.isLoaded ? ' No data found' : <Loader />}</StyledTableCell>
                                            <StyledTableCell component="th" scope="row">  </StyledTableCell>
                                            <StyledTableCell component="th" scope="row">  </StyledTableCell>
                                        </StyledTableRow>
                                    </TableBody>
                                </Table>
                            }
                        </TableContainer>
                    </Paper>
                </Container>
            </FadeInContainer>
        )

    }
}
const mapStateToProps = (state) => {

    const { logsData } = state.logsInfo ? state.logsInfo : {};
    let logsDataInfo = logsData.result ? logsData.result : {};
    return {
        logsDataInfo,
    }
};
export default connect(mapStateToProps, { getPositionLogs })(withRouter(Logs));
import React from 'react';
import { connect } from "react-redux";
import { withRouter } from 'react-router-dom';
import cookie from 'react-cookies';

import Container from '@material-ui/core/Container';
import { getAplcaAccountInfo } from '../actions/accountActions';

import Grid from '@material-ui/core/Grid';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Paper from '@material-ui/core/Paper';
import { datetimeFromTimestampMdy, round } from '../utility/Utility';
import FadeInContainer from '../commonComponents/FadeInContainer';
import { successColor, dangerColor, txtColor } from '../utility/Constants'
import Uparrow from '../assets/img/Up_green_arrow.png';
import Downarrow from '../assets/img/down.png';

class AccountInfo extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            dense: true,
            secondary: false,
            checked: true
        }
    }
    componentDidMount() {
        if (cookie.load('login') == 'false' || cookie.load('login') == undefined) {
            this.props.history.push('login')
        }
        this.props.getAplcaAccountInfo(function () { })

    }
    render() {
        // console.log(this.props)
        // console.log('this.props ___')

        const { alpacaAccInfo } = this.props;
        if (alpacaAccInfo && Object.keys(alpacaAccInfo).length == 0) {
            return <div style={{ padding: 100 }}>No data available</div>
        }
        let dynamicTxt = txtColor;
        let valDiff = alpacaAccInfo.portfolio_value - alpacaAccInfo.last_equity
        if (valDiff > 0) {
            valDiff = '+' + valDiff;
            dynamicTxt = successColor;
        } else {
            dynamicTxt = dangerColor;
        }
        return (
            <React.Fragment>
                <Container maxWidth="lg">
                    <FadeInContainer delay={150} duration={750}>
                        <Paper elevation={3} style={{ margin: 20, padding: 20, boxShadow: "0 1px 5px 0 rgb(218 224 235 / 60%)" }}>
                            <Grid container spacing={2}>
                                <Grid item xs={6} md={6}>
                                    <div style={{ flexDirection: 'row', justifyContent: 'flex-start', display: 'flex' }}>
                                        <span style={{ fontSize: '0.95em', color: txtColor }}>Portfolio Value </span>
                                    </div>
                                    <div style={{ marginTop: 7, justifyContent: 'flex-start', display: 'flex' }}>
                                        <span style={{ fontSize: '1.8em', color: txtColor, paddingRight: 10 }}>{alpacaAccInfo.portfolio_value}</span>
                                        <span style={{ fontSize: '1.8em', color: txtColor }}>
                                            {alpacaAccInfo.last_equity < alpacaAccInfo.portfolio_value ?
                                                <img src={Uparrow} style={{ width: 15, height: 10 }} /> :
                                                <img src={Downarrow} style={{ width: 15, height: 10 }} />}
                                        </span>
                                        <span style={{ fontSize: '0.9em', color: dynamicTxt, padding: 10 }}>
                                            ({round(valDiff, 2)})
                                        </span>
                                    </div>
                                </Grid>
                                <Grid item xs={6} md={6}>
                                    <Grid container spacing={2}>

                                        <Grid item xs={3} md={3}>
                                            <div style={{ flexDirection: 'row', justifyContent: 'flex-start', display: 'flex' }}>
                                                <span style={{ fontSize: '0.95em', color: txtColor, paddingLeft: 3 }}>Long Market </span>
                                            </div>
                                            <div style={{ marginTop: 7, justifyContent: 'flex-start', display: 'flex' }}>
                                                <span style={{ fontSize: '1.8em', color: txtColor, paddingRight: 10 }}>{'(' + alpacaAccInfo.long_market_value}</span>
                                            </div>
                                        </Grid>
                                        <Grid item xs={2} md={2}>
                                            <div style={{ flexDirection: 'row', justifyContent: 'center', display: 'flex', height: 20 }}></div>
                                            <div style={{ flexDirection: 'column', justifyContent: 'flex-end', display: 'flex' }}>
                                                <span style={{ fontSize: '1.8em', color: txtColor, paddingRight: 10 }}>{'+'}</span>
                                            </div>
                                        </Grid>
                                        <Grid item xs={2} md={3}>
                                            <div style={{ flexDirection: 'row', justifyContent: 'center', display: 'flex' }}>
                                                <span style={{ fontSize: '0.95em', color: txtColor }}>Short Market </span>
                                            </div>
                                            <div style={{ marginTop: 7, justifyContent: 'center', display: 'flex' }}>
                                                <span style={{ fontSize: '1.8em', color: txtColor, paddingRight: 10 }}>{alpacaAccInfo.short_market_value}</span>
                                            </div>
                                        </Grid>
                                        <Grid item xs={2} md={2}>
                                            <div style={{ flexDirection: 'row', justifyContent: 'center', display: 'flex', height: 20 }}></div>
                                            <div style={{ flexDirection: 'column', justifyContent: 'center', display: 'flex' }}>
                                                <span style={{ fontSize: '1.8em', color: txtColor, paddingRight: 10 }}>{'+'}</span>
                                            </div>

                                        </Grid>
                                        <Grid item xs={2} md={2}>
                                            <div style={{ flexDirection: 'row', justifyContent: 'center', display: 'flex' }}>
                                                <span style={{ fontSize: '0.95em', color: txtColor, paddingRight: 10 }}>Cash </span>
                                            </div>
                                            <div style={{ marginTop: 7, justifyContent: 'center', display: 'flex', alignItems: 'center' }}>
                                                <span style={{ fontSize: '1.8em', color: txtColor, paddingRight: 3 }}>{alpacaAccInfo.cash}</span>
                                                <span style={{ fontSize: '1.8em', color: txtColor }}>{')'}</span>
                                            </div>

                                        </Grid>
                                        <Grid item xs={1} md={1}>

                                        </Grid>
                                    </Grid>


                                </Grid>

                            </Grid>
                        </Paper>
                        <Paper elevation={3} style={{ margin: 20, padding: 20, boxShadow: "0 1px 5px 0 rgb(218 224 235 / 60%)" }}>
                            <Grid container spacing={2}>
                                <Grid item xs={4} md={4}>
                                    <div className="demo" style={{ backgroundColor: 'white' }}>
                                        <List dense={this.state.dense}>
                                            <ListItem>
                                                <ListItemIcon>
                                                    Status :
                                                </ListItemIcon>

                                                <ListItemText
                                                    primary={alpacaAccInfo.status}
                                                    secondary={this.state.secondary ? 'Secondary text' : null}
                                                />
                                            </ListItem>
                                            <ListItem>
                                                <ListItemIcon>
                                                    Portfolio value :
                                                </ListItemIcon>
                                                <ListItemText
                                                    primary={alpacaAccInfo.portfolio_value}
                                                    secondary={this.state.secondary ? 'Secondary text' : null}
                                                />
                                            </ListItem>
                                            <ListItem>
                                                <ListItemIcon>
                                                    Account Number :
                                                </ListItemIcon>

                                                <ListItemText
                                                    primary={alpacaAccInfo.account_number}
                                                    secondary={this.state.secondary ? 'Secondary text' : null}
                                                />
                                            </ListItem>
                                            <ListItem>
                                                <ListItemIcon>
                                                    Currency :
                                                </ListItemIcon>

                                                <ListItemText
                                                    primary={alpacaAccInfo.currency}
                                                    secondary={this.state.secondary ? 'Secondary text' : null}
                                                />
                                            </ListItem>
                                            <ListItem>
                                                <ListItemIcon>
                                                    Cash :
                                                </ListItemIcon>

                                                <ListItemText
                                                    primary={alpacaAccInfo.cash}
                                                    secondary={this.state.secondary ? 'Secondary text' : null}
                                                />
                                            </ListItem>
                                            <ListItem>
                                                <ListItemIcon>
                                                    Cash withdrawable :
                                                </ListItemIcon>

                                                <ListItemText
                                                    primary={alpacaAccInfo.cash_withdrawable}
                                                    secondary={this.state.secondary ? 'Secondary text' : null}
                                                />
                                            </ListItem>
                                            <ListItem>
                                                <ListItemIcon>
                                                    Trading blocked :
                                                </ListItemIcon>

                                                <ListItemText
                                                    primary={alpacaAccInfo.trading_blocked ? "Yes" : "No"}
                                                    secondary={this.state.secondary ? 'Secondary text' : null}
                                                />
                                            </ListItem>
                                            <ListItem>
                                                <ListItemIcon>
                                                    Transfers blocked :
                                                </ListItemIcon>

                                                <ListItemText
                                                    primary={alpacaAccInfo.transfers_blocked ? "Yes" : "No"}
                                                    secondary={this.state.secondary ? 'Secondary text' : null}
                                                />
                                            </ListItem>
                                        </List>
                                    </div>
                                </Grid>
                                <Grid item xs={4} md={4}>
                                    <div className="demo" style={{ backgroundColor: 'white' }}>
                                        <List dense={this.state.dense}>


                                            <ListItem>
                                                <ListItemIcon>
                                                    Account blocked :
                                                </ListItemIcon>

                                                <ListItemText
                                                    primary={alpacaAccInfo.account_blocked ? "Yes" : "No"}
                                                    secondary={this.state.secondary ? 'Secondary text' : null}
                                                />
                                            </ListItem>
                                            <ListItem>
                                                <ListItemIcon>
                                                    Shorting enabled :
                                                </ListItemIcon>

                                                <ListItemText
                                                    primary={alpacaAccInfo.shorting_enabled ? "Yes" : "No"}
                                                    secondary={this.state.secondary ? 'Secondary text' : null}
                                                />
                                            </ListItem>
                                            <ListItem>
                                                <ListItemIcon>
                                                    Buying Power :
                                                </ListItemIcon>

                                                <ListItemText
                                                    primary={alpacaAccInfo.buying_power}
                                                    secondary={this.state.secondary ? 'Secondary text' : null}
                                                />
                                            </ListItem>
                                            <ListItem>
                                                <ListItemIcon>
                                                    Daytrade Count :
                                                </ListItemIcon>

                                                <ListItemText
                                                    primary={alpacaAccInfo.daytrade_count}
                                                    secondary={this.state.secondary ? 'Secondary text' : null}
                                                />
                                            </ListItem>
                                            <ListItem>
                                                <ListItemIcon>
                                                    Day trading buying power :
                                                </ListItemIcon>

                                                <ListItemText
                                                    primary={alpacaAccInfo.daytrading_buying_power}
                                                    secondary={this.state.secondary ? 'Secondary text' : null}
                                                />
                                            </ListItem>
                                            <ListItem>
                                                <ListItemIcon>
                                                    Regt buying power :
                                                </ListItemIcon>

                                                <ListItemText
                                                    primary={alpacaAccInfo.regt_buying_power}
                                                    secondary={this.state.secondary ? 'Secondary text' : null}
                                                />
                                            </ListItem>
                                            <ListItem>
                                                <ListItemIcon>
                                                    Equity :
                                                </ListItemIcon>

                                                <ListItemText
                                                    primary={alpacaAccInfo.equity}
                                                    secondary={this.state.secondary ? 'Secondary text' : null}
                                                />
                                            </ListItem>
                                            <ListItem>
                                                <ListItemIcon>
                                                    Last Equity :
                                                </ListItemIcon>

                                                <ListItemText
                                                    primary={alpacaAccInfo.last_equity}
                                                    secondary={this.state.secondary ? 'Secondary text' : null}
                                                />
                                            </ListItem>
                                        </List>
                                    </div>
                                </Grid>
                                <Grid item xs={4} md={4}>
                                    <div className="demo" style={{ backgroundColor: 'white' }}>
                                        <List dense={this.state.dense}>

                                            <ListItem>
                                                <ListItemIcon>
                                                    Multiplier :
                                                </ListItemIcon>

                                                <ListItemText
                                                    primary={alpacaAccInfo.multiplier}
                                                    secondary={this.state.secondary ? 'Secondary text' : null}
                                                />
                                            </ListItem>
                                            <ListItem>
                                                <ListItemIcon>
                                                    Initial margin :
                                                </ListItemIcon>

                                                <ListItemText
                                                    primary={alpacaAccInfo.initial_margin}
                                                    secondary={this.state.secondary ? 'Secondary text' : null}
                                                />
                                            </ListItem>
                                            <ListItem>
                                                <ListItemIcon>
                                                    Maintenance margin:
                                                </ListItemIcon>

                                                <ListItemText
                                                    primary={alpacaAccInfo.maintenance_margin}
                                                    secondary={this.state.secondary ? 'Secondary text' : null}
                                                />
                                            </ListItem>
                                            <ListItem>
                                                <ListItemIcon>
                                                    Last maintenance margin:
                                                </ListItemIcon>

                                                <ListItemText
                                                    primary={alpacaAccInfo.last_maintenance_margin}
                                                    secondary={this.state.secondary ? 'Secondary text' : null}
                                                />
                                            </ListItem>
                                            <ListItem>
                                                <ListItemIcon>
                                                    Long market value:
                                                </ListItemIcon>

                                                <ListItemText
                                                    primary={alpacaAccInfo.long_market_value}
                                                    secondary={this.state.secondary ? 'Secondary text' : null}
                                                />
                                            </ListItem>
                                            <ListItem>
                                                <ListItemIcon>
                                                    Short market value :
                                                </ListItemIcon>

                                                <ListItemText
                                                    primary={alpacaAccInfo.short_market_value}
                                                    secondary={this.state.secondary ? 'Secondary text' : null}
                                                />
                                            </ListItem>


                                            <ListItem>
                                                <ListItemIcon>
                                                    Created on :
                                                </ListItemIcon>
                                                <ListItemText
                                                    primary={datetimeFromTimestampMdy(alpacaAccInfo.created_at)}
                                                    secondary={this.state.secondary ? 'Secondary text' : null}
                                                />

                                            </ListItem>
                                            <ListItem>
                                                <ListItemIcon>
                                                    Updated on :
                                                </ListItemIcon>
                                                <ListItemText
                                                    primary={datetimeFromTimestampMdy(alpacaAccInfo.updated_at)}
                                                    secondary={this.state.secondary ? 'Secondary text' : null}
                                                />

                                            </ListItem>
                                        </List>
                                    </div>
                                </Grid>

                            </Grid>
                        </Paper>
                    </FadeInContainer>
                </Container>
            </React.Fragment>
        )
    }
}

const mapStateToProps = (state) => {
    // console.log(state)
    // console.log('state ___')
    const { accountData } = state.accountInfo ? state.accountInfo : {};
    let alpacaAccInfo = accountData.result ? accountData.result : {};
    return {
        alpacaAccInfo
    }
};
export default connect(mapStateToProps, { getAplcaAccountInfo })(withRouter(AccountInfo));
import axios from 'axios';
// import axiosRetry from 'axios-retry';
import { isJSON } from '../utility/Utility'

export const httpGetRequest = (url, callback, noofRetries) => {

    const xhr = new XMLHttpRequest()
    xhr.ontimeout = function () {
        console.error('The request for ' + url + ' timed out.')
    }
    xhr.onload = function () {
        if (xhr.readyState === 4) {
            if (xhr.status === 200) {
                const resData = {}

                try {
                    if (isJSON(xhr.response)) {
                        resData.data = JSON.parse(xhr.response)
                    } else {
                        resData.data = xhr.response
                    }
                } catch (err) {
                    console.log(err)
                }
                callback(resData)
            } else {
                console.error(xhr.statusText)
            }
        }
    }
    xhr.open('GET', url, true) // false is sync
    xhr.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded')
    xhr.send(null)
}
export const httpPutRequest = (url, dataObj, callback) => {

    // const xhr = new XMLHttpRequest()
    // xhr.ontimeout = function () {
    //     console.error('The request for ' + url + ' timed out.')
    // }
    // xhr.onload = function () {
    //     console.log(xhr.status)
    //     console.log('xhr.status ___')
    //     if (xhr.readyState === 4) {
    //         const resData = {}
    //         if (xhr.status === 200) {
    //             try {
    //                 if (isJSON(xhr.response)) {
    //                     resData.data = JSON.parse(xhr.response)
    //                 } else {
    //                     resData.data = xhr.response
    //                 }
    //             } catch (err) {
    //                 console.log(err)
    //                 console.log("err")
    //             }
    //             callback(resData)
    //         } else {
    //             console.error(xhr.response)

    //             if (isJSON(xhr.response)) {
    //                 resData.data = JSON.parse(xhr.response)
    //             } else {
    //                 resData.data = xhr.response
    //             }
    //             callback(resData)
    //         }
    //     }
    // }
    // xhr.open('PUT', url, true) // false is sync
    // // xhr.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded')
    // xhr.setRequestHeader('Content-type', 'application/json; charset=utf-8');
    // xhr.send(dataObj)

    // fetch(url, {
    //     method: 'PUT',
    //     body: JSON.stringify(dataObj),
    //     headers: { 'Content-Type': 'application/json' }
    // })
    //     .then(res => {
    //         callback(res.json())
    //     })
    //     .then(console.log)


    fetch(url, {
        method: 'PUT', // or 'PUT'
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(dataObj),
    })
        .then(response => response.json())
        .then(data => {
            console.log(data);
            console.log("data ___");
            callback(data)
        })
        .catch((error) => {
            console.error('Error:', error);
        });
}
export const httpPostReq = (url, dataObj, callback) => {

    const headers = {
        'Content-Type': 'application/x-www-form-urlencoded'
    }

    axios
        .post(url, dataObj, { headers })
        .then(function (response) {
            callback(response)
        })
        .catch(function (error) {
            console.log(error)
            callback('error', error)
        })
}
export const httpPostReqReg = (url, dataObj, callback) => {

    fetch(url, {
        method: 'POST', // or 'PUT'
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(dataObj),
    })
        .then(response => response.json())
        .then(data => {
            console.log(data);
            console.log("data ___");
            callback(data)
        })
        .catch((error) => {
            console.error('Error:', error);
        });
}

export const httpPostReq1 = (url, dataObj, callback) => {

    const xhr = new XMLHttpRequest()
    xhr.ontimeout = function () {
        console.error('The request for ' + url + ' timed out.')
    }

    xhr.onload = function () {
        console.log(xhr.readyState)
        console.log('xhr.readyState')
        if (xhr.readyState === 4) {
            console.log(xhr.status)
            console.log('xhr.status')
            if (xhr.status === 200) {
                const resData = {}

                try {
                    if (isJSON(xhr.response)) {
                        resData.data = JSON.parse(xhr.response)
                    } else {
                        resData.data = xhr.response
                    }
                } catch (err) {
                    console.log(err)
                }
                callback(resData)
            } else {
                // console.error(xhr.statusText)
                console.log(xhr)
                let error = {}
                error.status = "error"
                error.data = xhr.statusText
                callback(error)
            }
        }
    }
    xhr.open('POST', url, true) // false is sync
    xhr.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded')
    xhr.send(dataObj)
}
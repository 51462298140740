import { createStore, applyMiddleware, compose } from "redux";
import thunk from "redux-thunk";
import logger from "redux-logger";
import rootReducer from "./reducers";

const initialState = {};

const middleware = [thunk];

// const devTools =
//   process.env.NODE_ENV === "production"
//     ? applyMiddleware(...middleware)
//     : composeWithDevTools(applyMiddleware(...middleware));
const devTools = applyMiddleware(...middleware);
const store = createStore(rootReducer, initialState, devTools);

export default store;
import React, { Component } from "react";
import { withRouter } from 'react-router-dom';
import { connect } from "react-redux";
import cookie from 'react-cookies';

import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';
import { makeStyles, withStyles } from '@material-ui/core/styles';

import Orders from '../components/Orders';
import BlocksListing from '../components/BlocksListing';
import CommonChartView from '../components/CommonChartView';
import Loader from '../commonComponents/Loader';

import Logs from '../components/Logs';

import { getParameterByName } from '../utility/Utility';

import '../css/style.css';


const AntTabs = withStyles({
    root: {
        borderBottom: '1px solid #e8e8e8',
    },
    indicator: {
        backgroundColor: '#1890ff',
    },
})(Tabs);

const AntTab = withStyles((theme) => ({
    root: {
        textTransform: 'none',
        minWidth: 72,
        fontWeight: theme.typography.fontWeightRegular,
        marginRight: theme.spacing(4),
        fontFamily: [
            '-apple-system',
            'BlinkMacSystemFont',
            '"Segoe UI"',
            'Roboto',
            '"Helvetica Neue"',
            'Arial',
            'sans-serif',
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"',
        ].join(','),
        '&:hover': {
            color: '#40a9ff',
            opacity: 1,
        },
        '&$selected': {
            color: '#1890ff',
            fontWeight: theme.typography.fontWeightMedium,
        },
        '&:focus': {
            color: '#40a9ff',
        },
    },
    selected: {},
}))((props) => <Tab disableRipple {...props} />);

class OrdersContainer extends Component {

    constructor(props) {
        super(props);
        this.state = {
            value: 0,
            qstr: '',
            showLoader: false
        };
        this.hideLoader = this.hideLoader.bind(this);
    }
    abortOntroller = new AbortController();
    componentDidMount() {

        // console.log('componentDidMount _ order conatiner___')
        if (cookie.load('login') == 'false' || cookie.load('login') == undefined) {
            this.props.history.push('/login')
        }
        let url = window.location.pathname;
        if (url.includes('/list')) {
            this.setState({
                value: 0
            })
        } else if (url.includes('/blocks')) {
            this.setState({
                value: 1
            })
        } else {
            this.setState({
                value: 2
            })
        }
    }
    componentWillUnmount() {
        // console.log('componentWillUnmount _ order conatiner___')
        this.abortOntroller.abort();
    }
    handleChange = (evt, newValue) => {

        let qstr = getParameterByName('symbol');
        this.setState({
            value: newValue,
            showLoader: true
        })

        // let tagVal = newValue == 0 ? 'blocks' : 'list'; //OLD

        // let tagVal = newValue == 0 ? 'list' : 'blocks';
        // if (qstr) {
        //     this.props.history.push(`/order/${tagVal}?symbol=${qstr}`);
        // } else {
        //     this.props.history.push(`/order/${tagVal}`)
        // }

        let tagVal = 'list';
        // this.props.history.push(`/order/${tag}?symbol=${symbol}`)

        switch (newValue) {
            case 0:
                tagVal = 'list';
                break;
            case 1:
                tagVal = 'blocks';
                break;
            case 2:
                tagVal = 'logs';
                break;
            default:
                break;
        }

        if (qstr) {
            this.props.history.push(`/order/${tagVal}?symbol=${qstr}`);
        } else {
            this.props.history.push(`/order/${tagVal}`)
        }

    };

    hideLoader() {
        // console.log(this.state.showLoader)
        // console.log('hide loader......')
        if (this.state.showLoader) {
            this.setState({
                showLoader: false
            });

            // this.props.setLoader(false);
        }
    }

    linkClicked(symbol, index) {

        this.setState({
            value: index
        })

        // let tag = (index == 0) ? 'blocks' : 'list';
        let tag = 'list';
        // this.props.history.push(`/order/${tag}?symbol=${symbol}`)

        switch (index) {
            case 0:
                tag = 'list';
                break;
            case 1:
                tag = 'blocks';
                break;
            case 2:
                tag = 'logs';
                break;
            default:
                break;
        }
        this.props.history.push(`/order/${tag}?symbol=${symbol}`)
    }

    componentWillUnmount() {

    }
    render() {


        // console.log(this.props)
        // console.log('this.props order conatiner ____')

        let qstr = getParameterByName('symbol');
        const TabPanel = (props) => {
            const { children, value, index, ...other } = props;

            return (
                <div
                    role="tabpanel"
                    hidden={this.state.value !== index}
                    id={`nav-tabpanel-${index}`}
                    aria-labelledby={`nav-tab-${index}`}

                    {...other}
                >
                    {value === index && (
                        <Box p={3}>
                            {children}
                        </Box>
                    )}
                </div>
            );
        }

        // console.log(this.state.showLoader)
        // console.log('showLoader ____')
        return (
            <div style={{ paddingTop: 20, minWidth: window.innerWidth * 0.6 }}>
                <div className="sub-tab" style={{ alignItems: 'center', justifyContent: 'center' }} >
                    {qstr && <CommonChartView symbol={qstr} />}
                    <div style={{ width: '100%', marginTop: 40 }}>
                        <AntTabs value={this.state.value} qstr={qstr} onChange={this.handleChange} aria-label="ant">
                            <AntTab label="List" />
                            <AntTab label="Blocks" />
                            <AntTab label="Logs" />
                        </AntTabs>
                    </div>

                    <TabPanel value={this.state.value} index={0}>
                        <Orders {...this.props} qstr={qstr} linkClicked={(newValue, ind) => this.linkClicked(newValue, ind)} propLoading={this.state.showLoader} hideLoader={() => this.hideLoader()} />
                    </TabPanel>
                    <TabPanel value={this.state.value} index={1}>
                        <BlocksListing {...this.props} qstr={qstr} linkClicked={(newValue, ind) => this.linkClicked(newValue, ind)} propLoading={this.state.showLoader} hideLoader={() => this.hideLoader()} />
                    </TabPanel>
                    <TabPanel value={this.state.value} index={2}>
                        <Logs {...this.props} qstr={qstr} linkClicked={(newValue, ind) => this.linkClicked(newValue, ind)} propLoading={this.state.showLoader} hideLoader={() => this.hideLoader()} />
                    </TabPanel>

                </div>
            </div>
        );
    }
}
const mapStateToProps = (state) => {
    return {}
};
export default connect(mapStateToProps, {})(withRouter(OrdersContainer));

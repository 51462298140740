import React, { Component } from "react";
import { Switch, Route, withRouter } from 'react-router-dom';
import cookie from 'react-cookies';
import { connect } from "react-redux";
// import { makeStyles } from '@material-ui/core/styles';   

import Routing from '../commonComponents/Routing';
// import Login from './Login';
import CustomizedTabs from '../components/CustomTab';
import Loader from '../commonComponents/Loader';


class HomeContainer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            page: 0,
            rowsPerPage: 5,
            showLoader: false
        }
    }
    componentDidMount() {


        if (cookie.load('login') == 'false' || cookie.load('login') == undefined) {
            this.props.history.push('login')
        }

        // let url = window.location.pathname;

        // if (url.includes('/order')) {
        //     console.log('home 1')
        //     this.props.history.push('/order/blocks')
        // } else {
        //     console.log('home 2')
        //     this.props.history.push('/portfolio')
        // }
    }
    setLoader(flg) {
        console.log(flg + " setLoader..")
        this.setState({
            showLoader: flg
        })
    }
    render() {
        return (

            <div className={'root'}>
                <div className="main-container">

                    <CustomizedTabs isLogin={cookie.load('login')} {...this.props} setLoader={(tag) => this.setLoader(tag)} />

                    {/* <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', textAlign: 'center' }}>
                        <Switch>
                            <Routing {...this.props} setLoader={(tag) => this.setLoader(tag)} />
                        </Switch>
                    </div> */}
                    {!this.state.showLoader ? <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', textAlign: 'center' }}>
                        <Switch>
                            <Routing {...this.props} setLoader={(tag) => this.setLoader(tag)} />
                        </Switch>
                    </div> :
                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: 500 }}>
                            <Loader />
                        </div>}

                </div>

            </div>

        );
    }
}

const mapStateToProps = (state) => {
    return {}
};
export default connect(mapStateToProps, {})(withRouter(HomeContainer));
import React from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import { getUserInfo, datetimeFromTimestampMdy } from '../utility/Utility';
import { connect } from "react-redux";
import { withRouter } from 'react-router-dom';
import cookie from 'react-cookies';
// import { AppRegistry, StyleSheet, Text, View } from 'react-native';

import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import Checkbox from '@material-ui/core/Checkbox';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import Fab from '@material-ui/core/Fab';
import EditIcon from '@material-ui/icons/Edit';

// import CustomModal from '../commonComponents/CustomModal';\
import "react-responsive-modal/styles.css";
import { Modal } from "react-responsive-modal";
import TextField from '@material-ui/core/TextField';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import { updateCedentials } from '../actions/accountActions';
import { dangerColor } from '../utility/Constants';
import FadeInContainer from '../commonComponents/FadeInContainer';


class OverView extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            dense: true,
            secondary: false,
            checked: true,
            open: false,
            apiid: '',
            apiSecret: '',
            statusMsg: ''

        }
        this.onKeyUp = this.onKeyUp.bind(this);
    }
    onKeyUp(event) {
        if (event.charCode === 13) {
            this.onSubmit()
        }
    }
    componentDidMount() {
        // this.props.getAplcaAccountInfo(function () { })
        if (cookie.load('login') == 'false' || cookie.load('login') == undefined) {
            this.props.history.push('login')
        }
    }

    onOpenModal = () => {
        this.setState({ open: true });
    };

    onCloseModal = () => {
        this.setState({ open: false });
    };
    handleChange = e => {
        // console.log(e.currentTarget.id)
        // console.log('e.currentTarget.id ___')
        // console.log(this.props)
        // console.log('this.props __')

        let userInfoD = getUserInfo();
        let alpacaInfo = userInfoD.alpaca_info;
        this.setState({
            apiid: alpacaInfo.api_id,
            statusMsg: ""
        });
        if (e.currentTarget.id == 'standard-apiid-input') {
            this.setState({
                apiid: e.currentTarget.value,
                statusMsg: ""
            });
        } else {
            this.setState({
                apiSecret: e.currentTarget.value,
                statusMsg: ""
            });
        }
    }
    onSubmit() {
        const { apiid, apiSecret } = this.state;
        if (apiid.length > 0 && apiSecret.length > 0) {

            let _this = this;
            const { updateCedentials } = this.props;
            updateCedentials(apiid, apiSecret, function (res) {
                // console.log(res)
                // console.log('up res ___')
                if (res && res.status == "success") {
                    _this.setState({ statusMsg: res ? res.message : "" });

                } else {
                    _this.setState({ statusMsg: res ? res.message : "Something went wrong, please try again." });
                }
            });
        } else {
            this.setState({ statusMsg: "All fields are required" });
        }
    }
    render() {
        const { open } = this.state;
        let userInfoD = getUserInfo();

        let alpacaInfo = userInfoD.alpaca_info;
        return (
            <React.Fragment>

                <FadeInContainer delay={150} duration={750}>
                    <Container maxWidth="lg">
                        <Paper elevation={4} style={{ margin: 20, padding: 20, boxShadow: "0 1px 5px 0 rgb(218 224 235 / 60%)" }} root='.MuiPaper-root'>
                            <Grid container >
                                <Grid item xs={12} md={12} lg={12}>
                                    <Typography variant="h6" style={{ textAlign: 'left' }} >
                                        Overview
                                    </Typography>
                                    <div >
                                        <List dense={true}>
                                            <ListItem>
                                                <ListItemIcon>
                                                    Name :
                                            </ListItemIcon>
                                                <ListItemText
                                                    className='list-item-txt'
                                                    primary={userInfoD.fname + ' ' + userInfoD.lname}
                                                    secondary={this.state.secondary ? 'Secondary text' : null}
                                                />
                                            </ListItem>
                                            <ListItem>
                                                <ListItemIcon>
                                                    Email :
                                            </ListItemIcon>

                                                <ListItemText
                                                    primary={userInfoD.email}
                                                    secondary={this.state.secondary ? 'Secondary text' : null}
                                                />

                                            </ListItem>
                                            <ListItem>
                                                <ListItemIcon>
                                                    Username :
                                            </ListItemIcon>

                                                <ListItemText
                                                    style={{ paddingLeft: 5 }}
                                                    primary={userInfoD.username}
                                                    secondary={this.state.secondary ? 'Secondary text' : null}
                                                />

                                            </ListItem>
                                            <ListItem>
                                                <ListItemIcon>
                                                    Created on :
                                            </ListItemIcon>
                                                <ListItemText
                                                    style={{ paddingLeft: 5 }}
                                                    primary={datetimeFromTimestampMdy(userInfoD.created_on)}
                                                    secondary={this.state.secondary ? 'Secondary text' : null}
                                                />

                                            </ListItem>
                                            <ListItem>
                                                <ListItemIcon>
                                                    Modified on :
                                            </ListItemIcon>
                                                <ListItemText
                                                    style={{ paddingLeft: 5 }}
                                                    primary={datetimeFromTimestampMdy(userInfoD.last_modified_on)}
                                                    secondary={this.state.secondary ? 'Secondary text' : null}
                                                />

                                            </ListItem>

                                        </List>
                                    </div>
                                    <Typography variant="h6" style={{ textAlign: 'left' }}>
                                        Alpaca Info
                                    </Typography>
                                    <div align="right" style={{ marginTop: -45 }}>
                                        {/* <Button variant="contained" color="primary" onClick={this.onOpenModal}>
                                        Edit
                                    </Button> */}
                                        <Fab color="primary" aria-label="edit" onClick={this.onOpenModal}>
                                            <EditIcon />
                                        </Fab>
                                    </div>


                                    <div >
                                        <List dense={true}>
                                            <ListItem>
                                                <ListItemIcon>
                                                    API ID :
                                    </ListItemIcon>
                                                <ListItemText
                                                    primary={alpacaInfo.api_id}
                                                    secondary={this.state.secondary ? 'Secondary text' : null}
                                                />
                                            </ListItem>
                                            <ListItem>
                                                <ListItemIcon>
                                                    API SECRET KEY :
                                            </ListItemIcon>

                                                <ListItemText
                                                    style={{ paddingLeft: 5 }}
                                                    primary={"XXXXXXXXXXX"}
                                                    secondary={this.state.secondary ? 'Secondary text' : null}
                                                />

                                            </ListItem>
                                            <ListItem>
                                                <ListItemIcon>
                                                    Is paper Account?
                                            </ListItemIcon>

                                                <Checkbox
                                                    style={{ marginLeft: 25 }}
                                                    edge="start"
                                                    checked={this.state.checked !== -1}
                                                    tabIndex={-1}
                                                    disableRipple
                                                // inputProps={{ 'aria-labelledby': labelId }}
                                                />

                                            </ListItem>

                                        </List>
                                    </div>
                                </Grid>
                            </Grid>
                        </Paper>
                    </Container>

                    <div style={{ flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                        {/* <button onClick={this.onOpenModal}>Open modal</button> */}
                        <Modal open={open} onClose={this.onCloseModal}>
                            <Card style={{ display: 'flex', flexWrap: 'wrap', flexDirection: 'row', justifyContent: 'center', marginTop: 20, boxShadow: "0 8px 20px 0 rgb(218 224 235 / 60%)" }}>
                                <CardContent>
                                    <div className="login-container" style={{ width: 500, display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
                                        {/* <h4>Edit</h4> */}


                                        <form className="form" >
                                            {this.state.statusMsg.length > 0 && <p style={{ color: dangerColor }}><label>{this.state.statusMsg}</label></p>}
                                            <div>
                                                <TextField
                                                    id="standard-apiid-input"
                                                    label="API ID"
                                                    type="text"
                                                    value={alpacaInfo.api_id}
                                                    onChange={(e) => this.handleChange(e)}
                                                    onKeyPress={this.onKeyUp}
                                                    style={{ width: 200 }}
                                                />
                                            </div>

                                            <div style={{ paddingBottom: 20 }}>
                                                <TextField
                                                    id="standard-secret-input"
                                                    label="API SECRET KEY"
                                                    type="text"
                                                    autoComplete="current-password"
                                                    onChange={(e) => this.handleChange(e)}
                                                    style={{ width: 200 }}
                                                    onKeyPress={this.onKeyUp}
                                                />
                                            </div>
                                            <div>
                                                <span style={{ paddingRight: 50 }}>Is paper Account </span>
                                                <Checkbox
                                                    id="standard-paperacc-input"
                                                    edge="start"
                                                    checked={true}
                                                    // tabIndex={-1}
                                                    disabled={false}
                                                // disableRipple
                                                />
                                            </div>
                                            <div style={{ textAlign: 'center', paddingTop: 20 }}>
                                                <Button variant="contained" color="primary" onClick={() => this.onSubmit()}>Update </Button>
                                            </div>
                                        </form>

                                    </div>
                                </CardContent>
                            </Card>
                        </Modal>
                    </div>
                </FadeInContainer>
            </React.Fragment>
        )
    }
}

const mapStateToProps = (state) => {
    // console.log(state)
    // console.log('state ___')
    // const { accountData } = state.accountInfo ? state.accountInfo : {};
    // let alpacaAccInfo = accountData.result ? accountData.result : {};
    return {}
};
export default connect(mapStateToProps, { updateCedentials })(withRouter(OverView));


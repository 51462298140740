import React from 'react';
// import PropTypes from 'prop-types';
import { withStyles, makeStyles } from '@material-ui/core/styles';
// import Box from '@material-ui/core/Box';
import Collapse from '@material-ui/core/Collapse';
import IconButton from '@material-ui/core/IconButton';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
// import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
// import { connect } from "react-redux";
// import { withRouter } from 'react-router-dom';
// import { getBlockListingInfo, getBlockChartInfo } from '../actions/orderActions';

import { round, datetimeFromTimestampMdy, getNewyorkTime } from '../utility/Utility';
import { successColor, dangerColor, warningColor, defaultColor, dateOffset } from '../utility/Constants';
// import Uparrow from '../assets/img/Up_green_arrow.png';
// import Downarrow from '../assets/img/down.png';
// import AreaChart from '../commonComponents/AreaChart';
import CustomAreaChart from '../commonComponents/CustomAreaChart';

const useRowStyles = makeStyles({
    root: {
        '& > *': {
            borderBottom: 'unset',
        },
    },
});

const StyledTableCell = withStyles((theme) => ({
    head: {
        backgroundColor: "#666363",//theme.palette.common.black,
        color: theme.palette.common.white,
        // fontWeight: '600',
        fontSize: '0.8rem',
    },
    body: {
        fontSize: '0.8rem',
        fontWeight: '600'
    },
}))(TableCell);

function Row(props) {
    const { row, blockChartData, blockInfo } = props;
    const [open, setOpen] = React.useState(false);
    const classes = useRowStyles();
    // const blockChartData = {};

    // console.log(props)
    // console.log('props ___')

    if (!row) {
        return <div />
    }
    const { symbol, buy_result, sell_result, qty, avg_buy_price, avg_sell_price, total_profit, percent_profit, status, current_price, pre_close } = row;

    // console.log(buy_result)
    // console.log('buy_result ____')
    return (
        <React.Fragment>
            <TableRow className={classes.root}>


                <StyledTableCell component="th" scope="row">
                    <a className="th-a-link" onClick={() => props.linkClicked(symbol, 0)}>{symbol}</a>
                </StyledTableCell>
                <StyledTableCell align="left" style={{ color: defaultColor }}>
                    {buy_result ? getNewyorkTime(buy_result.filled_at) : '-'}
                </StyledTableCell>

                <StyledTableCell align="left" style={{ color: defaultColor }} >
                    {sell_result ? getNewyorkTime(sell_result.filled_at) : '-'}
                </StyledTableCell>

                <StyledTableCell align="right" style={{ color: defaultColor }}>
                    {qty}
                </StyledTableCell>

                {/* <StyledTableCell align="right" style={{ color: defaultColor }}>
                    <span >{roundToTwo(current_price)}</span>
                    <span style={{ paddingLeft: 5 }}>
                        {pre_close < current_price ?
                            <img src={Uparrow} style={{ width: 10, height: 8 }} /> :
                            <img src={Downarrow} style={{ width: 10, height: 8 }} />}
                    </span>
                </StyledTableCell> */}
                <StyledTableCell align="right" style={{ color: defaultColor }}>
                    {avg_buy_price}
                </StyledTableCell>
                <StyledTableCell align="right" style={{ color: defaultColor }}>
                    {status >= 100 ? avg_sell_price : '-'}
                </StyledTableCell>
                <StyledTableCell align="right" style={{ color: total_profit >= 0 ? (total_profit == 0 ? defaultColor : successColor) : dangerColor }}>
                    {status >= 100 ? round(total_profit, 2) : '-'}
                </StyledTableCell>
                <StyledTableCell align="right" style={{ color: percent_profit >= 0 ? (percent_profit == 0 ? defaultColor : successColor) : dangerColor }}>
                    {status >= 100 ? round(percent_profit, 2) : '-'}
                </StyledTableCell>
                <StyledTableCell>
                    <IconButton aria-label="expand row" size="small" onClick={() => {
                        setOpen(!open);
                    }}>
                        {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                    </IconButton>
                </StyledTableCell>
            </TableRow>
            <TableRow>
                <StyledTableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={12}>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <div style={{ flex: 1, height: 290, width: '100%', alignItems: 'center' }}>
                            {Object.keys(row).length > 0 && <CustomAreaChart
                                getBlockChartInfo={props.getBlockChartInfo}
                                getSignalChartInfo={props.getSignalChartInfo}
                                blockInfo={row}
                                buyDate={buy_result ? buy_result.filled_at : 0}
                                sellDate={sell_result ? sell_result.filled_at : 0}
                                buyPrice={avg_buy_price ? avg_buy_price : 0}
                                sellPrice={avg_sell_price ? avg_sell_price : 0}
                                containerId={'div_' + symbol + '_' + row.id + '_chart'} />}

                        </div>
                    </Collapse>
                </StyledTableCell>
            </TableRow>
        </React.Fragment >
    );
}

function CollapsibleTable(props) {

    const { blockInfo, blockChartData } = props;
    return (
        <TableContainer component={Paper}>
            <Table aria-label="collapsible table">
                <TableHead>
                    <TableRow>
                        <StyledTableCell>Symbol</StyledTableCell>
                        <StyledTableCell align="left">Date of Buy</StyledTableCell>
                        <StyledTableCell align="left">Date of Sell</StyledTableCell>
                        <StyledTableCell align="right">QTY #</StyledTableCell>
                        {/* <StyledTableCell align="left">Current Price $</StyledTableCell> */}
                        <StyledTableCell align="right">Buy Price $</StyledTableCell>
                        <StyledTableCell align="right">Sell Price $</StyledTableCell>
                        <StyledTableCell align="right">Total Gain $</StyledTableCell>
                        <StyledTableCell align="right">Total Gain %</StyledTableCell>
                        <StyledTableCell></StyledTableCell>

                    </TableRow>
                </TableHead>
                <TableBody>
                    {blockInfo.map((data) => (
                        <Row key={data.id} row={data} {...props} />
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
}


export default CollapsibleTable
// const mapStateToProps = (state) => {

//     const { blockChartData } = state.blockChartInfo ? state.blockChartInfo : {};
//     // const { blockListData } = state.blockInfo ? state.blockInfo : {};
//     // let blockInfo = blockListData.result ? blockListData.result : {};
//     return {
//         // blockInfo,
//         blockChartData
//     }
// };
// export default connect(mapStateToProps, { getBlockChartInfo })(CollapsibleTable);
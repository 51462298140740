
import React, { useEffect } from "react";
import { chartAreaBackColorDanger, chartAreaBackColorSuccess } from '../utility/Constants';
import '../css/style.css';
// import Chart from "react-chartjs-2";
// var Chart = require('chart.js');
import { Chart } from 'chart.js';
import annotationPlugin from 'chartjs-plugin-annotation';


class ChartjsGraph extends React.Component {
    constructor(props) {
        super(props);
        this.canvasRef = React.createRef();
    }

    componentDidUpdate() {
        // this.myChart.data.labels = this.props.data.map(d => d.time);
        // this.myChart.data.datasets[0].data = this.props.data.map(d => d.value);

        const { chartData } = this.props;
        if (chartData && Object.keys(chartData).length > 0) {

            this.myChart.data = {
                labels: chartData.labels,
                datasets: chartData.datasets,
            };
            this.myChart.options = chartData.options;

            //Gradient color
            let propdataSet = chartData.datasets;
            let canvas = document.getElementById('canvas');
            let ctx = canvas.getContext("2d");
            let gradient = ctx.createLinearGradient(0, 0, 0, 400);
            propdataSet.map((pdata, i) => {

                let bgColor = pdata.backgroundColor;
                if (typeof (bgColor) == 'object' && pdata.label == "Close") {
                    if (pdata.data[0] < pdata.data[Object.keys(pdata.data).length - 1]) {
                        bgColor = chartAreaBackColorSuccess;
                    } else {
                        bgColor = chartAreaBackColorDanger;
                    }
                } else if (pdata.type != 'bubble' && pdata.label != "Close") {
                    bgColor = 'transparent'
                }

                if (bgColor && pdata.backgroundColor != 'transparent' && pdata.type != 'bubble') {
                    // gradient.addColorStop(0, typeof (bgColor) == 'string' ? bgColor : chartAreaBackColorSuccess);
                    gradient.addColorStop(0, bgColor);
                    gradient.addColorStop(0.7, 'rgba(255, 255, 255, 0.7)');
                    gradient.addColorStop(1, 'rgba(255, 255, 255, 0)');
                    pdata.backgroundColor = gradient;
                }
            })

            this.myChart.update();
        }
    }

    componentDidMount() {

        const { chartData } = this.props;
        const { onSelect } = this.props;

        if (Object.keys(chartData).length > 0) {

            let propdataSet = chartData.datasets;
            let canvas = document.getElementById('canvas');
            let ctx = canvas.getContext("2d");
            let gradient = ctx.createLinearGradient(0, 0, 0, 400);
            propdataSet.map((pdata, i) => {

                let bgColor = pdata.backgroundColor;
                if (typeof (bgColor) == 'object' && pdata.label == "Close") {
                    if (pdata.data[0] < pdata.data[Object.keys(pdata.data).length - 1]) {
                        bgColor = chartAreaBackColorSuccess;
                    } else {
                        bgColor = chartAreaBackColorDanger;
                    }
                } else if (pdata.type != 'bubble' && pdata.label != "Close") {
                    bgColor = 'transparent'
                }

                if (bgColor && pdata.backgroundColor != 'transparent' && pdata.type != 'bubble') {
                    // gradient.addColorStop(0, typeof (bgColor) == 'string' ? bgColor : chartAreaBackColorSuccess);
                    gradient.addColorStop(0, bgColor);
                    gradient.addColorStop(0.7, 'rgba(255, 255, 255, 0.7)');
                    gradient.addColorStop(1, 'rgba(255, 255, 255, 0)');
                    pdata.backgroundColor = gradient;
                }
            })

            Chart.plugins.register({
                afterDatasetsDraw: function (chart) {
                    if (chart.tooltip._active && chart.tooltip._active.length) {
                        let activePoint = chart.tooltip._active[0],
                            ctx = chart.ctx,
                            y_axis = chart.scales['y-axis-0'],
                            x = activePoint.tooltipPosition().x,
                            topY = y_axis.top,
                            bottomY = y_axis.bottom;
                        // draw line
                        ctx.save();
                        ctx.beginPath();
                        ctx.moveTo(x, topY);
                        ctx.lineTo(x, bottomY);
                        ctx.lineWidth = 0.45;
                        // ctx.strokeStyle = '#07C';
                        ctx.strokeStyle = '#f57607';
                        ctx.stroke();
                        ctx.restore();
                    }
                }
            });

            this.myChart = new Chart(this.canvasRef.current, {
                type: 'line',
                data: {
                    labels: chartData.labels,
                    datasets: chartData.datasets,
                },
                options: chartData.options
            })
            let startIndex = 0;
            let endIndex = 0;
            let drag = false;

            var overlay = document.getElementById('charoverlay');
            overlay.width = canvas.width;
            overlay.height = canvas.height;
            var selectionContext = overlay.getContext('2d');
            var selectionRect = {
                w: 0,
                startX: 0,
                startY: 0
            };
            var lastClickedOn = 0;
            canvas.addEventListener('pointerdown', evt => {

                // console.log(evt);
                const points = this.myChart.getElementsAtEventForMode(evt, 'index', {
                    intersect: false
                });

                // console.log(points);
                startIndex = points[0]._index;
                // console.log("startIndex = ", startIndex);
                const rect = canvas.getBoundingClientRect();

                selectionRect.startX = evt.clientX - rect.left;
                selectionRect.startY = this.myChart.chartArea.top;
                drag = true;

            });
            canvas.addEventListener('pointermove', evt => {

                const rect = canvas.getBoundingClientRect();
                if (drag) {
                    //   const rect = canvas.getBoundingClientRect();
                    selectionRect.w = (evt.clientX - rect.left) - selectionRect.startX;
                    selectionContext.globalAlpha = 0.1;
                    selectionContext.clearRect(0, 0, canvas.width, canvas.height);
                    selectionContext.fillRect(selectionRect.startX,
                        selectionRect.startY,
                        selectionRect.w,
                        this.myChart.chartArea.bottom - this.myChart.chartArea.top);

                } else {
                    // selectionContext.clearRect(0, 0, canvas.width, canvas.height);
                    var x = evt.clientX - rect.left;
                    if (x > this.myChart.chartArea.left) {
                        selectionContext.fillRect(x, this.myChart.chartArea.top, 1,
                            this.myChart.chartArea.bottom - this.myChart.chartArea.top);
                    }
                    selectionContext.clearRect(0, 0, canvas.width, canvas.height);
                }

            });



            canvas.addEventListener('pointerup', evt => {

                let now = new Date().getTime()

                const points = this.myChart.getElementsAtEventForMode(evt, 'index', {
                    intersect: false
                });
                drag = false;
                endIndex = points[0]._index;

                let clickDiff = now - lastClickedOn;
                // console.log('XXXX s = ' +startIndex + ', e = ' + endIndex + ', c = ' + clickDiff);

                if ((((now - lastClickedOn) < 300) && (startIndex == endIndex)) || (startIndex != endIndex)) {

                    if (onSelect && typeof onSelect == 'function') {
                        onSelect(startIndex, endIndex);
                    }
                }
                lastClickedOn = now

                const rect = canvas.getBoundingClientRect();
                selectionContext.clearRect(0, 0, canvas.width, canvas.height);

                var x = evt.clientX - rect.left;
                if (x > this.myChart.chartArea.left) {
                    selectionContext.fillRect(x,
                        this.myChart.chartArea.top,
                        1,
                        this.myChart.chartArea.bottom - this.myChart.chartArea.top);
                }
            });


            canvas.addEventListener('mouseout', evt => {

                if (!drag) {
                    return;
                }
                const points = this.myChart.getElementsAtEventForMode(evt, 'index', {
                    intersect: false
                });
                drag = false;
                endIndex = points[0]._index;
                // console.log('111 implement filter between ' +startIndex + ' and ' + endIndex);
                if (onSelect && typeof onSelect == 'function') {
                    onSelect(startIndex, endIndex);
                }

                const rect = canvas.getBoundingClientRect();
                selectionContext.clearRect(0, 0, canvas.width, canvas.height);
                var x = evt.clientX - rect.left;
                if (x > this.myChart.chartArea.left) {
                    selectionContext.fillRect(x,
                        this.myChart.chartArea.top, 1,
                        this.myChart.chartArea.bottom - this.myChart.chartArea.top);
                }
                selectionContext.lineWidth = 0
            });
        }

    }

    componentWillUnmount() {
        let canvas = document.getElementById('canvas');
        if (canvas) {
            canvas.removeEventListener('mouseout', () => { })
            canvas.removeEventListener('pointerup', () => { })
            canvas.removeEventListener('pointermove', () => { })
            canvas.removeEventListener('pointerdown', () => { })
        }
    }

    render() {

        const { chartData } = this.props;
        // console.log(chartData)
        // console.log('chartData _____')
        if (Object.keys(chartData).length > 0) {
            return <div style={{ justifyContent: 'center', alignItems: 'center', width: "100%" }}>
                <canvas id="charoverlay" style={{ pointerEvents: "none", position: "absolute", display: "block" }} />
                <canvas id="canvas" ref={this.canvasRef} style={{ height: 190 }} />
            </div>;
        }
        return <div />
    }
}
export default ChartjsGraph;
import { GET_POSITION_LOGS_INFO } from '../actions/types';

const initialState = {
    logsData: {},
    loading: false,
};

export default function (state = initialState, action) {
    switch (action.type) {
        case GET_POSITION_LOGS_INFO:
            return {
                ...state,
                logsData: action.payload
            };

        default:
            return state;
    }
}
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { withStyles, lighten, makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
// import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
// import Toolbar from '@material-ui/core/Toolbar';
// import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
// import Checkbox from '@material-ui/core/Checkbox';
// import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
// import Zoom from '@material-ui/core/Zoom';
import Fade from '@material-ui/core/Fade';
// import FormControlLabel from '@material-ui/core/FormControlLabel';
// import Switch from '@material-ui/core/Switch';
// import DeleteIcon from '@material-ui/icons/Delete';
// import FilterListIcon from '@material-ui/icons/FilterList';
import CircularProgress from '@material-ui/core/CircularProgress';

import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';

import { connect } from "react-redux";
import { withRouter } from 'react-router-dom';
import cookie from 'react-cookies';
import Button from '@material-ui/core/Button';

import { getPositionsInfo, forceSell, suppressAction } from '../actions/positionActions';
import { getAplcaAccountInfo } from '../actions/accountActions';
import { getAccountHistory } from '../actions/accountHistoryActions';
import { round, formatDateMMdd, getDayDifference } from '../utility/Utility';
import { successColor, dangerColor, txtColor, defaultColor, greyOutColor, dangerColorFaded } from '../utility/Constants';
// import FadeInContainer from '../commonComponents/FadeInContainer';
import Loader from '../commonComponents/Loader';
import LoaderSmall from '../commonComponents/LoaderSmall';
import SweetAlert from 'react-bootstrap-sweetalert';

import '../css/style.css';
import Uparrow from '../assets/img/Up_green_arrow.png';
import Downarrow from '../assets/img/down.png';

import PortfolioLineChart from './PortfolioLineChart'
import PortfolioBarChart from './PortfolioBarChart';


const StyledTableCell = withStyles((theme) => ({
    head: {
        backgroundColor: "#666363",//theme.palette.common.black,
        color: theme.palette.common.white,
        // fontWeight: '600',
        fontSize: '0.87rem',

    },
    body: {
        fontSize: '0.8rem',
        fontWeight: '600'
    },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
    root: {
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.action.hover,
        },
    },
}))(TableRow);
const BlueOnGreenTooltip = withStyles({
    tooltip: {
        color: "white",//"lightblue",
        backgroundColor: "rgba(0, 0, 0, 0.6)", //"green",
        marginTop: -4,
    }
})(Tooltip);
const StyledButton = withStyles({
    root: {
        // background: 'linear-gradient(45deg, #FFFFFF 30%, #000000 90%)',
        borderRadius: 3,
        border: 0,
        color: 'white',
        height: 28,
        padding: '0 20px',
        margin: '2px',
        border: '1px solid #ececec',
        // boxShadow: '0 3px 5px 2px rgba(255, 105, 135, .3)',
    },
    label: {
        textTransform: 'capitalize',
    },
})(Button);

function descendingComparator(a, b, orderBy) {
    if (b['qty'] === 0 || a['qty'] === 0) {
        return;
    }
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function getComparator(order, orderBy) {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}


const headCells = [
    { id: 'symbol', numeric: false, disablePadding: false, align: 'left', sortable: false, label: 'Symbol' },
    { id: 'qty', numeric: true, disablePadding: false, align: 'right', sortable: true, label: 'QTY #' },
    { id: 'avg_entry_price', numeric: true, disablePadding: false, align: 'right', sortable: true, label: 'Avg $' },
    { id: 'current_price', numeric: true, disablePadding: false, align: 'right', sortable: true, label: 'Current $' },
    { id: 'market_value', numeric: true, disablePadding: false, align: 'right', sortable: true, label: 'Value $' },
    { id: 'unrealized_pl', numeric: true, disablePadding: false, align: 'right', sortable: true, label: 'Gain $ (%)' },
    { id: 'lastday_price', numeric: true, disablePadding: false, align: 'right', sortable: true, label: 'Lastday $' },
    { id: 'change_today_total', numeric: true, disablePadding: false, align: 'right', sortable: true, label: 'Change Today' },
    { id: 'created_on', numeric: true, disablePadding: false, align: 'right', sortable: true, label: 'Added (Days)' },
    { id: 'unrealized_plpc', numeric: true, disablePadding: false, align: 'right', sortable: true, label: 'Avg Gain %' },
];

function EnhancedTableHead(props) {
    const { order, orderBy, onRequestSort } = props;
    const createSortHandler = (property) => (event) => {
        onRequestSort(event, property);
    };

    return (
        <TableHead>
            <TableRow>

                {headCells.map((headCell) => (
                    <StyledTableCell
                        key={headCell.id}
                        align={headCell.numeric ? 'right' : 'left'}
                        padding={headCell.disablePadding ? 'none' : 'default'}
                        sortDirection={orderBy === headCell.id ? order : false}
                    >
                        {headCell.sortable ? <TableSortLabel
                            active={orderBy === headCell.id}
                            // style={sortIconStyles}
                            direction={orderBy === headCell.id ? order : 'asc'}
                            onClick={createSortHandler(headCell.id)}
                        >
                            {headCell.label}

                        </TableSortLabel> : headCell.label}
                    </StyledTableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

EnhancedTableHead.propTypes = {
    onRequestSort: PropTypes.func.isRequired,
    order: PropTypes.oneOf(['asc', 'desc']).isRequired,
    orderBy: PropTypes.string.isRequired,
};

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    paper: {
        width: '100%',
        marginBottom: theme.spacing(2),
    },
    table: {
        minWidth: 750,
    },
    visuallyHidden: {
        border: 0,
        clip: 'rect(0 0 0 0)',
        height: 1,
        margin: -1,
        overflow: 'hidden',
        padding: 0,
        position: 'absolute',
        top: 20,
        width: 1,
    },
    buttonProgress: {
        color: '#ececec',
        position: 'absolute',
        top: '70%',
        left: '50%',
        marginTop: -16,
        marginLeft: -12,
    },
    wrapper: {
        margin: theme.spacing(1),
        position: 'relative',
    },
    buttonClassname: {
        borderRadius: 3,
        color: 'white',
        height: 28,
        padding: '0 10px',
        margin: '2px',
        border: '1px solid #ececec',
        fontSize: 12
    }
}));

function EnhancedTable(props) {
    const classes = useStyles();
    const [order, setOrder] = React.useState('asc');
    const [orderBy, setOrderBy] = React.useState('unrealized_pl');
    const [selected, setSelected] = React.useState([]);
    const [dense, setDense] = React.useState(false);
    const [loading, setLoading] = React.useState(false);
    const [loadingFS, setLoadingFS] = React.useState(false);
    const [showConfirmSupp, setShowConfirmSupp] = React.useState(false);

    const [suppClicked, setSuppClicked] = React.useState(false);
    const [unSuppClicked, setUnsuppClicked] = React.useState(false);
    const [forcSellClicked, setForcSellClicked] = React.useState(false);
    const [positionId, setPositionId] = React.useState('');
    const [selectedSymbol, setSelectedSymbol] = React.useState('');
    const [resMsg, setResMsg] = React.useState('');

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };


    const handleClick = (event, name) => {
        const selectedIndex = selected.indexOf(name);
        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, name);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1),
            );
        }

        setSelected(newSelected);
    };


    function forceSellAction() {

        setShowConfirmSupp(true);
        setForcSellClicked(true);
        setSuppClicked(false);
        setUnsuppClicked(false)
    }
    function suppressUnsuprsAction(sTag) {
        setShowConfirmSupp(true);
        if (sTag) {
            setSuppClicked(true);
            setUnsuppClicked(false)
        } else {
            setSuppClicked(false);
            setUnsuppClicked(true)
        }
        setForcSellClicked(false);
    }
    function doActions(posId, msgTxt, callback) {
        setLoading(true);
        switch (msgTxt) {
            case 'suppress':
                suppressAction(posId, true, (data) => {
                    setResMsg(data.message ? data.message : 'Something went wrong, please try again!');
                    callback(data)
                    setLoading(false);

                })
                break;
            case 'unsuppress':
                suppressAction(posId, false, (data) => {
                    setResMsg(data.message ? data.message : 'Something went wrong, please try again!');
                    callback(data)
                    setLoading(false);
                })
                break;
            case 'force sell':
                forceSell(posId, (data) => {
                    // setLoadingFS(false);
                    setLoading(false);
                    setResMsg(data.message ? data.message : 'Something went wrong, please try again!');
                    callback(data)
                })
                break;
            default:
                break;
        }
    }
    function onConfirmAction(posId, msgTxt) {
        setShowConfirmSupp(false)
        setForcSellClicked(false);
        setSuppClicked(false);
        setUnsuppClicked(false);

        doActions(posId, msgTxt, props.reloadComponent)
    }
    function onCancelAction() {
        setShowConfirmSupp(false)
        setForcSellClicked(false);
        setSuppClicked(false);
        setUnsuppClicked(false);
    }
    function closeMessageAlert() {
        setResMsg('')
    }

    const isSelected = (name) => selected.indexOf(name) !== -1;
    const rows = props.positionsInfo;
    // let positionsRow = [];

    rows.forEach(row => {
        if (row.qty <= 0 || row.current_price <= 0 || row.lastday_price <= 0 || row.current_price === row.lastday_price) {
            row.change_today_total = 0.00
            row.change_today_pct = 0.00
        } else {
            row.change_today_total = ((row.current_price - row.lastday_price) * row.qty)
            row.change_today_pct = (((row.current_price - row.lastday_price) * 100) / row.lastday_price)
            // row.change_today_total = round(((row.current_price - row.lastday_price) * row.qty), 2)
            // row.change_today_pct = round((((row.current_price - row.lastday_price) * 100) / row.lastday_price),2)
        }

        // positionsRow.push(row)
    });

    let msgText = "";
    if (suppClicked) {
        msgText = "suppress";
    } else if (unSuppClicked) {
        msgText = "unsuppress";
    } else if (forcSellClicked) {
        msgText = "force sell";
    }

    let fntmTableContainer = document.getElementById('fntm-table-container');
    let tblwidth = 0;
    if (fntmTableContainer) {
        tblwidth = fntmTableContainer.getBoundingClientRect().width
    }

    function makeRowBackgroundColor(rowid, removeColor) {
        let rowObj = document.getElementById(rowid);
        if (rowObj && removeColor) {
            rowObj.style.backgroundColor = 'rgba(255, 255, 255, 1)';
        } else {
            rowObj.style.backgroundColor = 'rgba(0, 0, 0, 0.05)';
        }
    }

    return (
        <div className={classes.root}>
            <Paper className={classes.paper}>
                <TableContainer>
                    <Table
                        className={classes.table}
                        aria-labelledby="tableTitle"
                        size={dense ? 'small' : 'medium'}
                        aria-label="enhanced table"
                    >
                        <EnhancedTableHead
                            // classes={classes}
                            // numSelected={selected.length}
                            order={order}
                            orderBy={orderBy}
                            // onSelectAllClick={handleSelectAllClick}
                            onRequestSort={handleRequestSort}
                        // rowCount={rows.length}   
                        />
                        <TableBody>
                            {stableSort(rows, getComparator(order, orderBy))
                                .map((data, i) => {
                                    const isItemSelected = isSelected(data.name);
                                    const labelId = `enhanced-table-checkbox-${i}`;

                                    let defaultGreyColor = defaultColor;
                                    let currMrktUplColor = defaultColor;
                                    switch (true) {
                                        case (data.unrealized_pl > 0):
                                            currMrktUplColor = successColor;
                                            break;
                                        case (data.unrealized_pl < 0):
                                            currMrktUplColor = dangerColor;
                                            break;
                                        default:
                                            break;
                                    }
                                    let lastdayChangetodayColor = defaultColor;
                                    switch (true) {
                                        case (data.change_today > 0):
                                            lastdayChangetodayColor = successColor;
                                            break;
                                        case (data.change_today < 0):
                                            lastdayChangetodayColor = dangerColor;
                                            break;
                                        default:
                                            break;
                                    }
                                    if (data.qty == 0) {
                                        lastdayChangetodayColor = greyOutColor;
                                        currMrktUplColor = greyOutColor;
                                        defaultGreyColor = greyOutColor;
                                    }

                                    const { pre_close, current_price, sync_state, signal, message } = data;

                                    let symbolBackColor = 'transparent';
                                    //Old Cond
                                    // if (sync_state && sync_state < 100) {
                                    //     symbolBackColor = dangerColorFaded;
                                    // }
                                    //New Cond
                                    if (data.forcesell) {
                                        symbolBackColor = dangerColorFaded;
                                    }
                                    let indicatorColor = "transparent";
                                    // if (signal && Object.keys(signal).length > 0) {
                                    //     const { grstop } = signal;
                                    //     const { close } = signal;
                                    //     try {
                                    //         //Old Cond
                                    //         // if (grstop > close) {
                                    //         //     indicatorColor = dangerColor;
                                    //         // } else 
                                    //         if (grstop < close) {
                                    //             indicatorColor = successColor;
                                    //         }
                                    //     } catch (err) {
                                    //         indicatorColor = "transparent";
                                    //     }
                                    // }

                                    //New Cond
                                    if (data.suppress) {
                                        indicatorColor = dangerColor;
                                    }
                                    let forceSellEnabled = true;
                                    if (data.qty > 0 && data.forcesell == false) {
                                        forceSellEnabled = false;
                                    }


                                    if (current_price > 0 && data.qty > 0) {
                                        data.market_value = current_price * data.qty
                                        let totalBuyPrice = (data.avg_entry_price * data.qty)
                                        data.unrealized_pl = data.market_value - totalBuyPrice
                                        data.unrealized_plpc = (data.unrealized_pl * 100) / totalBuyPrice
                                    } else {
                                        data.unrealized_plpc = data.unrealized_plpc * 100
                                    }
                                    const longText = <div
                                        style={{ padding: 10 }}
                                        id={data.id + '-tlp-div-id'}
                                        className="tltp-div-id"
                                    >
                                        <p className="tooltip-txt" style={{ fontWeight: 'bold' }}> {data.symbol}</p>
                                        <p className="tooltip-txt">Date: {signal.date}</p>
                                        <p className="tooltip-txt">Close: ${signal.close}</p>
                                        <p className="tooltip-txt">GR Stop: ${signal.grstop}</p>
                                        <p className="tooltip-txt">CR Stop: ${signal.cutoverstop}</p>
                                        <p className="tooltip-txt">Trigger: {signal.trigger}</p>
                                        <p className="tooltip-txt">Message: {message ? message : ''}</p>
                                        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
                                            <div className={classes.wrapper}>
                                                <Button
                                                    className={classes.buttonClassname}
                                                    disabled={loading}
                                                    onClick={() => {
                                                        setPositionId(data.id);
                                                        setSelectedSymbol(data.symbol);
                                                        suppressUnsuprsAction(!data.suppress)
                                                    }}
                                                >
                                                    {data.suppress ? 'Unsurpress' : 'Surpress'}
                                                </Button>
                                                {loading && <CircularProgress size={24} className={classes.buttonProgress} />}
                                            </div>
                                            <div className={classes.wrapper}>
                                                <Button
                                                    className={classes.buttonClassname + ' foresell-btn'}
                                                    disabled={forceSellEnabled}
                                                    onClick={() => {
                                                        setPositionId(data.id);
                                                        setSelectedSymbol(data.symbol);
                                                        forceSellAction()
                                                    }}
                                                >
                                                    Force sell
                                                </Button>
                                                {loadingFS && <CircularProgress size={24} className={classes.buttonProgress} />}
                                            </div>

                                        </div>
                                    </div>;


                                    let addedOn = data.created_on;
                                    let dayDiff = "";
                                    let today = new Date();

                                    if (addedOn) {
                                        let addedOnDate = new Date(addedOn);
                                        dayDiff = getDayDifference(addedOnDate, today);

                                        if (dayDiff.toString().length < 2) {

                                            dayDiff = (dayDiff == 0) ? 1 : dayDiff;
                                            dayDiff = "0" + dayDiff
                                        }
                                    }
                                    if (data.qty !== undefined && data.qty === 0) {
                                        dayDiff = "";
                                        addedOn = "";
                                    }

                                    let gainPercentage = '-';
                                    if (dayDiff !== 0 && !isNaN(dayDiff)) {
                                        gainPercentage = round(data.unrealized_plpc, 2) / dayDiff
                                    }

                                    return (

                                        <BlueOnGreenTooltip
                                            title={longText}
                                            disableFocusListener
                                            enterDelay={0}
                                            className="tbl-tooltip"
                                            key={i + '_toltip'}
                                            id={data.id + '-row-ttp-id'}
                                            arrow
                                            placement="bottom"
                                            interactive
                                            onOpen={() => makeRowBackgroundColor(data.id + '-row-id', false)}
                                            onClose={() => makeRowBackgroundColor(data.id + '-row-id', true)}
                                            TransitionComponent={Fade}
                                        >

                                            <StyledTableRow key={i}
                                                hover
                                                onClick={(event) => handleClick(event, data.name)}
                                                role="checkbox"
                                                aria-checked={isItemSelected}
                                                tabIndex={-1}
                                                key={data.qty}
                                                selected={isItemSelected}
                                                id={data.id + '-row-id'}
                                                style={{ backgroundColor: '#ffffff' }}
                                            >
                                                <StyledTableCell component="th" id={labelId} scope="row" >
                                                    <span style={{ width: 6, height: 6, borderRadius: '50%', backgroundColor: indicatorColor, display: "inline-block", marginRight: 5 }}></span>
                                                    <span style={{ backgroundColor: symbolBackColor, padding: 3, borderRadius: 3 }}>
                                                        <a href={"/order/list?symbol=" + data.symbol} className="th-a-link" style={{ color: defaultGreyColor }} >{data.symbol}</a>
                                                    </span>
                                                </StyledTableCell>
                                                <StyledTableCell align="right" style={{ color: defaultGreyColor }}>
                                                    {data.qty}
                                                </StyledTableCell>
                                                <StyledTableCell align="right" className="tblcell" style={{ color: defaultGreyColor }}>
                                                    {round(data.avg_entry_price, 2)}
                                                </StyledTableCell>
                                                <StyledTableCell align="right" style={{ color: defaultGreyColor }}>
                                                    <span >{round(data.current_price, 2)}</span>
                                                    <span style={{ paddingLeft: 5 }}>
                                                        {pre_close < current_price ?
                                                            <img src={Uparrow} style={{ width: 10, height: 8, opacity: data.qty == 0 ? 0.3 : 1 }} /> :
                                                            <img src={Downarrow} style={{ width: 10, height: 8, opacity: data.qty == 0 ? 0.3 : 1 }} />}
                                                    </span>
                                                </StyledTableCell>

                                                <StyledTableCell align="right" style={{ color: currMrktUplColor }}>
                                                    {round(data.market_value, 2)}
                                                </StyledTableCell>

                                                <StyledTableCell align="right" style={{ color: currMrktUplColor }} >
                                                    <span style={{ paddingRight: 5 }}>
                                                        {round(data.unrealized_pl, 2)}
                                                    </span>
                                                    <span >
                                                        ({round(data.unrealized_plpc, 2)} %)
                                                    </span>
                                                </StyledTableCell>

                                                <StyledTableCell align="right" style={{ color: lastdayChangetodayColor }}>
                                                    {round(data.lastday_price, 2)}
                                                </StyledTableCell>
                                                <StyledTableCell align="right" style={{ color: lastdayChangetodayColor }}>
                                                    <span style={{ paddingRight: 5 }}>
                                                        {round(data.change_today_total, 2)}
                                                    </span>
                                                    <span >
                                                        ({round(data.change_today_pct, 2)} %)
                                                    </span>
                                                </StyledTableCell>
                                                <StyledTableCell align="right" style={{ color: defaultGreyColor }}>
                                                    {addedOn.length > 0 ? formatDateMMdd(addedOn) + " " + `(${dayDiff})` : "-"}
                                                </StyledTableCell>

                                                <StyledTableCell align="right" style={{ color: currMrktUplColor }}>
                                                    {round(gainPercentage, 3)} %
                                                </StyledTableCell>
                                                {data.id == positionId && loading &&
                                                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', backgroundColor: 'rgba(0, 0, 0, 0.4)', marginLeft: -tblwidth }}>
                                                        <LoaderSmall />
                                                    </div>
                                                }
                                            </StyledTableRow>
                                        </BlueOnGreenTooltip>

                                    )
                                })}
                            {showConfirmSupp && <SweetAlert
                                // warning
                                style={{ backgroundColor: 'rgba(0, 0, 0, 0.6)', color: '#fff', width: 250, height: 95, fontSize: 8 }}
                                showCancel
                                // showCloseButton
                                confirmBtnText="Yes"
                                cancelBtnText="No"
                                // confirmBtnBsStyle="primary"
                                // cancelBtnBsStyle="light"
                                title={`Do you want to ${msgText} ${selectedSymbol}?`}
                                onConfirm={() => onConfirmAction()}
                                onCancel={() => onCancelAction()}
                                focusConfirmBtn
                                customButtons={
                                    <React.Fragment>
                                        <Button
                                            className={classes.buttonClassname}
                                            onClick={() => onCancelAction()}
                                        >
                                            {'No'}
                                        </Button>
                                        <Button
                                            className={classes.buttonClassname}
                                            onClick={() => onConfirmAction(positionId, msgText)}
                                        >
                                            {'Yes'}
                                        </Button>
                                    </React.Fragment>
                                }
                            >
                            </SweetAlert>}
                            {resMsg.length > 0 && <SweetAlert
                                style={{ backgroundColor: 'rgba(0, 0, 0, 0.6)', color: '#fff', width: 250, height: 95, fontSize: 8 }}
                                showCancel
                                title={resMsg}
                                onConfirm={() => { }}
                                onCancel={() => closeMessageAlert()}
                                customButtons={
                                    <React.Fragment>
                                        <Button
                                            className={classes.buttonClassname}
                                            onClick={() => closeMessageAlert()}
                                        >
                                            {'OK'}
                                        </Button>
                                    </React.Fragment>
                                }
                            >
                            </SweetAlert>}
                        </TableBody>
                    </Table>
                </TableContainer>

            </Paper>
            {/* <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', backgroundColor: 'rgba(0, 0, 0, 0.5)', marginTop: -window.pageYOffset }}><Loader /></div> */}
        </div >
    );
}



function Portfolio(props) {

    if (cookie.load('login') == 'false' || cookie.load('login') == undefined) {
        props.history.push('login')
    }
    const [reload, setReload] = React.useState(false);
    useEffect(() => {
        props.getPositionsInfo('asc', 'created_on', function () { })
        props.getAplcaAccountInfo(function () { })
        props.getAccountHistory(function () { })
        // Safe to add dispatch to the dependencies array
    }, [])


    const { positionsInfo, accountInfo, accountHistoryData } = props;
    const accountHistoryRes = accountHistoryData.result ? accountHistoryData.result : {};
    let dynamicTxt = txtColor;
    let valDiff = accountInfo.portfolio_value - accountInfo.last_equity
    if (valDiff > 0) {
        dynamicTxt = successColor;
    } else {
        dynamicTxt = dangerColor;
    }
    function reloadPositionData() {
        props.getPositionsInfo('asc', 'created_on', function (data) {
            setReload(!reload);
        })
    }

    return (
        <div style={{ margin: 20, minWidth: window.innerWidth * 0.8 }}>

            <Container maxWidth="lg">
                <Paper elevation={4} style={{ margin: 20, padding: 20, boxShadow: "0 1px 5px 0 rgb(218 224 235 / 60%)" }}>
                    <Grid container spacing={2}>
                        <Grid item xs={4} md={4}>
                            <div style={{ flexDirection: 'row', justifyContent: 'flex-start', display: 'flex' }}>
                                <span style={{ fontSize: '0.95em', color: txtColor, marginRight: 3 }}>Portfolio  = </span>
                                <span style={{ fontSize: '1em', color: txtColor, paddingRight: 10, marginLeft: 2, fontWeight: 600 }}>{accountInfo.portfolio_value}</span>
                                <span style={{ fontSize: '0.95em', color: txtColor }}>
                                    {accountInfo.last_equity < accountInfo.portfolio_value ?
                                        <img src={Uparrow} style={{ width: 15, height: 10 }} /> :
                                        <img src={Downarrow} style={{ width: 15, height: 10 }} />}
                                </span>
                                <span style={{ fontSize: '0.9em', color: dynamicTxt, paddingLeft: 10, paddingRight: 10 }}>
                                    ({valDiff > 0 ? '+' + round(valDiff, 2) : round(valDiff, 2)})
                                </span>
                            </div>
                        </Grid>
                        <Grid item xs={8} md={8}>
                            <Grid container spacing={2}>

                                <Grid item xs={3} md={3}>
                                    <div style={{ flexDirection: 'row', justifyContent: 'flex-start', display: 'flex' }}>
                                        <span style={{ fontSize: '0.95em', color: txtColor }}>(</span>
                                        <span style={{ fontSize: '0.95em', color: txtColor, marginLeft: 3 }}>Long = </span>
                                        <span style={{ marginRight: 4 }}></span>
                                        <span style={{ fontSize: '0.95em', color: txtColor, paddingRight: 10 }}>{accountInfo.long_market_value}</span>
                                    </div>

                                </Grid>
                                <Grid item xs={2} md={2}>
                                    <div style={{ flexDirection: 'column', justifyContent: 'flex-end', display: 'flex' }}>
                                        <span style={{ fontSize: '0.95em', color: txtColor, paddingRight: 10 }}>{'+'}</span>
                                    </div>
                                </Grid>
                                <Grid item xs={2} md={2}>
                                    <div style={{ flexDirection: 'row', justifyContent: 'center', display: 'flex' }}>
                                        <span style={{ fontSize: '0.95em', color: txtColor }}>Short = </span>
                                        <span style={{ marginRight: 4 }}></span>
                                        <span style={{ fontSize: '0.95em', color: txtColor, paddingRight: 10 }}>{accountInfo.short_market_value}</span>
                                    </div>

                                </Grid>
                                <Grid item xs={2} md={2}>
                                    <div style={{ flexDirection: 'column', justifyContent: 'center', display: 'flex' }}>
                                        <span style={{ fontSize: '0.95em', color: txtColor, paddingRight: 10 }}>{'+'}</span>
                                    </div>

                                </Grid>
                                <Grid item xs={2} md={3}>
                                    <div style={{ flexDirection: 'row', justifyContent: 'center', display: 'flex' }}>
                                        <span style={{ fontSize: '0.95em', color: txtColor, paddingRight: 4 }}>Cash = </span>
                                        <span style={{ marginRight: 2 }}></span>
                                        <span style={{ fontSize: '0.95em', color: txtColor, paddingRight: 3 }}>{accountInfo.cash}</span>
                                        <span style={{ fontSize: '0.95em', color: txtColor }}>{')'}</span>
                                    </div>

                                </Grid>
                                <Grid item xs={1} md={1}>

                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid container spacing={2} >
                        <Grid item xs={6} md={6}>
                            <div style={{ flexDirection: 'row', justifyContent: 'flex-start', display: 'flex' }}>
                                {accountHistoryRes && Object.keys(accountHistoryRes).length > 0 && <PortfolioLineChart {...props} accountHistory={accountHistoryRes} />}
                            </div>
                        </Grid>
                        <Grid item xs={6} md={6}>
                            <div style={{ flexDirection: 'row', justifyContent: 'flex-start', display: 'flex' }}>
                                {accountHistoryRes && Object.keys(accountHistoryRes).length > 0 && <PortfolioBarChart {...props} accountHistory={accountHistoryRes} />}
                            </div>
                        </Grid>
                    </Grid>
                </Paper>
            </Container>
            <Container maxWidth="lg">
                <Paper elevation={4} style={{ margin: 20, padding: 20, boxShadow: "0 1px 5px 0 rgb(218 224 235 / 60%)" }} id="fntm-table-container">
                    {(positionsInfo && Object.keys(positionsInfo).length > 0) ? <EnhancedTable {...props} reloadComponent={() => reloadPositionData()} /> :
                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: 500 }}>
                            <Loader />
                        </div>}


                </Paper>
            </Container>
        </div>
    )
}
const mapStateToProps = (state) => {
    const { positionsData } = state.positionsInfo ? state.positionsInfo : {};
    const { accountData } = state.accountInfo ? state.accountInfo : {};
    let positionsInfo = positionsData.result ? positionsData.result : {};
    let accountInfo = accountData.result ? accountData.result : {};

    const { accountHistoryData } = state.accountHistoryInfo

    return {
        positionsInfo,
        accountInfo,
        accountHistoryData
    }
};
export default connect(mapStateToProps, { getPositionsInfo, getAplcaAccountInfo, getAccountHistory })(withRouter(Portfolio));
import { POSITION_INFO_SUCCESS, POSITION_INFO_FAIL } from '../actions/types';

const initialState = {
    positionsData: {},
    loading: false,
};

export default function (state = initialState, action) {
    switch (action.type) {
        case POSITION_INFO_SUCCESS:
            return {
                ...state,
                positionsData: action.payload
            };
        case POSITION_INFO_FAIL:
            return {
                ...state,
                positionsData: action.payload
            };

        default:
            return state;
    }
}
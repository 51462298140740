import { BLOCK_INFO_SUCCESS, BLOCK_INFO_FAIL } from '../actions/types';

const initialState = {
    blockListData: {},
    loading: false,
};

export default function (state = initialState, action) {
    switch (action.type) {
        case BLOCK_INFO_SUCCESS:
            return {
                ...state,
                blockListData: action.payload
            };
        case BLOCK_INFO_FAIL:
            return {
                ...state,
                blockListData: action.payload
            };

        default:
            return state;
    }
}
import { ORDER_INFO_SUCCESS, ORDER_INFO_FAIL } from '../actions/types';

const initialState = {
    orderData: {},
    loading: false,
};

export default function (state = initialState, action) {
    switch (action.type) {
        case ORDER_INFO_SUCCESS:
            return {
                ...state,
                orderData: action.payload
            };
        case ORDER_INFO_FAIL:
            return {
                ...state,
                orderData: action.payload
            };

        default:
            return state;
    }
}
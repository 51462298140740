import React from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Container from '@material-ui/core/Container';



import Paper from '@material-ui/core/Paper';
import { connect } from "react-redux";
import { withRouter } from 'react-router-dom';

import { getBlockListingInfo, getBlockChartInfo, getSignalChartInfo } from '../actions/orderActions';
import FadeInContainer from '../commonComponents/FadeInContainer';
import CollapsibleTable from './CollapsableTable';
import Loader from '../commonComponents/Loader';

import '../css/style.css';



const StyledTableCell = withStyles((theme) => ({
    head: {
        backgroundColor: "#666363",//theme.palette.common.black,
        color: theme.palette.common.white,
        // fontWeight: '600',
        fontSize: '0.8rem',
    },
    body: {
        fontSize: '0.8rem',
        fontWeight: '600'
    },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
    root: {
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.action.hover,
            height: '100px'
        },
    },
}))(TableRow);

const useRowStyles = makeStyles({
    root: {
        '& > *': {
            borderBottom: 'unset',
        },
    },
});


class BlocksListing extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            page: 0,
            rowsPerPage: 5,
            status: 'all',
            searchVal: '',
            expandMore: {},
            open: false,
            isLoaded: false
        }
    }
    abortOntroller = new AbortController();
    componentDidMount() {

        let _this = this;
        const { qstr } = this.props;
        // console.log("componentDidMount _______ block" + qstr);
        if (qstr && qstr.length > 0) {
            this.setState({
                searchVal: qstr,
                status: 'all'
            });
            if (document.getElementById("block-symbol-search")) {
                document.getElementById("block-symbol-search").value = qstr;
            }
            this.props.getBlockListingInfo('all', qstr, function () {
                _this.setState({
                    isLoaded: true
                });
                _this.props.hideLoader();
            })
        } else {
            this.props.getBlockListingInfo('all', '', function () {
                _this.setState({
                    isLoaded: true
                });
                _this.props.hideLoader();
            })
        }

    }
    componentWillUnmount() {
        this.abortOntroller.abort();
    }
    handleChange(event) {
        let _this = this;
        const status = event.target.value;
        this.setState({
            status: status,
        })
        this.props.getBlockListingInfo(status, this.state.searchVal, function () {
            _this.setState({
                isLoaded: true
            });
            _this.props.hideLoader();
        })
    }
    handleOnTxtChange(event) {
        let _this = this;
        const searchVal = event.target.value;
        if (searchVal && searchVal.length == 0) {
            this.setState({
                searchVal: searchVal,
                status: 'open'
            });
        } else {
            this.setState({
                searchVal: searchVal
            });
        }
        this.props.getBlockListingInfo(this.state.status, searchVal, function () {
            _this.setState({
                isLoaded: true
            });
            _this.props.hideLoader();
        })
    }
    symbolLink = (symbolVal) => {

        //0 is tab index of list
        this.props.linkClicked(symbolVal, 0);
    }

    render() {

        const { blockInfo, qstr, propLoading } = this.props;
        // console.log(this.props)
        // console.log('this.props _______ blocklisting')
        const Headers = () => {
            return (
                <TableHead>
                    <TableRow>
                        <StyledTableCell>Symbol</StyledTableCell>
                        <StyledTableCell align="right">Date of Buy</StyledTableCell>
                        <StyledTableCell align="right">Date of Sell</StyledTableCell>
                        <StyledTableCell align="right">QTY #</StyledTableCell>
                        <StyledTableCell align="right">Current Price $</StyledTableCell>
                        <StyledTableCell align="right">Buy Price $</StyledTableCell>
                        <StyledTableCell align="right">Sell Price $</StyledTableCell>
                        <StyledTableCell align="right">Total Gain $</StyledTableCell>
                        <StyledTableCell align="right">Total Gain %</StyledTableCell>
                        <StyledTableCell></StyledTableCell>

                    </TableRow>
                </TableHead>
            )
        }

        if (propLoading) {
            return (
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: 500 }}>
                    <Loader />
                </div>
            )
        }

        return (
            <FadeInContainer delay={150} duration={750} >
                {/* {blockInfo && Object.keys(blockInfo).length > 0 && <CommonChartView symbol={qstr} {...this.props} />} */}
                {/* <h3>Blocks</h3> */}
                <Container maxWidth="lg">
                    <Paper elevation={4} style={{ padding: 20, marginBottom: 30, boxShadow: "0 1px 5px 0 rgb(218 224 235 / 60%)" }}>
                        {/* <Paper style={{ width: '100%', marginBottom: 20, boxShadow: "0 1px 5px 0 rgb(218 224 235 / 60%)", flexDirection: "row", display: "flex" }}>
                            <div style={{ padding: 10 }}>
                                <FormControl variant="outlined" className="fantom-select">
                                    <InputLabel htmlFor="outlined-age-native-simple">Status</InputLabel>
                                    <Select
                                        native
                                        value={this.state.status}
                                        onChange={(evt) => this.handleChange(evt)}
                                        label="Status"
                                        inputProps={{
                                            name: 'status',
                                            id: 'outlined-age-native-simple',
                                        }}
                                    >
                                        <option value={'all'}>All</option>
                                        <option value={'open'}>Open</option>
                                        <option value={'closed'}>Closed</option>
                                    </Select>
                                </FormControl>
                            </div>
                            <div style={{ padding: 10 }}>
                                <TextField
                                    id="block-symbol-search"
                                    className="fantom-input"
                                    label="Search Symbol"
                                    type="search"
                                    variant="outlined"
                                    defaultValue={this.state.searchVal}
                                    onChange={(evt) => this.handleOnTxtChange(evt)}
                                />
                            </div>
                        </Paper> */}
                        <TableContainer component={Paper} style={{ maxHeight: window.innerHeight - 250, width: '100%' }}>
                            {(blockInfo && Object.keys(blockInfo).length > 0) ?

                                <CollapsibleTable {...this.props} />
                                :
                                <Table stickyHeader aria-label="sticky table" >
                                    <Headers />
                                    <TableBody>
                                        <StyledTableRow align="center">
                                            <StyledTableCell component="th" scope="row"> </StyledTableCell>
                                            <StyledTableCell component="th" scope="row"> </StyledTableCell>
                                            <StyledTableCell component="th" scope="row">  </StyledTableCell>
                                            <StyledTableCell component="th" scope="row">  </StyledTableCell>
                                            {/* <StyledTableCell component="th" scope="row">  No data found</StyledTableCell> */}
                                            <StyledTableCell component="th" scope="row"> {this.state.isLoaded ? ' No data found' : <Loader />}</StyledTableCell>
                                            <StyledTableCell component="th" scope="row">  </StyledTableCell>
                                            <StyledTableCell component="th" scope="row">  </StyledTableCell>
                                            <StyledTableCell component="th" scope="row">  </StyledTableCell>
                                            <StyledTableCell component="th" scope="row">  </StyledTableCell>
                                        </StyledTableRow>
                                    </TableBody>
                                </Table>
                            }
                        </TableContainer>
                    </Paper>
                </Container>
            </FadeInContainer>
        )

    }
}
const mapStateToProps = (state) => {

    const { blockChartData } = state.blockChartInfo ? state.blockChartInfo : {};
    const { blockListData } = state.blockInfo ? state.blockInfo : {};
    let blockInfo = blockListData.result ? blockListData.result : {};
    return {
        blockInfo,
        blockChartData
    }
};
export default connect(mapStateToProps, { getBlockListingInfo, getBlockChartInfo, getSignalChartInfo })(withRouter(BlocksListing));
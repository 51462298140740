import React, { Component } from "react";
import { withRouter } from 'react-router-dom';
import { connect } from "react-redux";
import cookie from 'react-cookies';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Paper from '@material-ui/core/Paper';

import '../css/style.css';

import { createCookie } from '../utility/Utility';
import { loginUser } from '../actions/loginActions';


class Login extends Component {

    constructor(props) {
        super(props);
        this.state = {
            email: "",
            password: "",
            statusMsg: "",// "Something went wrong! Please try again."
        };
        this.onKeyUp = this.onKeyUp.bind(this);
    }

    onKeyUp(event) {
        if (event.charCode === 13) {
            this.onSubmit()
        }
    }
    handleChange = e => {
        // console.log(e.currentTarget)
        if (e.currentTarget.id == 'standard-email-input') {
            // this.setState({ [e.currentTarget.id]: e.currentTarget.value });
            this.setState({
                email: e.currentTarget.value,
                statusMsg: ""
            });
        } else {
            this.setState({
                password: e.currentTarget.value,
                statusMsg: ""
            });
        }

    };

    customNavigate = (page) => {
        this.props.history.push(page);
    }

    onSubmit = () => {
        // console.log(this.state)
        const { email, password } = this.state;
        if (email.length > 0 && password.length > 0) {
            // createCookie('login', true);
            // this.props.history.push('/');
            let _this = this;
            const { loginUser } = this.props;
            loginUser(email, password, function (res) {
                // console.log(res)
                // console.log('res ___')
                if (res && res.status == "success") {
                    // _this.setState({ statusMsg: res.data });
                    createCookie('login', true);
                    createCookie('userInfo', res.result);

                    _this.props.history.push('/position');
                    window.location.reload();
                } else {
                    _this.setState({ statusMsg: res ? res.message : "Something went wrong, please try again." });
                }
            });
        } else {
            createCookie('login', false);
            this.setState({ statusMsg: "All fields are required" });
        }
    }
    componentDidMount() {
        if (cookie.load('login') == "true") {
            this.props.history.push("/position");
        }

    }
    render() {

        return (
            <Paper elevation={4} style={{ marginTop: 50, padding: 20, boxShadow: "0 1px 5px 0 rgb(218 224 235 / 60%)" }}>
                {/*<Card style={{ display: 'flex', flexWrap: 'wrap', flexDirection: 'row', justifyContent: 'center', marginTop: 50 }}>*/}

                <CardContent>
                    <div className="login-container" style={{ width: 500 }}>
                        <h4>Log In</h4>

                        {this.state.statusMsg.length > 0 && <p style={{ color: 'red' }}><label>{this.state.statusMsg}</label></p>}
                        <form className="form">
                            <div>
                                <TextField
                                    id="standard-email-input"
                                    label="Email"
                                    type="text"
                                    onChange={(e) => this.handleChange(e)}
                                    onKeyPress={this.onKeyUp}
                                    style={{ width: 200 }}
                                />
                            </div>
                            <div style={{ paddingBottom: 20 }}>
                                <TextField
                                    id="standard-password-input"
                                    label="Password"
                                    type="password"
                                    autoComplete="current-password"
                                    onChange={(e) => this.handleChange(e)}
                                    onKeyPress={this.onKeyUp}
                                    style={{ width: 200 }}
                                />
                            </div>
                            <Button variant="contained" color="primary" onClick={() => this.onSubmit()}>Log in </Button>
                            <p className="forgot-password text-right">
                                New here? <a activehref="javascript:void(0);" onClick={() => this.customNavigate('signup')} style={{ textDecoration: 'underline', cursor: 'pointer', color: 'green' }}>Sign up now.</a>
                            </p>
                        </form>
                    </div>
                </CardContent>
                {/* </Card> */}
            </Paper>

        );
    }
}
const mapStateToProps = (state) => {
    return {}
};
export default connect(mapStateToProps, { loginUser })(withRouter(Login));
